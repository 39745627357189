import { createSlice } from "@reduxjs/toolkit";
import { PendingSubmissionList } from "../../models/submission/PendingSubmissionListModel";

const initialState: PendingSubmissionList = {
  recruiterGuid: "",
  jobId: "",
  searchKeywords: "",
  location: [],
  statuses: [],
  contractType: [],
  showPendingSubmissions: true,
  pageIndex: 1,
  pageSize: 20,
  sortColumn: "",
  sortOrder: "Descending",
  isSorted: true,
  totalRecord: 0,
}
  
const PendingSubmissionListStateSlice = createSlice({
  name: "PendingSubmissionListStateSlice",
  initialState,
  reducers: {
    setPendingSubmissionListState: (state, action: any) => {
      return action.payload;
    },
    resetPendingSubmissionState: () => {
      return initialState;
    },
  },
});

export const { setPendingSubmissionListState, resetPendingSubmissionState } = PendingSubmissionListStateSlice.actions;

export default PendingSubmissionListStateSlice.reducer;
