import * as React from "react";
import Grid from "@mui/material/Grid";
import JobSearchbar from "./JobSearchbar";
import JobListingTable from "./JobListingTable";
import { UserAuth } from "../../../models/interfaces";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { PageName } from "../../../models/app/Page/PageModel";
import { getNevLink } from "../../../utils/getFileFromUrl";

const SavedJobPage: React.FC = () => {

  const userRole = getLoggedInUserRole();
  const DefaultSavedJobBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    {
      isLeaf: true,
      sequence: 3,
      title: "Saved Jobs",
      leafClasses: " v2LastLeft",
    },
  ];

  const AdminSavedJobBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to:getNevLink('/dashboard') },
    {
      isLeaf: false,
      sequence: 2,
      title: "Recruiter",
      to: "/admin/recruiters",
      leafClasses: " v2LastLeft",
    },
    {
      isLeaf: true,
      sequence: 3,
      title: "Saved Jobs",
      leafClasses: " v2LastLeft",
    },
  ];

  const [totalRecords, setTotalRecords] = React.useState<number | undefined>(0);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} >
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={
            userRole == UserAuth.Admin || userRole == UserAuth.SuperAdmin
              ? AdminSavedJobBreadcrumbs
              : DefaultSavedJobBreadcrumbs
          }
        />
      </Grid>
      <Grid item xs={12}>
        <JobSearchbar
          totalRecords={totalRecords}
          pageNameType={PageName.SAVEDJOBPAGE}
        />
      </Grid>
      <Grid item xs={12}>
        <JobListingTable
          setTotalRecords={setTotalRecords}
          pageNameType={PageName.SAVEDJOBPAGE}
        />
      </Grid>
    </Grid>
  );
};

export default SavedJobPage;
