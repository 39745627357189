import { FormControl, Input, InputLabel } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { getIn, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { GetAllDomains, UpdateRecruiterUserProfile } from "../../Services/recruiterService";
import { DocumentType, UploadAttachment } from "../../Services/uploadPhotoService";
import { interests } from "../../data/tags";
import { disableEdit, enableEdit, toggleEdit } from "../../features/editMode/editModeSlice";
import useApi from "../../hooks/useApi";

import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { experienceRegex, linkedInRegex } from "../../constants/regex";
import { defaultDataAutocomplete } from "../../data/defaultDataAutocomplete";
import { getRecruiterData } from "../../features/recruiterSlice";
import { FilePreview } from "../../models/app/FilePreview";
import { DocumentTypeEnum } from "../../models/interfaces";
import { AppState } from "../../store/AppState";
import getAddressForAutoComplete from "../../utils/getAddressForAutoComplete";
import { downloadFile, getFilePreviewUrl } from "../../utils/getFileFromUrl";
import getLabel from "../../utils/getLabelForAutoComplete";
import { FileUploader } from "../Common/FileUploader";
import SnackbarPopup from "../Common/Popup/snackbar/SnackbarPopup";
import PreviewFileDialog from "../Common/PreviewFileDialog";
import { UploadAttachments } from "../Common/UploadAttachments";
import { CustomAlert } from "../ui/CustomAlert";
import { ErrorMessage } from "../ui/ErrorMessage";
import { IndustryAutocomplete } from "../ui/IndustryAutocomplete";
import { SkillAutocomplete } from "../ui/SkillAutocomplete";
import { getDocumentsByType, getFirstDocumentIndexByType } from "../../utils/documentObjectUtils";
import { ternary } from "../../utils/complexityUtils";
import { Asterisk } from "../ui/Asterisk";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface UserProfileFormProps {
  props?:any
  userDetail:any;
  getUserProfile:() => void;
}

const UserProfileForm: React.FC<UserProfileFormProps> = (props: UserProfileFormProps) => {

  const editMode = useSelector((state:AppState) => state.editMode)
  const dispatch = useDispatch()

  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const [autoCompleteSuggesions, setautoCompleteSuggesions] = React.useState("")
  const [otherDocuments,setOtherDocuments] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  const [filePreview, setFilePreview] = React.useState<FilePreview | undefined>(undefined);

  props.userDetail.interests = props?.userDetail?.interests?.filter((v:string) => v !== 'Full-time' && v !== 'Contingent')
  props.userDetail.linkedIn =  ternary(props.userDetail.profiles?.[0]?.link, props.userDetail.profiles?.[0]?.link,"")
  let initialState = props?.userDetail;
  const [userProfileDetails, setuserProfileDetails] = useState(initialState)

  const UserSchema = Yup.object({
    id: Yup.string(),
    userSelfDescription: Yup.string().max(500),
    linkedIn: Yup.string().matches(linkedInRegex, "LinkedIn URL doesn't match")
      .when('documentInfo', {
        is: (documentInfo:any[]) =>  getDocumentsByType(documentInfo, DocumentTypeEnum.Resume)?.length === 0,
        then: Yup.string().required("LinkedIn is required if no resume document is uploaded"),
        otherwise: Yup.string(),
      }),
    experience: Yup.string().required('Please enter number of years of experience').matches(experienceRegex, "Please enter the valid experience from 0 to 25 or 25+ "),

    domains: Yup.array().min(1, "Please select the domain"),
    interests: Yup.array().min(1, "Please select the interests"),

  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: UserSchema,

    enableReinitialize: true,
    onSubmit: (values,actions) => {

      if (fileError !== "") {
        return;
      }
      setPageStatus({ isSuccess: false, error: "", loading: true });
      values.profiles= values.profiles[0]?.link == ""? []: values.profiles
      return UpdateRecruiterUserProfile(values)
        .then((response) => {
          if (response.data.status == 200) {
            dispatch(disableEdit());
            dispatch(getRecruiterData());

            setuserProfileDetails(values)
            setPageStatus({ isSuccess: true, error: "", loading: false });
          } else {
            setPageStatus({
              isSuccess: false,
              error: response.data?.message?.appStatusDescription,
              loading: false,
            });
          }
        })
        .catch((er) => {
          setPageStatus({
            isSuccess: false,
            error: er.message,
            loading: false,
          });
        });

    },
  });
  const handleIndustryChange = (e:any, value:any) => {
    formik.setFieldValue("industries", value);
  }
  const handleSkillChange = (e:any, value:any) => {
    formik.setFieldValue("skills", value);
  }

  const handleDomainChange = (domain:any) => {
    let item = { id: domain.id, domain: domain.domainName }
    if (formik.values.domains.some((e: any) => e.id === domain.id)) {
      formik.values.domains = formik.values.domains.filter(
        (item:any )=> item.id !== domain.id
      );
    }
    else {
      formik.values.domains.push(item);
    }
    formik.setFieldTouched("domains", true);
  }
  const handleInterestChange = (interest:any) => {
    if (formik.values.interests.some((e: any) => e === interest)) {
      formik.values.interests = formik.values.interests.filter(
        (item: any) => item !== interest
      );
    }
    else {
      formik.values.interests.push(interest);
    }
    formik.setFieldTouched("interests", true);
  }

  
  const handleAttachmentChange = (files:any) => {

  const otherUniqueDocuments = Array.from( new Set(files.map((file:any) => JSON.stringify(file))))
                              .map((json:any) => JSON?.parse(json));

  let existingDocuments = formik?.values?.documentInfo.filter((x:any) => x.documentType !== DocumentType.OtherDoc)

    formik.setFieldValue("documentInfo",[...existingDocuments,...otherUniqueDocuments]);
 }
  const allDomains = useApi(() => GetAllDomains());
  const otherDocs = async () =>{
    if(initialState){
      setOtherDocuments(initialState.documentInfo?.filter((x:any) => x.documentType == DocumentType.OtherDoc));
    }
  }
  useEffect(() => {
    dispatch(enableEdit());
    allDomains.request();

    props.getUserProfile()
    otherDocs();
  }, [])

  const getSkills = formik.values.skills
  const getIndustry = formik.values.industries
  const [isUploading, setisUploading] = React.useState(false)

  const [fileError, setfileError] = React.useState("");

  const handleUploadClick = (files: any) => {
    let resumeDocIndex = getFirstDocumentIndexByType(formik.values.documentInfo, DocumentTypeEnum.Resume);
    resumeDocIndex = ternary(resumeDocIndex < 0 , formik.values.documentInfo?.length, resumeDocIndex)
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);


    if (files[0].size > 2097152) {
      formik.setFieldValue(`DdocumentInfo[${resumeDocIndex}].fileName`, files[0].name);

      setfileError("File size too large");
    } else {

      formik.setFieldTouched(`documentInfo[${resumeDocIndex}].fileName`, false, false);
      let filUpload = { documentType: DocumentTypeEnum.Resume, file: files[0] }
      setisUploading(true)
      UploadAttachment(filUpload).then(res => {
        
        
        formik.setFieldValue(`documentInfo[${resumeDocIndex}].resourceUrl`, res?.data?.entity?.absoluteUri);
        formik.setFieldValue(`documentInfo[${resumeDocIndex}].fileName`, res?.data?.entity?.fileName);
        formik.setFieldValue(`documentInfo[${resumeDocIndex}].newFileName`, res?.data?.entity?.newFileName);
        formik.setFieldValue(`documentInfo[${resumeDocIndex}].documentType`, DocumentTypeEnum.Resume);


      }).finally(()=> {setisUploading(false)})
      setfileError("");
    }



  };
  const renderPreferredGeographies = ({
    getInputProps,
    suggestions,
  }:any) => (
    <>
      <Autocomplete
        readOnly={!editMode.isEditable}

        multiple
        id="size-small-standard-multi3"
        size="medium"
        getOptionDisabled={(option) => formik.values.preferredLocation.some((e: any) => e.id === option.id) || formik.values.preferredLocation?.length === 5 ? true : false}
        onChange={(e, value) => formik.setFieldValue("preferredLocation", value)}
        options={suggestions.length > 0 ? suggestions.map((str:any) => ({ id: str.placeId, location: { city: getAddressForAutoComplete(str.description).city, state: getAddressForAutoComplete(str.description).state, country: getAddressForAutoComplete(str.description).country } })) : defaultDataAutocomplete}
        value={formik.values.preferredLocation}
        getOptionLabel={(option) => getLabel(option.location.city, option.location.state)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              color={!editMode.isEditable ?  'default' : 'primary'}
              size="medium"              
              label={getLabel(option.location.city, option.location.state)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            {...getInputProps()}
            variant="standard"
            label="Preferred Geographies"
            placeholder={ editMode.isEditable && "Please type in to search geographies"}
            InputProps={{
              ...params.InputProps,
              disableUnderline: !editMode.isEditable,
            }}
          />
        )}
      />
    </>


  );




  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {
            editMode.isEditable && (
            <Box className="manage-profile-btns">
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(toggleEdit());
                  setPageStatus({ isSuccess: false, error: "", loading: false });
                  formik.setValues(userProfileDetails);
                }}
                disabled={isLoading}
                data-testid="btn-cancel-update-user-profile"
                className="manage-profile-btnCancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  console.log(formik.errors);
                  formik.handleSubmit();
                  formik.setFieldTouched('linkedIn', true);
                }}
                variant="contained"
                disabled={formik.isSubmitting || isLoading}
                endIcon={formik.isSubmitting?<CircularProgress size={"16px"} color={"inherit"} />:"" }
                data-testid="btn-update-user-profile"
              >
                Update Profile
              </Button>
            </Box>
          )}
        </Grid>
        
          {pageStatus.error && (
            <Grid item xs={12} md={12}>
              <CustomAlert key={1} type={"error"} message={pageStatus.error} onClose={()=>{}} />
            </Grid>
          )}
          {pageStatus.isSuccess &&  (
            <SnackbarPopup 
              open={pageStatus.isSuccess} 
              message={"User Profile information updated successfully"} 
              onClose={()=>{ setPageStatus({...pageStatus, isSuccess:false}) }}
              />
          )}
        
        <Grid item xs={12} md={12}>
          <Item
            elevation={0}
            sx={{
              backgroundColor: "transparent",
              textAlign: "left",
              padding: 0,
            }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiFormControl-root": {
                  marginTop: "3px",
                  marginBottom: "3px",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={2.3}>
                  <FormControl
                    fullWidth
                    variant="standard"
                    required
                    error={
                      formik.touched.experience &&
                      Boolean(formik.errors.experience)
                    }
                  >
                    <InputLabel htmlFor="experience" shrink={true}>Total Experience </InputLabel>

                    <Input
                      name="experience"
                      onKeyDown={(evt) => evt.key === "-" && evt.preventDefault()}
                      readOnly={!editMode.isEditable}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="start">Years</InputAdornment>
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.experience}

                    />

                    <ErrorMessage
                      errorText={
                        formik.touched.experience && formik.errors.experience
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={9.7}>
                  <FormControl
                    fullWidth
                    
                    variant="standard"
                    error={Boolean(
                      getIn(formik.touched, "profiles[0].link") &&
                      getIn(formik.errors, "profiles[0].link")
                    )}
                    className="linkedIn-input"
                  >
                    <InputLabel htmlFor="profiles.link">
                      
                      LinkedIn Profile {getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.length === 0 && <Asterisk />}
                    </InputLabel>
                    <Input
                          name="linkedIn"
                          inputProps={{ "data-testid": "linkedInInputProfile" }}
                          type="text"
                          onChange={(event) => {
                            setPageStatus({ isSuccess: false, error: "", loading: false });
                            formik.setFieldValue('linkedIn', event.target.value);
                            formik.setFieldValue('profiles', [{ id: 'linkedIn', type: 'linkedIn', link: event.target.value }]);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.linkedIn}
                          placeholder="Please enter your linkedIn profile URL"
                        />
                        <ErrorMessage errorText={formik.touched.linkedIn && formik.errors.linkedIn} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>
                  <InputLabel shrink={true} required>
                    Select Domain 
                  </InputLabel>
                  {allDomains && allDomains.data && allDomains?.data?.entityList
                    .sort((a:any, b:any) => a.domainName.length - b.domainName.length)
                    .map((domain: any,index:number) => {
                      return (
                        <FormControlLabel
                          key={domain?.id ?? index}
                          control={
                            <Checkbox
                              onChange={() => handleDomainChange(domain)}
                              data-testid={`handleDomainChange-${index}`}
                            />
                          }
                          label={domain.domainName}
                          sx={{
                            marginLeft: "0px",
                            marginTop: "0px",
                            marginBottom: "0px",
                          }}
                          checked={formik.values.domains?.some(
                            (e: any) => e.id === domain.id
                          )}
                          disabled={!editMode.isEditable}
                        />
                      );
                    })}
                    <ErrorMessage
                      errorText={formik.touched.domains && formik.errors.domains}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                  <InputLabel shrink={true} required >
                    Your Interest 
                  </InputLabel>
                  {interests.map((x,index:number) => {
                    return (
                      <FormControlLabel
                      key={ x ?? index}
                        control={
                          <Checkbox
                            onChange={() => handleInterestChange(x)}
                            disabled={!editMode.isEditable}
                            id={x}
                            data-testid={`handleInterestChange-${index}`}
                          />
                        }
                        label={x}
                        checked={formik.values?.interests?.some((e: any) => e === x)}
                        sx={{
                          marginLeft: "0px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        }}
                      />
                    );
                  })}
                   <ErrorMessage
                      errorText={formik.touched.interests && formik.errors.interests}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                  <IndustryAutocomplete
                    industries={getIndustry}
                    onChange={handleIndustryChange}
                    readOnly={!editMode.isEditable}
                    isRequired={false}
                    placeholder="Please type in to search for the industry"
                  />
                  <ErrorMessage
                    errorText={
                      formik.touched.industries && formik.errors.industries
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <PlacesAutocomplete
                    value={autoCompleteSuggesions}
                    onChange={(ev: any) => {
                      setautoCompleteSuggesions(ev);
                    }}
                    searchOptions={{ types: ["(cities)"] }}
                  >
                    {renderPreferredGeographies}
                  </PlacesAutocomplete>

                  <ErrorMessage
                    errorText={
                      formik.touched.preferredLocation &&
                      formik.errors.preferredLocation
                    }
                />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography
                      variant="h6"
                      component="span"
                      className=" letter-spacing-normal primary-color-text typography-with-right-text"
                    >
                      <span className="muiLabelCustom">
                        <InputLabel shrink={true}>Skills often recruited? </InputLabel>                     
                      </span>
                      <span className="muiLabelCustom">
                        <InputLabel shrink={true} className="rightLabelText">Maximum 16 skills </InputLabel>
                      </span>
                    </Typography>
                    <SkillAutocomplete
                      skills={getSkills}
                      onChange={handleSkillChange}
                      readOnly={!editMode.isEditable}

                    />
                    <ErrorMessage
                      errorText={
                        formik.touched.skills &&
                        formik.errors.skills
                      }
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FileUploader
                    editMode={editMode.isEditable}
                    isUploading={isUploading}
                    fileName={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.fileName}
                    resourceUrl={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.resourceUrl}
                    fileError={fileError}
                    onFileChange={handleUploadClick}
                    setFilePreview={setFilePreview}
                  />               
                </Grid>
                <Grid item xs={12}>
                {!editMode.isEditable &&
                    (<>
                      <Box mt={{ xs: 1, sm: 1, md: 1 }}>
                          <Box className="form-three-child" ml={0}>
                              <InputLabel shrink={true}>Other Documents</InputLabel>
                          </Box>
                          <Box className="main-wrapper-uploader">
                              {formik?.values?.documentInfo.filter((x:any) => x.documentType === DocumentType.OtherDoc)?.map((otherDoc:any, index:number) => {
                                  return <Box key={otherDoc?.newFileName ?? index} className="upload-wrapper-text success-upload">
                                          <Typography
                                              variant="subtitle1"
                                              component="div"
                                              className=" title-text"
                                          >
                                              {otherDoc?.fileName ?? ""}
                                          </Typography>
                                          {otherDoc?.resourceUrl !== undefined
                                          && otherDoc?.resourceUrl !== ""
                                          &&
                                          <>
                                              {otherDoc?.type !== "application/x-zip-compressed" // cannot preview zip files
                                              && otherDoc?.fileName.split(".")[1]?.toLowerCase() !== "zip"
                                              &&
                                              <VisibilityOutlinedIcon
                                                  onClick={() => {
                                                      getFilePreviewUrl(otherDoc?.resourceUrl ?? "")
                                                      .then((filePreviewUrl: string) => setFilePreview({
                                                          resourceUrl: filePreviewUrl ?? "",
                                                          fileName: otherDoc?.fileName
                                                      }));
                                                  }}
                                                  color="primary"
                                                  className="pointer"
                                                  sx={{pl: 1, verticalAlign: "bottom"}}
                                              />
                                              }
                                              <SaveAltOutlinedIcon
                                                  onClick={() => {
                                                      downloadFile(
                                                        otherDoc.resourceUrl,
                                                        otherDoc.fileName
                                                      );
                                                  }}
                                                  color="primary"
                                                  className="pointer"
                                                  sx={{pl: 1, verticalAlign: "bottom"}}
                                              />
                                          </>
                                          }
                                      </Box>
                                  })
                                  || "N/A"
                              }
                          </Box>
                      </Box>
                    </>)
                  }
                </Grid>
                <Grid item xs={12} ml={-1}>
                {editMode.isEditable &&
                      (
                      <UploadAttachments
                        setFilePreview={setFilePreview}
                        onChange={(files:any) => handleAttachmentChange(files)}
                        documents={otherDocuments}
                        handleLoading={(val: any) => {
                          setIsLoading(val);
                        }}
                        acceptedFormats={".txt, .rtf, .doc, .docx, .pdf, .zip, .png, .jpg, .jpeg, .pptx"}
                      />
                      )
                    }
                </Grid>
              </Grid>            
             
            </Box>           
          </Item>
        </Grid>        
      </Grid>

      {filePreview !== undefined &&
      <PreviewFileDialog
        open={filePreview !== undefined}
        handleOpenClose={() => setFilePreview(undefined)}
        fileName={filePreview?.fileName}
        fileURL={filePreview?.resourceUrl}
        fileType={filePreview?.fileType}
      />
      }
    </>
  );
}

export default UserProfileForm
