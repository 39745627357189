import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Input, InputLabel, TextField } from "@material-ui/core";
import { CircularProgress, Divider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useParams } from "react-router-dom";
import {
  GetAllDomains,
  GetRecruiterById,
  UpdateEditRecruiter,
  GetHearAboutUsOptions
} from "../../../Services/recruiterService";
import { useFormik, getIn, FormikProps } from "formik";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ErrorMessage } from "../../ui/ErrorMessage";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import getLabel from "../../../utils/getLabelForAutoComplete";
import { defaultDataAutocomplete } from "../../../data/defaultDataAutocomplete";
import { interests } from "../../../data/tags";
import { SkillAutocomplete } from "../../ui/SkillAutocomplete";
import { IndustryAutocomplete } from "../../ui/IndustryAutocomplete";
import PlacesAutocomplete from "react-places-autocomplete";
import getAddressForAutoFill from "../../../utils/getAddressForAutoFill";
import { searchCities, searchOptions } from "../../../constants/googlePlaceOption";
import { timeZones } from "../../../data/timeZones";
import PhoneInput from "react-phone-input-2";
import PhoneVerify from "../../Common/PhoneVerify/PhoneVerify";
import verifiedIcon from "./../../../assets/images/verified.svg"
import { CustomEmailInput } from "../../ui/CustomEmailInput";
import { FileUploader } from "../../Common/FileUploader";
import { UploadAttachment } from "../../../Services/uploadPhotoService";
import { CustomAlert } from "../../ui/CustomAlert";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { GetPayoneerPaymentDetail, GetUSPaymentDetail } from "../../../Services/invoiceManagerService";
import { BillingType } from "../../../models/PaymentDetails";
import { AdminPaymentDetail } from "../../ManageProfile/BillingDetailsForms/AdminPaymentDetails";
import { DocumentTypeEnum,UserAuth } from "../../../models/interfaces";
import { FeedbackCommentsTimeline } from "../components/FeedbackCommentsTimeline";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import { getNevLink } from "../../../utils/getFileFromUrl";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import { useDispatch, useSelector } from "react-redux";
import { setRecruiterListState } from "../../../features/Recruiter/RecruiterListStateSlice";
import EditRecruiterSuccessPopup from "./Components/EditRecruiterSuccessPopup";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { EditRecruiterValidationSchema } from "./Components/EditRecruiterValidationSchema";
import { initialState } from "./EditRecruiterInitialState";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import { AxiosResponse } from "axios";
import { getDocumentsByType, getFirstDocumentIndexByType } from "../../../utils/documentObjectUtils";
import ScreeningViewInfo from "../components/Common/ScreeningViewInfo";
import SectionHeading from "../../ui/Form/Heading/SectionHeading";
import { Asterisk } from "../../ui/Asterisk";
export default function EditRecruiters() {

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Recruiters", to: `${ getNevLink('/recruiters')}` },
    { isLeaf: true, sequence: 3, title: "Edit Recruiters", leafClasses:" v2LastLeft"},
  ];

  // Get the recruiter list filter state 
  // Initialization
  const RecruiterListState = useSelector((state:any)=>state.recruiterListState);
  const dispatch = useDispatch();

  const [open2, setOpen] = React.useState(false);
  const [isUploading, setisUploading] = React.useState(false)
  const [pageStatus, setPageStatus] = React.useState({
    isSuccess: false,
    error: "",
    loading: false,
  });
  const [fileError, setfileError] = React.useState("");
  let { id } = useParams();
  
  const [getRecruiterData, setGetRecruiterData] = React.useState<any>();
  const [allDomainList, setAllDomainList] = React.useState<any[]>([]);
  const [ getHearAboutUsOptions, setGetHearAboutUsOptions] = React.useState<any>([]);
  const [autoCompleteSuggesions, setautoCompleteSuggesions] =
    React.useState("");
  const [countryShort, setCountryShort] = React.useState("us");
  const [recruiterData, setRecruiterData] = React.useState(initialState);
  let user = getUserDetails();
  const [currentVerifiedEmail, setCurrentVerifiedEmail] = React.useState('');
  const [paymentDetailsChangeStatus, setPaymentDetailsChangeStatus] = React.useState({isUpdated:false,paymentType:"",selectedOption:""})
  const [socialSubOptionList, setSocialSubOptionList] = React.useState([]) as any;
  const [filePreview, setFilePreview] = React.useState<undefined | 
  {
        resourceUrl: string;
        fileName: string;
        fileType?: string;
  }>(undefined);
  const [verifiedPhoneDetails, setVerifiedPhoneDetails] = React.useState({
    phoneNumbers: {
      primary: "",
      secondary: "",
      isPrimaryVerified: false,
      isSecondaryVerified: false,
    }
  });

  const USAFormRef = React.useRef<FormikProps<any>>(null);
  const PayoneerFormRef = React.useRef<FormikProps<any>>(null);

  const navigate = useNavigate();
  useEffect(() => {
   _GetRecruiterById();
   _GetAllDomains();
   _GetHearAboutUsOptions();
  }, []);

   const filterAboutsUsSubOption = (filteredData :any, selectedId:any) => {
    // for null value
    if (!filteredData) {
      return filteredData;
    }

    const filteredOption = filteredData.find(
      (row :any) => row.id === selectedId
    );

    return filteredOption;
  };

  const _GetRecruiterById = () =>{
    GetRecruiterById(id ?? "")
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setGetRecruiterData(response?.data);
        
        if (response.data && response.data.entity) {
          setRecruiterData({ ...initialState, ...response.data.entity, linkedIn: response.data.entity?.profiles[0]?.link ?? "" })
          setCurrentVerifiedEmail(response?.data?.entity?.email);    
          setPaymentDetailsChangeStatus({ ...paymentDetailsChangeStatus, selectedOption: response.data.entity.billingInfoType })    
          setVerifiedPhoneDetails({
            ...verifiedPhoneDetails,
            phoneNumbers :{
              ...verifiedPhoneDetails.phoneNumbers,
                primary: response.data.entity.phoneNumbers.primary ?? "",
                secondary: response.data.entity.phoneNumbers.secondary ?? "",
              }
              

          }) 
        }

        if (response?.data !== null) {
          GetUSPaymentDetail(response?.data?.entity?.id).then((response) => {
            const paymentDetails = response.data?.entity;
            Object.keys(initialState.paymentDetailsChangeRequest.usaBilling).forEach(e => formik.setFieldValue(`paymentDetailsChangeRequest.usaBilling.${e}`, valueOrDefault(paymentDetails[e], "")));

          });
    
          GetPayoneerPaymentDetail(response.data.entity.id).then((response) => {
              const payoneerData = response.data?.entity;
              Object.keys(initialState.paymentDetailsChangeRequest.payoneerBilling).forEach(e => formik.setFieldValue(`paymentDetailsChangeRequest.payoneerBilling.${e}`, valueOrDefault(payoneerData[e], "")));
            });
          }
      }else{
          setGetRecruiterData({})
      }
    })
  }

  const _GetAllDomains = ()=>{

    GetAllDomains()
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setAllDomainList([...response?.data?.entityList])
      }else{
        setAllDomainList([])
      }
    })
  }

  const _GetHearAboutUsOptions = () =>{
    GetHearAboutUsOptions()
    .then((response)=>{
      if(response?.data?.message?.appStatusCode === "RC_RSF"){
        setGetHearAboutUsOptions(response?.data?.entity);
        let  subList = filterAboutsUsSubOption(response?.data?.entity?.hearAboutUsOptions,"f1ea0b28-50d7-4164-80d6-0f503a03d872");
        setSocialSubOptionList(subList);
      }else{
        setGetHearAboutUsOptions([])
      }

    })
  }

  const handleDomainChange = (domain: any) => {
    let item = { id: domain.id, domain: domain.domainName };
    if (formik.values.domains.some((e: any) => e.id === domain.id)) {
      formik.values.domains = formik.values.domains.filter(
        (item: any) => item.id !== domain.id
      );
    } else {
      formik.values.domains.push(item);
    }
    formik.setFieldTouched("domains", true);
  };
  const handlePaymentChange = (e: any, paymentType: string) => {
    setPaymentDetailsChangeStatus({ ...paymentDetailsChangeStatus, isUpdated: true, paymentType: paymentType })
    formik.setFieldValue(`paymentDetailsChangeRequest.${paymentType}.${e.target.name}`, e.target.value)
  }
  const onAdminCountryChange = (e: any) => {
    if (e === BillingType.Payoneer) {
      setPaymentDetailsChangeStatus({
        isUpdated: true,
        paymentType: "payoneerBilling",
        selectedOption: "Payoneer"
      });
    }
    if (e === BillingType.USA) {
      setPaymentDetailsChangeStatus({
        isUpdated: true,
        paymentType: "usaBilling",
        selectedOption: "USA"
      });
    }

  }

  const handleInterestChange = (interest: any) => {

    if (formik.values.interests.some((e: any) => e === interest)) {
      formik.values.interests = formik.values.interests.filter(
        (item: any) => item !== interest
      );
    } else {
      formik.values.interests.push(interest);
    }
    formik.setFieldTouched("interests", true);
  };
  const handleIndustryChange = (e: any, value: any) => {
    formik.setFieldValue("industries", value);
  };
  const handleSkillChange = (e: any, value: any) => {
    formik.setFieldValue("skills", value);
  };
  const autoCompleteGetWorkLocation = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue(
      "currentWorkLocation.city",
      valueOrDefault(resp.city, "")
    );
    formik.setFieldValue("currentWorkLocation.state", valueOrDefault(resp.state, ""));
  };
  const autoCompleteGetAddress = async (address: any) => {
    let resp = await getAddressForAutoFill(address);
    formik.setFieldValue("address.postalCode", valueOrDefault(resp.pin, ""));
    formik.setFieldValue("address.streetAddress", valueOrDefault(resp.formattedAddress, ""));
    formik.setFieldValue("address.countryCode", valueOrDefault(resp.country, ""));
    formik.setFieldValue("address.state", valueOrDefault(resp.state, ""));
    formik.setFieldValue("address.city", valueOrDefault(resp.city, ""));
    formik.setFieldValue("phoneNumbers.primary", "");
    formik.setFieldValue("phoneNumbers.secondary", "");
    if (resp.pin && resp.pin !== "") {
      formik.setFieldTouched("address.postalCode", false)
    }
    if (resp.formattedAddress && resp.formattedAddress !== "") {
      formik.setFieldTouched("address.streetAddress", false)
    }
    if (resp.state && resp.state !== "") {
      formik.setFieldTouched("address.state", false)
    }
    if (resp.city && resp.city !== "") {
      formik.setFieldTouched("address.city", false)
    }
    if (resp.country && resp.country !== "") {
      formik.setFieldTouched("address.countryCode", false)
    }
    setCountryShort(resp?.countryCode?.toLowerCase());
  };

  function handleUpdateEditRecruiter(res : AxiosResponse<any, any>) {
    if (res?.data?.message?.appStatusCode === "RC_RSF") {
      setOpen(true)
      setPageStatus({ isSuccess: true, error: "", loading: false });
    }
    else {
      setPageStatus({ isSuccess: false, error: res?.data?.message?.appStatusDescription, loading: false });
    }
  }

  function isPayoneerEmailMissing(payoneerEmail : string | undefined) {
    let userRole = getLoggedInUserRole();
    return (
      userRole === UserAuth.Admin ||
      userRole === UserAuth.SuperAdmin
    ) 
    &&
    (
      payoneerEmail == undefined ||
      payoneerEmail === ""
    );
  }

  const formik = useFormik({
    initialValues: recruiterData,
    validationSchema: EditRecruiterValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      
      if(paymentDetailsChangeStatus.selectedOption === BillingType.USA){
        USAFormRef.current?.setFieldValue("billingInfoType",BillingType.USA);
        USAFormRef.current?.handleSubmit();
        USAFormRef.current?.setFieldTouched("location.address",true);
        USAFormRef.current?.setFieldTouched("location.city",true);
        USAFormRef.current?.setFieldTouched("location.state",true);
        USAFormRef.current?.setFieldTouched("location.zip",true);
        USAFormRef.current?.setFieldTouched("location.country",true);
        USAFormRef.current?.setFieldTouched("resourceInfo.fileName",true);
      
        if (USAFormRef.current?.isValid) {
          USAFormRef.current?.setFieldValue("billingInfoType",BillingType.USA);          
          handleEditRecruiterSubmit(values);
        
        }else{
          USAFormRef.current?.handleSubmit();
        }
      }
      else if(paymentDetailsChangeStatus.selectedOption === BillingType.Payoneer){        
        PayoneerFormRef.current?.handleSubmit();
        PayoneerFormRef.current?.setFieldValue("billingInfoType",BillingType.Payoneer);
        if (PayoneerFormRef.current?.isValid) {
          PayoneerFormRef.current?.setFieldValue("billingInfoType",BillingType.Payoneer);          
          handleEditRecruiterSubmit(values);
        
        }else{
          PayoneerFormRef.current?.handleSubmit();
        }
      }else{
        handleEditRecruiterSubmit(values);
        }
      
    },
  });

  const handleEditRecruiterSubmit = (values:any)=>{

    values.profiles = filterProfiles(values.profiles);
    values.address.id = values.address.city;

    if (values.paymentDetailsChangeRequest?.usaBilling) {
      
      values.paymentDetailsChangeRequest.usaBilling.isUpdateDetails = paymentDetailsChangeStatus.isUpdated && paymentDetailsChangeStatus.paymentType == "usaBilling"
      values.paymentDetailsChangeRequest.usaBilling.isRoutingNumberConfirm = true
      values.paymentDetailsChangeRequest.usaBilling.userId = getRecruiterData.entity.id
      values.paymentDetailsChangeRequest.usaBilling.email = getRecruiterData.entity.email
      values.paymentDetailsChangeRequest.usaBilling.name = getRecruiterData.entity.firstName
      values.paymentDetailsChangeRequest.usaBilling.billingInfoType = "USA"
      values.paymentDetailsChangeRequest.usaBilling.country = "USA"
      values.paymentDetailsChangeRequest.usaBilling.isW9NameConfirm = true
    }
    if (values.paymentDetailsChangeRequest?.payoneerBilling) {          
      values.paymentDetailsChangeRequest.payoneerBilling.isUpdateDetails = paymentDetailsChangeStatus.isUpdated && paymentDetailsChangeStatus.paymentType === "payoneerBilling"
      values.paymentDetailsChangeRequest.payoneerBilling.userId = getRecruiterData.entity.id
      values.paymentDetailsChangeRequest.payoneerBilling.billingInfoType = "Payoneer"
    }

    const body = {
      recruiter: values,
      adminFName: user.userName?.split(" ")[0] ?? "",
      adminEmail: user.email,
      emailChangeRedirectUrl: window.location.origin + `/manage-profile?verificationType=emailVerification&userId=${id}`,
      profileUrl: window.location.origin + `/manage-profile?userId=${id}`,
      adminId: user.id,
      paymentDetailsChangeRedirectUrl: window.location.origin + `/manage-profile?verificationType=paymentDetails&userId=${id}`,
    };
    //if latest change is usa remove payoneer object
    if (paymentDetailsChangeStatus.paymentType === "usaBilling") {
      delete (body as any).recruiter.paymentDetailsChangeRequest.payoneerBilling;
    }
    //if latest change is payoneer remove usa object
    if (paymentDetailsChangeStatus.paymentType === "payoneerBilling") {
      delete (body as any).recruiter.paymentDetailsChangeRequest.usaBilling;

      // if payoneer email is missing/empty, remove payoneer object (it is mandatory in API)
      if (isPayoneerEmailMissing(body.recruiter?.paymentDetailsChangeRequest?.payoneerBilling?.email))
      {
        delete (body as any).recruiter.paymentDetailsChangeRequest.payoneerBilling;
      }
    }
    //if no change remove obejct
    if (paymentDetailsChangeStatus.paymentType === "") {
      delete (body as any).recruiter.paymentDetailsChangeRequest;
    }
    if (paymentDetailsChangeStatus.paymentType === "" && !getRecruiterData?.entity?.paymentDetailsChangeRequest) {   
      delete (body as any).recruiter.paymentDetailsChangeRequest;
    }
    
    setPageStatus({ isSuccess: true, error: "", loading: true });
    
    UpdateEditRecruiter(body)
      .then((res) => {
        handleUpdateEditRecruiter(res);
      })
      .catch((err) => {
        setOpen(false)
        console.log(err, err?.data?.message?.appStatusDescription)
        setPageStatus({ isSuccess: false, error: err?.data?.message?.appStatusDescription, loading: false });
      })
  }

  const filterProfiles = (profiles: any) => {
    return profiles[0]?.link === "" ? [] : profiles;
  };


  const handleUploadClick = (files: any) => {
    if (!files) return;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let docIndex = getFirstDocumentIndexByType(formik.values.documentInfo, DocumentTypeEnum.Resume)
   
    docIndex = ternary(docIndex < 0 , formik.values.documentInfo?.length, docIndex)
    if (files[0].size > 2097152) {
      formik.setFieldValue(`documentInfo[${docIndex}].fileName`, files[0].name);
      setfileError("File size too large");
    } else {
      formik.setFieldTouched('documentInfo[${docIndex}].fileName', false, false);
      let filUpload = { documentType: DocumentTypeEnum.Resume, file: files[0] }
      setisUploading(true)
      UploadAttachment(filUpload).then(res => {
        formik.setFieldValue(`documentInfo[${docIndex}].resourceUrl`, res?.data?.entity?.absoluteUri);
        formik.setFieldValue(`documentInfo[${docIndex}].fileName`, res?.data?.entity?.fileName);
        formik.setFieldValue(`documentInfo[${docIndex}].newFileName`, res?.data?.entity?.newFileName);
        formik.setFieldValue(`documentInfo[${docIndex}].documentType`, DocumentTypeEnum.Resume);
      }).finally(() => { setisUploading(false) })
      setfileError("");
    }
  }
  const renderPreferredGeographies = ({ getInputProps, suggestions }: any) => (
    <>
      <Autocomplete
        multiple
        id="size-small-standard-multi3"
        size="medium"
        getOptionDisabled={(option: any) =>
          formik.values.preferredLocation.some(
            (e: any) => e.id === option.id
          ) || formik.values.preferredLocation?.length === 5
            ? true
            : false
        }
        onChange={(e, value) =>
          formik.setFieldValue("preferredLocation", value)
        }
        options={
          suggestions.length > 0
            ? suggestions.map((str: any) => ({
                id: str.placeId,
                location: {
                  city: getAddressForAutoComplete(str.description).city,
                  state: getAddressForAutoComplete(str.description).state,
                  country: getAddressForAutoComplete(str.description).country,
                },
              }))
            : defaultDataAutocomplete
        }
        value={formik.values.preferredLocation}
        getOptionLabel={(option: any) =>
          getLabel(option.location.city, option.location.state)
        }
        renderTags={(value, getTagProps) =>
          value.map((option: any, index) => (
            <Chip
              color="primary"
              size="medium"
              label={getLabel(option.location.city, option.location.state)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            {...getInputProps()}
            variant="standard"
            label="Preferred Geographies"
            placeholder="Please type in to search geographies"
          />
        )}
      />
    </>
  );
  const renderFunc = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      <FormControl
        variant="standard"
        error={
          formik.touched.address?.streetAddress &&
          Boolean(formik.errors.address?.streetAddress)
        }
        fullWidth
        required
      >
        <InputLabel htmlFor="address-input">Address</InputLabel>
        <Input
          {...getInputProps()}
          name="address.streetAddress"
          type="text"
          onBlur={formik.handleBlur}
        />
        <ErrorMessage
          errorText={
            formik.touched.address?.streetAddress &&
            formik.errors?.address?.streetAddress
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={autoCompleteGetAddress}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </>
  );
  const renderFuncCurrentWorkLocation = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading,
  }: any) => (
    <>
      <FormControl
        variant="standard"
        error={
          formik.touched.currentWorkLocation?.city &&
          Boolean(formik.errors.currentWorkLocation?.city)
        }
        className="customCity"
        fullWidth
      >
        <InputLabel htmlFor="address-input">City</InputLabel>
        <Input
          {...getInputProps()}
          name="currentWorkLocation.city"
          type="text"
          onBlur={formik.handleBlur}
        />
        <ErrorMessage
          errorText={
            formik.touched.currentWorkLocation?.city &&
            formik.errors.currentWorkLocation?.city
          }
        />
      </FormControl>
      <div className="autocomplete-dropdown-container">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div {...getSuggestionItemProps(suggestion)}>
            <span
              onChange={autoCompleteGetWorkLocation}
              style={{ cursor: "pointer" }}
            >
              {suggestion.description}
            </span>
          </div>
        ))}
      </div>
    </>
  );
  
  const props_Phone1Verify = {
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.primary,
    isPrimaryPhone: true,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isSecondaryVerified,
    updatePhoneVerify: (isVerified: any) => {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", isVerified);
      setVerifiedPhoneDetails({
        ...verifiedPhoneDetails,
        phoneNumbers :{
          ...verifiedPhoneDetails.phoneNumbers,
            primary: formik.values.phoneNumbers?.primary ?? "",
          }
          

      }) 
    }
  }
  const props_Phone2Verify = {
    userId: formik.values.id,
    phoneNumber: formik.values.phoneNumbers?.secondary,
    isPrimaryPhone: false,
    otherPhoneVerifyStatus: formik.values.phoneNumbers?.isPrimaryVerified,
    updatePhoneVerify: (isVerified: any) => {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", isVerified);
      setVerifiedPhoneDetails({
        ...verifiedPhoneDetails,
        phoneNumbers :{
          ...verifiedPhoneDetails.phoneNumbers,
            secondary: formik.values.phoneNumbers?.secondary ?? "",
          }
          

      }) 
    }
  }
  const onPrimaryPhChange = (ev: any) => {
    if (formik.values.phoneNumbers?.primary.replace(/-/g, "") != ev) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", false);
    }
    if (verifiedPhoneDetails.phoneNumbers?.primary.replace(/-/g, "") == ev) {
      formik.setFieldValue("phoneNumbers.isPrimaryVerified", verifiedPhoneDetails.phoneNumbers.isPrimaryVerified);
    }
    formik.setFieldValue("phoneNumbers.primary", ev);
  }
  const onSecondaryPhChange = (ev: any) => {
    if (formik.values.phoneNumbers?.secondary.replace(/-/g, "") != ev) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", false);
    }
    if (verifiedPhoneDetails.phoneNumbers?.secondary.replace(/-/g, "") == ev) {
      formik.setFieldValue("phoneNumbers.isSecondaryVerified", verifiedPhoneDetails.phoneNumbers.isSecondaryVerified);
    }
    formik.setFieldValue("phoneNumbers.secondary", ev);
  }

  const _handleOnUpdatePopupClose = () =>{
    setOpen(false)
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      }))
    navigate(getNevLink("/recruiters"))
  }

  const sortedDomainList = allDomainList?.length > 0
  ? [...allDomainList].sort((a: any, b: any) => a.domainName.length - b.domainName.length)
  : [];

  function displayPhoneVerifiedIcon() : boolean {
    return (
      (verifiedPhoneDetails.phoneNumbers?.primary ===  formik.values.phoneNumbers?.primary) ||
      formik.values.phoneNumbers?.isPrimaryVerified
    );
  }

  function displayPhoneVerifyComponent() : boolean {
    return (
      (verifiedPhoneDetails.phoneNumbers?.primary !==  formik.values.phoneNumbers?.primary) && 
      !formik.values.phoneNumbers?.isPrimaryVerified && 
      isValidPhoneNumber('+'+ formik.values.phoneNumbers?.primary)
    );
  }

  return (
    <>
    <Grid container spacing={0} className="editRecruter-wt">
      <Grid
        item
        xs={12}
        md={12}      >
        <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadcrumbs}
        />
      </Grid>

      <Grid item xs={12} sm={6}>

      <Typography className="Client-txt-fontwt">
      Manage Recruiter
        <Typography
          variant="h6"
          component="span"
          style={{ color: "grey" }}
        >
          &nbsp; ({getRecruiterData?.entity?.recruiterId})
        </Typography>
      </Typography>
    </Grid>
    <Grid className="custom-client-rspnsv" item xs={12} sm={6}>
      <Typography className="client-text" pr={2} sx={{
            textAlign: {
              xs: 'unset',  // For extra small screens
              sm: 'left',    // For small screens
              md: 'right',   // For medium screens
              lg: 'right',  // For large screens
              xl: 'right'     // For extra large screens
            }
          }}>
        Signed Up on : &nbsp;{" "}
        <span className="singnedDateText">
        {
          convertToLocalizedDateTime(getRecruiterData?.entity?.createdDateEST,"MMMM DD,YYYY,h:mm:ss A")
        }
        </span>
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} md={12}>
      <Paper elevation={1} sx={{padding:2}} >
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            {/* Row 0 */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} order={{ md: 1, sm: 2, xs: 2 }}>
                  
                  <Typography variant="h6">
                    Recruiter Information
                  </Typography>
                </Grid>
                <Grid item xs={7} order={{ md: 2, sm: 1, xs: 1 }}>
                  <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                    <Button
                    variant="outlined"
                        style={{ marginRight: "15px"}}
                        onClick={()=>{
                          navigate(getNevLink("/recruiters"))
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={pageStatus.loading}
                    endIcon={ternary(
                      pageStatus.loading,
                      <CircularProgress size={"16px"} color={"inherit"} />,
                      <>{""}</>
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                          console.log("Submit Data",formik.values);
                          console.log(formik.errors);
                          formik.handleSubmit()
                    }}
                    data-testid="submitIconBtn"
                  >
                    Update
                  </Button>
                  </Box>
                  {pageStatus.error && (<CustomAlert key={1} type={"error"} message={pageStatus.error} onClose={()=>{}}/>)}
                </Grid> 
              </Grid>
            </Grid>
                       
            {/* Row 1 */}
            <Grid item xs={12} md={4}>
              <FormControl
                required
                error={
                  formik.touched.firstName &&
                  Boolean(formik.errors.firstName)
                }
                variant="standard"
                fullWidth
              >
                    <InputLabel htmlFor="firstName" style={{color:"#4540db !important"}}>
                  First Name
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  name="firstName"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched.firstName &&
                    formik.errors.firstName
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                variant="standard"
                error={
                  formik.touched.middleName &&
                  Boolean(formik.errors.middleName)
                }
                fullWidth
              >
                <InputLabel htmlFor="middleName">
                  Middle initial
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.middleName}
                  name="middleName"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched.middleName &&
                    formik.errors.middleName
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                required
                error={
                  formik.touched.lastName &&
                  Boolean(formik.errors.lastName)
                }
                variant="standard"
                fullWidth
              >
                    <InputLabel htmlFor="lastName">
                      Last Name
                    </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name="lastName"
                  type="text"
                  data-testid="lastNameInput"
                />
                <ErrorMessage
                  errorText={
                        formik.touched.lastName &&
                        formik.errors.lastName
                  }
                />
              </FormControl>
            </Grid>
            {/* Row 2 */}          
            <Grid item xs={12} md={4}>
              <FormControl
                variant="standard"
                error={Boolean(
                  getIn(formik.touched, "email") &&
                    getIn(formik.errors, "email")
                )}
                fullWidth
                required
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                
                <CustomEmailInput 
                  name = "email" 
                  value={formik.values.email} 
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur} 
                  readOnly={false} 
                  isverified={Boolean(currentVerifiedEmail === formik.values.email)}
                /> 
                
                <ErrorMessage
                  errorText={
                    formik.touched.email && formik.errors.email
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} >
              <Grid container spacing={1} alignItems={"flex-end"}>
                  <Grid item xs={12} sm={7} md={8}>
                    <InputLabel htmlFor="phone-input" shrink={true} required>
                        Primary Phone
                    </InputLabel>
                    <FormControl variant="standard" fullWidth>                      
                    <PhoneInput
                      containerClass="phoneCountryCustom "
                      placeholder="Primary Phone"
                      country={countryShort}
                      onChange={(ev: any) => {
                        formik.setFieldValue(
                          "phoneNumbers.primary",
                          ev
                        );
                        onPrimaryPhChange(ev);
                      }}
                      value={formik.values.phoneNumbers?.primary}
                    />
                      { ternary(
                        displayPhoneVerifiedIcon(),
                        <img 
                          className="custom-email-verf-icon" 
                          style={{paddingLeft:"10px", position: "absolute", left: "100.28%", right: "-6.31%",top: "24%",bottom: "-7.5%"}} 
                          src={verifiedIcon}
                        ></img>,
                        null
                      )}
                    <ErrorMessage
                      errorText={
                        formik.touched.phoneNumbers?.primary &&
                        formik.errors.phoneNumbers?.primary
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                    {ternary(
                      displayPhoneVerifyComponent(),
                      <PhoneVerify {...props_Phone1Verify}></PhoneVerify>,
                      null
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} >
              <Grid container spacing={1} alignItems={"flex-end"}>
                    <Grid item xs={12} sm={7} md={8} >
                      <InputLabel shrink={true} htmlFor="Alternate Phone">Alternate Phone</InputLabel>
                    <FormControl variant="standard" fullWidth>
                    <PhoneInput
                      containerClass="phoneCountryCustom"
                      placeholder="Alternate Phone"
                      country={countryShort}
                      onChange={(ev: any) => {
                        formik.setFieldValue(
                          "phoneNumbers.secondary",
                          ev
                        );
                        onSecondaryPhChange(ev);
                      }}
                      value={formik.values.phoneNumbers?.secondary}
                    />
                        { (verifiedPhoneDetails.phoneNumbers?.secondary ===  formik.values.phoneNumbers?.secondary &&
                        formik.values.phoneNumbers?.secondary) ||  
                          formik.values.phoneNumbers?.isSecondaryVerified  ?
                          <img className="custom-email-verf-icon" style={{paddingLeft:"10px", position: "absolute", left: "100.28%", right: "-6.31%",top: "24%",bottom: "-7.5%"}} src={verifiedIcon}></img>
                          : null
                        }
                    <ErrorMessage
                      errorText={
                        formik.touched.phoneNumbers?.secondary &&
                        formik.errors.phoneNumbers?.secondary
                      }
                    />
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={5} md={4}>
                    {((verifiedPhoneDetails.phoneNumbers?.secondary !==  formik.values.phoneNumbers?.secondary) && !formik.values.phoneNumbers?.isSecondaryVerified && isValidPhoneNumber('+'+ formik.values.phoneNumbers?.secondary)) ? <PhoneVerify {...props_Phone2Verify}></PhoneVerify> : null}
                </Grid>
              </Grid>
            </Grid>
            {/* Row 3 */}

            <Grid item xs={12} md={4} >
              <PlacesAutocomplete
                searchOptions={searchOptions}
                value={formik.values?.address?.streetAddress}
                onChange={(ev: any) => {
                      formik.setFieldValue(
                        "address.streetAddress",
                        ev
                      );
                }}
                onSelect={autoCompleteGetAddress}
              >
                {renderFunc}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl
                variant="standard"
                error={Boolean(
                  getIn(formik.touched, "address.city") &&
                    getIn(formik.errors, "address.city")
                )}
                required
              >
                    <InputLabel htmlFor="address.city">
                      City
                    </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.address?.city}
                  name="address.city"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched.address?.city &&
                    formik.errors.address?.city
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl
                variant="standard"
                error={Boolean(
                  getIn(formik.touched, "address.state") &&
                    getIn(formik.errors, "address.state")
                )}
                className="state-input"
                required
              >
                <InputLabel htmlFor="address.state">
                  State
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.address?.state}
                  name="address.state"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched.address?.state &&
                    formik.errors.address?.state
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl
                variant="standard"
                error={Boolean(
                      getIn(
                        formik.touched,
                        "address.countryCode"
                      ) &&
                    getIn(formik.errors, "address.countryCode")
                )}
                className="state-input"
                required
              >
                <InputLabel htmlFor="address.countryCode">
                  Country
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.address?.countryCode}
                  name="address.countryCode"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched?.address?.countryCode &&
                    formik.errors.address?.countryCode
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl
                variant="standard"
                error={Boolean(
                  getIn(formik.touched, "address.postalCode") &&
                    getIn(formik.errors, "address.postalCode")
                )}
                required
              >
                <InputLabel htmlFor="address.postalCode">
                  Zip
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.address?.postalCode}
                  name="address.postalCode"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched?.address?.postalCode &&
                    formik.errors?.address?.postalCode
                  }
                />
              </FormControl>
            </Grid>
         
          {/* Row 4 */}
            < Grid item xs={12} sm={12} md={12}>
              <InputLabel>
                Current work location
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <PlacesAutocomplete
                searchOptions={searchCities}
                value={formik.values?.currentWorkLocation?.city}
                onChange={(ev: any) => {
                      formik.setFieldValue(
                        "currentWorkLocation.city",
                        ev
                      );
                }}
                onSelect={autoCompleteGetWorkLocation}
              >
                {renderFuncCurrentWorkLocation}
              </PlacesAutocomplete>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FormControl
                variant="standard"
                error={Boolean(
                      getIn(
                        formik.touched,
                        "currentWorkLocation.state"
                      ) &&
                        getIn(
                          formik.errors,
                          "currentWorkLocation.state"
                        )
                )}
                fullWidth
              >
                <InputLabel htmlFor="currentWorkLocation.state">
                  State
                </InputLabel>
                <Input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values?.currentWorkLocation?.state}
                  name="currentWorkLocation.state"
                  type="text"
                />
                <ErrorMessage
                  errorText={
                    formik.touched.currentWorkLocation?.state &&
                    formik.errors.currentWorkLocation?.state
                  }
                />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} mt={0.5}>
              <FormControl
                variant="standard"
                fullWidth  
              >
                <Autocomplete
                  fullWidth
                  id="size-small-standard-multi2"
                  size="medium"
                  options={timeZones.map(
                    (x: any) => x.value + " " + x.label
                  )}
                  value={formik.values.timezone}
                  defaultValue={formik.values.timezone}
                  onChange={(e, value) =>
                    formik.setFieldValue("timezone", value)
                  }
                  getOptionLabel={(option) => option}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        size="medium"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="TimeZone"
                    />
                  )}
                />
                <ErrorMessage
                  errorText={
                        formik.touched.timezone &&
                        formik.errors.timezone
                  }
                />
              </FormControl>
            </Grid>

           {/* Row 5 */}
       

           {/* Row 6 */}
            <Grid item xs={12} sm={3} md={2} mt={1}>
              <FormControl
                fullWidth
                variant="standard"
                required
                error={
                  formik.touched.experience &&
                  Boolean(formik.errors.experience)
                }
              >
                <InputLabel htmlFor="experience" shrink={true}>
                  Total Experience{" "}
                </InputLabel>
                <Input
                  name="experience"
                  onKeyDown={(evt) =>
                    evt.key === "-" && evt.preventDefault()
                  }
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="start">
                      Years
                    </InputAdornment>
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.experience}
                />

                <ErrorMessage
                  errorText={
                    formik.touched.experience &&
                    formik.errors.experience
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4.5} md={4} ml={5} mt={1}>
              <InputLabel
                shrink={true}
                required
              >
                Select Domain
              </InputLabel>
              {
              sortedDomainList?.map((domain: any, index:number) => {
                  return (
                    <FormControlLabel
                      key={domain?.id ?? index}
                      control={
                        <Checkbox
                              onChange={() =>
                                handleDomainChange(domain)
                              }
                              data-testid={`domainCheckbox-${index}`}
                        />
                      }
                      label={domain.domainName}
                      sx={{
                        marginLeft: "-10px",
                        marginTop: "0px",
                        marginBottom: "5px",
                      }}
                      className="checkbox-sml"
                      checked={formik.values.domains?.some(
                        (e: any) => e.id === domain.id
                      )}
                    />
                  );
                })}
                <ErrorMessage
                  errorText={
                    formik.touched.domains && formik.errors.domains
                  }
                />
            </Grid>
            <Grid item xs={12} sm={4.5} md={5} mt={1}>
            <InputLabel
                shrink={true}
                required
              >
                Your Interest
              </InputLabel>
            {interests.map((x,index:number) => {
                return (
                  <FormControlLabel
                    key={ x ?? index}
                    control={
                      <Checkbox
                        onChange={() => handleInterestChange(x)}
                        id={x}
                        data-testid={`handleInterestChange-${index}`}
                      />
                    }
                    label={x}
                    checked={formik.values?.interests?.some(
                      (e: any) => e === x
                    )}
                    sx={{
                      marginLeft: "-10px",
                      marginTop: "0px",
                      marginBottom: "5px",
                    }}
                    className="checkbox-sml"
                  />
                );
              })}

              <ErrorMessage
                errorText={
                      formik.touched.interests &&
                      formik.errors.interests
                }
              />
            </Grid>  

          {/* Row 7 */}           
          
            <Grid item xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                variant="standard"
                error={
                  formik.touched.linkedIn &&
                  Boolean(formik.errors.linkedIn)
                }
                className="linkedIn-input"
              >
                <InputLabel htmlFor="linkedin-input">
                  Linkedin Profile {getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.length === 0 && <Asterisk />}

                </InputLabel>
                    <Input className="linkd1"
                  name="linkedIn"
                  type="text"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "linkedIn",
                      event.target.value
                    );
                    formik.setFieldValue("profiles", [
                      {
                        id: "linkedIn",
                        type: "linkedIn",
                        link: event.target.value,
                      },
                    ]);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.linkedIn}
                />
                <ErrorMessage
                  errorText={
                        formik.touched.linkedIn &&
                        formik.errors.linkedIn
                  }
                />
              </FormControl>
            </Grid>

          {/* Row 8 */}
          <Grid item xs={12} mt={2}>
            <IndustryAutocomplete
              industries={formik.values.industries}
              onChange={handleIndustryChange}
              isRequired={false}
              placeholder="Please type in to search for the industry"
            />
            <ErrorMessage
              errorText={
                formik.touched.industries &&
                formik.errors.industries
              }
            />
          </Grid>

          {/* Row 9 */}
          <Grid item xs={12} mt={2}>
            <PlacesAutocomplete
              value={autoCompleteSuggesions}
              onChange={(ev: any) => {
                setautoCompleteSuggesions(ev);
              }}
              searchOptions={{ types: ["(cities)"] }}
            >
              {renderPreferredGeographies}
            </PlacesAutocomplete>

            <ErrorMessage
              errorText={
                formik.touched.preferredLocation &&
                formik.errors.preferredLocation
              }
            />
          </Grid>

          {/* Row 10 */}
          <Grid item xs={12} mt={2}>
            <InputLabel
            shrink={true}
            className="skillLabelRecuiter"
            >
              <span>Skills often recruited?</span>
            </InputLabel>
            <InputLabel
            shrink={true}
            className="skillLabelRecuiter max-skills-text"
            >
                Maximum 16 skills
            </InputLabel>
            <SkillAutocomplete
                skills={formik.values.skills}
                onChange={handleSkillChange}
              />
            <ErrorMessage
              errorText={
                formik.touched.skills &&
                formik.errors.skills
              }
              />
          </Grid>

          {/* Row 11 */}
          <Grid item xs={12} >
            <SectionHeading title='Documents' />
          </Grid>
          <Grid item xs={12} >
            <FileUploader
              editMode={true}
              isUploading={isUploading}
              fileName={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.fileName}
              resourceUrl={getDocumentsByType(formik.values.documentInfo, DocumentTypeEnum.Resume)?.[0]?.resourceUrl}
              fileError={fileError}
              onFileChange={handleUploadClick}
              setFilePreview={setFilePreview}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
                <ScreeningViewInfo 
                    documentInfo={formik.values.documentInfo}
                    setFilePreview={setFilePreview}
                    mode='Edit'
                />
            </Grid>

            {/* Row 12 */}
            <Grid item xs={12} sm={4} md={4} mb={2}>
              <TextField
                inputProps={{'data-testid':"hearAboutUsInput"}}
                fullWidth
                variant="standard"
                value={formik?.values?.hearAboutUs?.id ?? ""}
                onChange={(e)=>{
                formik.setFieldValue("hearAboutUs.id",e.target.value)
                let selectedValue = getHearAboutUsOptions?.hearAboutUsOptions?.find(( x: any) => x.id === e.target.value)
                formik.setFieldValue("hearAboutUs.option",selectedValue.option)

                if(e.target.value !== "f1ea0b28-50d7-4164-80d6-0f503a03d872"){
                  formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.id","")
                  formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.option","")
                    }
                  }}
                  select
                  label="How did you hear about us?"
                  name="hearAboutUs.id"
            
                >
                  {
                 getHearAboutUsOptions?.hearAboutUsOptions?.map((row : any,index : number)=>{
                      return (
                      <MenuItem value={row?.id} key={ row?.id ?? index}
                      >
                        {row?.option}</MenuItem>
                    )
                  })
                  }
                </TextField>
              <ErrorMessage errorText={formik.touched.hearAboutUs?.id && formik.errors.hearAboutUs?.id} />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              { formik.values.hearAboutUs.id ==="f1ea0b28-50d7-4164-80d6-0f503a03d872" &&
                <>
                  <TextField
                    inputProps={{'data-testid':"hearAboutUsSubOptionInput"}}
                    fullWidth
                    variant="standard"
                    value={formik.values?.hearAboutUs?.hearAboutUsSubOption?.id ?? ""}
                    onChange={(e)=>{
                      formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.id",e.target.value)
                      let selectedValue = socialSubOptionList?.subOptions?.find((x:any) => x.id === e.target.value)
                      formik.setFieldValue("hearAboutUs.hearAboutUsSubOption.option",selectedValue?.option)

                      if(e.target.value !== "bb6b3251-ce9c-4d76-9cab-64cf0478312d"){
                        formik.setFieldValue("hearAboutUs.otherSpecification.specifyOther","")
                              }
                            }}
                            select
                            label="Which Platforms?"
                            name="hearAboutUs.hearAboutUsSubOption.id"
                    
                          >
                    {
                    socialSubOptionList?.subOptions?.map((row :any,index : number)=>{
                                return (
                          <MenuItem value={row?.id} key={row?.id ?? index}>{row?.option}</MenuItem>
                        )
                      })
                              }
                          </TextField>
                  <ErrorMessage errorText={formik?.touched?.hearAboutUs?.hearAboutUsSubOption?.id && formik?.errors?.hearAboutUs?.hearAboutUsSubOption?.id} />
                        </>
                  }
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                { (formik.values?.hearAboutUs?.id ==="8dcb0133-6963-4308-bb5e-c5a0c98819b2" ||
                  formik.values?.hearAboutUs?.hearAboutUsSubOption?.id === "bb6b3251-ce9c-4d76-9cab-64cf0478312d") &&
                        <Box mt={0}>
                  <FormControl
                      variant="standard"
                      fullWidth
                      
                    >
                      <InputLabel htmlFor="first-name-input">
                        Other (Please specify)
                      </InputLabel>
                      <Input
                        data-testid="specifyOther"
                        name="hearAboutUs.otherSpecification.specifyOther"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.hearAboutUs?.otherSpecification?.specifyOther ?? ""}
                        type="text"
                        inputProps={{ maxLength: 500 }}
                      />
                      <ErrorMessage errorText={formik?.touched?.hearAboutUs?.otherSpecification?.specifyOther && formik?.errors?.hearAboutUs?.otherSpecification?.specifyOther} />
                    </FormControl>
                  </Box>
                  } 
            </Grid>
            <Grid item xs={12}>
                  {
                    getRecruiterData?.entity !== null &&
                    <AdminPaymentDetail
                      USAFormRef={USAFormRef}
                      PayoneerFormRef={PayoneerFormRef}
                      onChange={handlePaymentChange} 
                      selectedOption={paymentDetailsChangeStatus.selectedOption} 
                      editMode={true} 
                      values={formik.values.paymentDetailsChangeRequest} 
                      onCountryChange={onAdminCountryChange} 
                      billingDetail={{ userId: recruiterData.id, name: recruiterData.firstName, email: recruiterData.email, billingInfoType: recruiterData?.billingInfoType }} 
                    />
                  }
            </Grid>
          </Grid>
        </Box>
      </Paper>
        { getRecruiterData?.entity?.feedbackNotes?.length > 0 &&
          <Paper elevation={1} sx={{padding:2, marginTop:3}} >
              <Grid container>
                <Grid item xs={12}>
                  <FeedbackCommentsTimeline  type= "client"
                    commentDetails={getRecruiterData?.entity}
                  />
                </Grid>
              </Grid>
          </Paper>
        }
    </Grid>

      <Grid item xs={12} md={6}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              flexDirection: "row",
              "& .MuiFormControl-root": {
                marginTop: "15px",
                marginBottom: "3px",
              },
            }}
          ></Box>
        
      </Grid>
    </Grid>

    { open2 && 
      <EditRecruiterSuccessPopup 
        open={open2} 
        onClose={_handleOnUpdatePopupClose} />
    }

    {filePreview !== undefined &&
    <PreviewFileDialog
      open={filePreview !== undefined}
      handleOpenClose={() => setFilePreview(undefined)}
      fileName={filePreview.fileName}
      fileURL={filePreview.resourceUrl}
      fileType={filePreview.fileType}
    />
    }
   
    </>
  );
}

