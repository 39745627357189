import { makeStyles } from "@material-ui/core";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, Grid, Paper } from "@mui/material";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { PermissionsEnums } from "../../data/permission";
import { AppDateFormat } from "../../utils/dateUtils";
import { calculateSumWithPrecision } from "../../utils/numericUtils";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import { Fullname } from "../Common/Fullname";
import { ModuleComponent } from "../Common/Permission/ModuleComponent";
import { Payrate } from "../ui/Payrate";

const useStyles = makeStyles({
  tableCell: {
    width:"12.5%",
    flex: 1, // Make the columns equal size
  },
 });

export const AdminEarningTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <TableContainer component={Paper} className="adminearning-table-compnent">
          <AppInfiniteScroll
            dataLength={props?.data?.length}
            next={props.fetchMoreData}
            hasMore={props.hasMoreData}
            isLoading={props.loading}
            loader={true}
            height={"auto"}
          >
          <Table stickyHeader aria-label="sticky table" size="small" className="AppTableList">
            <TableHead className="AppTableListHeader">
              <TableRow>
                <TableCell  align="left">Recruiter</TableCell>
                <TableCell  align="left">Candidate</TableCell>
                <TableCell  align="left">Client</TableCell>
                <TableCell align="left">Project</TableCell>
                <TableCell align="left">Commission</TableCell>
                <TableCell align="left">Payment Terms</TableCell>
                <TableCell align="left">Commission Due Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.data.map((row,index) => {
                return <TableRow key={ row?.projectId ?? index} className={`AppTableList-table-row ${row?.isAdjustmentEntry ? "adjustment-row" : ""}`}>
                  <TableCell className={classes.tableCell}>{<Fullname fullName={row?.recruiterFullName}/>}</TableCell>
                  <TableCell className={classes.tableCell}>{<Fullname fullName={row?.candidateName}/>}</TableCell>
                  <TableCell className={classes.tableCell}>{row?.projectClientName}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <span className="projectId">{row?.projectId} </span>
                    <span className="projectName">{row?.projectName} </span>
                  </TableCell>                  
                  <TableCell className={classes.tableCell}>
                    <Payrate fontSize="0.875rem" packageType={""} packageValue={calculateSumWithPrecision(row?.totalCommission || 0, row?.oneTimeCommission || 0)} currency={row?.currencyType}/>
                  </TableCell>
                  <TableCell>{row?.paymentTerm}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <span className="commissionDueDateText">
                      {row?.commissionDueDate ? AppDateFormat(row?.commissionDueDate,"MMMM DD, YYYY") : "N/A"}
                    </span>
                  </TableCell>
                  <TableCell className={classes.tableCell}  >
                    <div style={{display:"flex", alignItems:"center"}}>
                      <VisibilityOutlinedIcon
                            data-testid={"viewEarningIcon"}
                            onClick={() => {
                              navigate(`/admin/ApproveEarning/${row?.submissionId}`, {state:{filterState:props?.filterState,selectedRow:row,mode:"viewMode",totalCommission:row?.totalCommission}})
                            }}
                            color="primary"
                            className="pointer custom-prview-dwn-icon"
                            sx={{verticalAlign: "bottom"}}
                          />
                     <ModuleComponent moduleId={PermissionsEnums.Earnings_Approve}>
                      { !row?.isApproved ? 
                        <Button size="small" variant="contained" style={{ marginLeft: "10px" }}
                          onClick={()=>{navigate(`/admin/ApproveEarning/${row?.submissionId}`, {state:{filterState:props?.filterState,selectedRow:row,mode:"ApproveMode",totalCommission:row?.totalCommission}})}}
                        >
                          Approve
                        </Button>
                        : null
                      }
                     </ModuleComponent>
                    </div>
                  </TableCell>
              </TableRow>})}
            </TableBody>
          </Table>
          </AppInfiniteScroll>
          </TableContainer>
          {props.loading && (
            <p
              style={{
                marginTop: "50px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              Please wait...
            </p>
          )}
          {!props.loading && props?.data.length === 0 && (
            <Alert severity="info" style={{ marginTop: "5px" }}>
              No Records Found
            </Alert>
          )}
          </Box>
      </Grid>
    </Grid>
    </>
  );
};
