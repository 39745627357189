import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { DeleteAttachment, DocumentType, UploadDocumentWithProgress } from "../../Services/uploadPhotoService";
import { DocumentTypeEnum } from "../../models/interfaces";
import { downloadFile, getFileFromUrl, getFilePreviewUrl } from "../../utils/getFileFromUrl";
import { DeleteDocumentIcon } from "../Common/Icons/DeleteDocument";
import { CustomAlert } from "../ui/CustomAlert";
import { getRandomInt } from "../../utils/randomUtils";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));
export const UploadAttachments = (props) => {
  const MAX_FILE_COUNT = 10;
  const MAX_FILES_SIZE = 10485760;


  //component states
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const hiddenFileInput = React.useRef(null);
  const [errorMessage, seterrorMessage] = useState({key:Date.now(),message:''})
  //holds value for fileURI after uploaded
  const [uploadProgress, setuploadProgress] = useState([]);
  //component functions
  const deleteUploadedFile = (file) => {
    let uploadedNew = uploadedFiles.filter((x) => x !== file);
    let uploadedInfoNew = uploadedFileInfo.filter(
      (x) => x.fileName !== file.name
    );
    DeleteAttachment(DocumentTypeEnum.Other, file.name);
    setUploadedFiles(uploadedNew);
    setUploadedFileInfo(uploadedInfoNew);

  };

  const uploadFile = (file) => {
    props?.handleLoading(true);
    const config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        let uploadProgressWithFile = {
          fileName: file.name,
          progressPercent: percentCompleted,
        };
        setuploadProgress((prevState) => [
          ...prevState,
          uploadProgressWithFile,
        ]);
        //remove from uploading progress
        if (percentCompleted == 100) {
          setSelectedFiles(selectedFiles.filter((x) => x !== file));
          props?.handleLoading(false);
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    UploadDocumentWithProgress(DocumentTypeEnum.Other, file, config)
      .then((response) => {
        if (response && response?.data?.status === 200) {
          let fileInfo = {
            resourceUrl: response?.data?.entity?.absoluteUri,
            fileName: response?.data?.entity?.fileName,
            newFileName: response?.data?.entity?.newFileName,
            documentType: DocumentTypeEnum.Other,
          };
          setUploadedFileInfo((prevState) => [...prevState, fileInfo]);
          setUploadedFiles((prevState) => [...prevState, file]);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleFileUpload = (files) => {
    files.map((x) => {
      //resolve duplicate upload
      if (!uploadedFiles.includes(x)) {
        uploadFile(x);
      }
    });
  };
  const handleFileChange = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    if (files.length > MAX_FILE_COUNT) {
      seterrorMessage({key:Date.now(), message:`Maximum upload file limit is ${MAX_FILE_COUNT}`})
    } else {
      files.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          if (uploaded.length === MAX_FILE_COUNT) {
            seterrorMessage({key:Date.now(), message:`Maximum upload file limit is ${MAX_FILE_COUNT}`})
            limitExceeded = true;
            return;
          }
          uploaded.push(file);
        }
      });
      if (!limitExceeded) {
        setSelectedFiles(uploaded);
        handleFileUpload(uploaded);
      }
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.files) {
      const chosenFiles = Array.prototype.slice.call(e.dataTransfer?.files);
      checkSizeLimit(chosenFiles);
      if (chosenFiles.length == 0) return;
    }
  };
  const checkSizeLimit = (files) => {
    let duplicates = files?.filter((el) =>
      uploadedFiles?.find((up) => up.name == el.name)
    );
    if (duplicates.length != 0) {
      seterrorMessage({key:Date.now(), message:`Duplicate files are not allowed`})
      return;
    }
    let size =
      files.length > 0
        ? files.map((item) => item.size).reduce((prev, next) => prev + next)
        : 0;
    files = files?.filter(
      (el) => !uploadedFiles?.find((up) => up.name == el.name)
    );

    if (size === 0) {
      return;
    } else if (size + totalSize > MAX_FILES_SIZE) {
      seterrorMessage({key:Date.now(), message:`Maximum file size is 10MB`})
      hiddenFileInput.current.value = null; // Clear the input value to trigger onChange event on selecting the same file
      hiddenFileInput.current.click();
      return;
    } else {
      handleFileChange(files);
    }
  };
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    if (chosenFiles.length == 0) return;
    checkSizeLimit(chosenFiles);
  };
  //effects
  useEffect(() => {
    //inititally set files as uploaded which are uploaded already
    async function fetchFileBlob() {
        props?.handleLoading(true);
        await Promise?.all(props?.documents?.map(async (x)=> {
                if(x?.resourceUrl!=='' && x?.fileName!=='')
                {
                   let fBlob = await getFileFromUrl(x?.resourceUrl,x?.fileName,props?.authToken)
                  
                   setUploadedFiles(prevState => [...prevState, fBlob])
                   setUploadedFileInfo(prevState => [...prevState, x])
        
                }
            }
        ))
        props?.handleLoading(false);
      }
      fetchFileBlob()
    }, [props?.documents])
    useEffect(() =>{
        let size = uploadedFiles?.length > 0 ? uploadedFiles?.map(item => item?.size).reduce((prev, next) => prev + next) : 0;
        setTotalSize(size);
        if(props?.onChange){
            props?.onChange(uploadedFileInfo?.map(x=>{
                x.documentType = DocumentType?.OtherDoc;
                return x;
            }));
        }
    },[uploadedFileInfo])

    const sortingFunction = ({ progressPercent: a }, { progressPercent: b }) => b - a;
    const sortedProgress = [...uploadProgress].sort(sortingFunction);
    
    return (
        <>
         <Grid container key={getRandomInt()} spacing={2}>
            <Grid item className="docInnerContainer ">
                <Item elevation={0}>
                    <Box mt={{ xs: 1, sm: 1, md: 0 }} >
                        <Box
                            className="form-three-child"
                        >   
                            {props?.isHideLabel ? "" :
                            <InputLabel
                                shrink={true}
                                className={`${props.classes ? props.classes : ""}`}
                            >
                               Other Documents
                            </InputLabel>
                            }
                        </Box>
                       <div id="form-file-upload"
                        data-testid={"form-file-upload-onDrop"}
                       onDragOver={(e) =>{
                        e.preventDefault();
                       }} 
                       onDrop={(e) => {
                        e.preventDefault();
                        handleDrop(e);
                       }}>
                       <Box className={`form-upload file-upload-wrapper ${props?.classNames}`} mt={1} mb={1} data-testid={"wrapperOtherDoc-onclick"} onClick={() => {hiddenFileInput.current.click();}}>
                            <Box className="file-upload-wrapper-inner">
                            <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ paddingTop: 13 }}
                    >
                      <path
                        d="M17.4373 3.09961H8.1373C7.71105 3.09961 7.3623 3.44836 7.3623 3.87461V26.3496C7.3623 26.7759 7.71105 27.1246 8.1373 27.1246H23.6373C24.0636 27.1246 24.4123 26.7759 24.4123 26.3496V10.0746L17.4373 3.09961Z"
                        fill="#00B2AE"
                      />
                      <path
                        d="M23.6371 27.5129H8.13711C7.47836 27.5129 6.97461 27.0091 6.97461 26.3504V3.87539C6.97461 3.21664 7.47836 2.71289 8.13711 2.71289H17.5921L24.7996 9.92039V26.3504C24.7996 27.0091 24.2959 27.5129 23.6371 27.5129ZM8.13711 3.48789C7.90461 3.48789 7.74961 3.64289 7.74961 3.87539V26.3504C7.74961 26.5829 7.90461 26.7379 8.13711 26.7379H23.6371C23.8696 26.7379 24.0246 26.5829 24.0246 26.3504V10.2304L17.2821 3.48789H8.13711Z"
                        fill="#231C1C"
                      />
                      <path
                        d="M17.4375 9.29961C17.4375 9.72586 17.7862 10.0746 18.2125 10.0746H24.4125L17.4375 3.09961V9.29961Z"
                        fill="#008181"
                      />
                      <path
                        d="M24.4127 10.4625H18.2127C17.5539 10.4625 17.0502 9.95876 17.0502 9.30001V3.10001C17.0502 2.94501 17.1277 2.79001 17.2827 2.75126C17.4377 2.67376 17.5927 2.71251 17.7089 2.82876L24.6839 9.80376C24.8002 9.92001 24.8389 10.075 24.7614 10.23C24.7227 10.385 24.5677 10.4625 24.4127 10.4625ZM17.8252 4.03001V9.30001C17.8252 9.53251 17.9802 9.68751 18.2127 9.68751H23.4827L17.8252 4.03001ZM10.0752 13.175H21.3127V13.95H10.0752V13.175ZM10.0752 15.8875H21.3127V16.6625H10.0752V15.8875Z"
                        fill="#231C1C"
                      />
                      <path
                        d="M10.0752 18.5996H21.3127V19.3746H10.0752V18.5996Z"
                        fill="#231C1C"
                      />
                      <path
                        d="M10.0752 21.6992H21.3127V22.4742H10.0752V21.6992Z"
                        fill="#231C1C"
                      />
                    </svg>
                  </Box>
                  <Typography  className={`custom-flex-wrapper ${props.classes ? props.classes : ""}`} variant="h4" style={{marginTop:8}}>
                    Drag & drop files
                    </Typography>
                    <Typography className={`custom-flex-wrapper ${props.classes ? props.classes : ""}`} variant="h4" style={{marginTop:0, fontSize:12}}>
                    or click to select from directory
                    </Typography>
                    <input
                      ref={hiddenFileInput}
                      type="file"
                      hidden
                      multiple
                      accept={props?.acceptedFormats}
                      onChange={(e) => {
                        handleFileEvent(e);
                      }}
                      data-testid={"inp-handleFileEvent"}
                      className="inp-handleFileEvent"
                    />
                  
                  <Typography className={`small-text ${props.classes ? props.classes : ""}`}>
                    Supported formats:{" "}
                    {props?.acceptedFormats ?? props?.acceptedFormats
                      ?.replaceAll(".", " ")
                      ?.toUpperCase()}
                  </Typography>
                 
                <br/>

                </Box>
                <div style={{width:'320px'}}>
                {errorMessage.message!='' &&<CustomAlert message={errorMessage.message} key={errorMessage.key} type={'error'}/> } 
                </div>  
              </div> 

                    </Box>
                </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} >
                <Item  className="uploading-rapper-main" elevation={0}>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className=""
                    >
                        {selectedFiles?.length != 0 && `Uploading - ${selectedFiles?.length}  files`}
                    </Typography>
                    {selectedFiles.map(file => (
                      sortedProgress.find(x => x.fileName === file.name)?.progressPercent === 100 ?
                        null
                        :(                     
                        <Box key={file.lastModified} className="upload-wrapper-text">
                            <Typography
                                variant="subtitle1"
                                component="div"
                                className=""
                            >
                                {file.name}
                            </Typography>                          
                            <progress
                              id="file"
                              value={sortedProgress.find(x => x.fileName === file.name)?.progressPercent}
                              max="100"
                            >
                            </progress>
                        </Box>)
                    ))}
                    <Box mt={{ xs: 2, sm:0, md:0 }}>
                        <InputLabel
                            className={`${props.classes ? props.classes : ""}`}
                            shrink={true}
                        >
                         {uploadedFiles?.length != 0 && `Uploaded - ${(totalSize/1048576)?.toFixed(2) } MB/10 MB `}
                        </InputLabel>
                    </Box>
                    <Box className="main-wrapper-uploader">
                        {uploadedFiles?.map((file, ind) => {
                            return <Box key={file?.lastModified} className="upload-wrapper-text success-upload">
                                    <Typography
                                        variant="subtitle1"
                                        component="div"
                                        className="roboto-font title-text V2themefont"
                                    >
                                        {file?.name}
                                    </Typography>
                                    {uploadedFileInfo[ind]?.resourceUrl !== undefined
                                    && uploadedFileInfo[ind]?.resourceUrl !== ""
                                    &&
                                    <>
                                        {file?.type !== "application/x-zip-compressed" // cannot preview zip files
                                        && uploadedFileInfo[ind]?.fileName.split(".")[1]?.toLowerCase() !== "zip"
                                        && props?.setFilePreview !== undefined
                                        &&
                                        <VisibilityOutlinedIcon
                                            onClick={() => {
                                                getFilePreviewUrl(uploadedFileInfo[ind]?.resourceUrl, props?.authToken)
                                                .then(filePreviewUrl => props.setFilePreview({
                                                    resourceUrl: filePreviewUrl,
                                                    fileName: uploadedFileInfo[ind]?.fileName
                                                }));
                                            }}
                                            color="primary"
                                            className="pointer"
                                            sx={{pl: 1, verticalAlign: "bottom"}}
                                        />
                                        }
                                        <SaveAltOutlinedIcon
                                            onClick={() => {
                                                downloadFile(
                                                    uploadedFileInfo[ind]?.resourceUrl,
                                                    uploadedFileInfo[ind]?.fileName,
                                                    props?.authToken
                                                );
                                            }}
                                            color="primary"
                                            className="pointer"
                                            sx={{pl: 1, verticalAlign: "bottom"}}
                                        />
                                        <DeleteDocumentIcon
                                            data-testid={"deleteOtherDoc-btn"}
                                            onClick={(e) => deleteUploadedFile(file)}
                                            inheritViewBox
                                            className="pointer"
                                            sx={{pl: 1, verticalAlign: "bottom", height: "20px", width: "20px"}}
                                        />
                                    </>
                                    }
                                </Box>
                            })
                        }
                    </Box>
                </Item>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Typography
                  sx={{fontSize:'12px'}}
                  className={`small-text ${props.classes ? props.classes : ""}`}
              >
                (Max file size: 10MB as {props.acceptedFormats ?? props.acceptedFormats?.replaceAll("."," .")}) <br/>
                  Required attachments:
                  For all candidates Photo ID with Address (Drivers License or Passport etc.)
                  For immigrant and non-immigrant candidates Copy of Employment Authorization
                  Document (H1B, EAD, GC etc.), a copy of the photo page of the passport or mention
                  passport number in “Recruiter Comments”.
              </Typography>
            </Grid>
        </Grid>
        </>
    )
}
