import React, { useEffect, FC } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";
import { useParams} from "react-router-dom";
import { GetRecruiterById } from "../../../Services/recruiterService";
import useApi from "../../../hooks/useApi";
import StatusBar from "../../Common/StatusBar";
import { RecruiterViewInfo } from "../components/RecruiterViewInfo";
import { FeedbackCommentsTimeline } from "../components/FeedbackCommentsTimeline";
import { ViewHearAboutUs } from "../components/ViewHearAboutUs";
import RecruiterPaymentDetails from "../components/RecruiterPaymentDetails";
import { getNevLink } from "../../../utils/getFileFromUrl";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormikProps } from "formik";

const VerifyHistory: FC = () => {

  const DefaultBreadcrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to:getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Recruiters", to: `${ getNevLink('/recruiters')}` },
    { isLeaf: true, sequence: 3, title: "Verify", leafClasses:" v2LastLeft"},
  ];

  let { id } = useParams();
  const USAFormRef = React.useRef<FormikProps<any>>(null);
  const PayoneerFormRef = React.useRef<FormikProps<any>>(null);

  const getRecruiterAPI = useApi(() => GetRecruiterById(id ?? "")) as any;

  useEffect(() => {
    getRecruiterAPI.request();
  }, []);

  return (
    <div>
      <Grid container>
      <Grid item xs={12} >
          <AppBreadCrumb
          classes="custom-appbreadcrumb"
          BreadCrumbItems={DefaultBreadcrumbs}
        />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className="Client-txt-fontwt">
            Manage Recruiter
            <Typography
              variant="h6"
              component="span"
              style={{ color: "grey" }}
            >
              &nbsp; ({getRecruiterAPI.data?.entity?.recruiterId})
            </Typography>
            {
              getRecruiterAPI.data?.entity?.status == "Approved" 
              ? 
                <CheckCircleIcon  className="verifyRecruiterIcon"/> 
              : 
              null              
            } 
          </Typography>
        </Grid>
        
        <Grid className="custom-client-rspnsv" item xs={12} sm={6}>
          <Typography className="client-text" pr={2} sx={{
                textAlign: {
                  xs: 'unset',  // For extra small screens
                  sm: 'left',    // For small screens
                  md: 'right',   // For medium screens
                  lg: 'right',  // For large screens
                  xl: 'right'     // For extra large screens
                }
              }}>
            Signed Up on : &nbsp;{" "}
            <span className="singnedDateText">
            {
              convertToLocalizedDateTime(getRecruiterAPI.data?.entity?.createdDateEST,"MMMM DD,YYYY,h:mm:ss A")
            }
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12} md={12} mt={2}>            
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} >   
                <Paper elevation={2} sx={{mb:3, pl:1, pb:2}}>
                  <RecruiterViewInfo recruiterDetails={getRecruiterAPI.data?.entity}/>                 
                  <ViewHearAboutUs recruiterDetails={getRecruiterAPI.data?.entity} />
                  <Box sx={{marginTop:1}}>
                    <RecruiterPaymentDetails 
                    recruiter={getRecruiterAPI.data?.entity} 
                    USAFormRef={USAFormRef}
                    PayoneerFormRef={PayoneerFormRef}
                    />
                  </Box>                 
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </div>
  );
};

export default VerifyHistory;
