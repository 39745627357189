import { Industry, KeyValue, Paging, SortType } from "../app/AppModels";

export interface ClientFilter extends Paging {
  searchKeyword?: string;
  industries: string[];
  locations: string[];
  statuses: string[];
}

export interface MemberFilter extends Paging {
  searchKeyword?: string;
  locations?: string[];
  statuses?: string[];
  clientGuid?: string
}

export const clientFilterDefault: ClientFilter = {
  isSorted: true,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "createdDate",
  sortOrder: SortType.Descending,
  totalRecord: 0,
  industries:[],
  locations:[],
  statuses:[]
};

export interface ClientStateType {
  filter: {
    industries: Industry[];
    locations: any[];
    statuses: KeyValue[];
    searchKeyword?: string;
  };
}

export interface TeamMemberStateType {
  filter: {
    locations: any[];
    statuses: KeyValue[];
    searchKeyword?: string;
  };
}

export const teamMemberFilterDefault: MemberFilter = {
  isSorted: true,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "createdDate",
  sortOrder: SortType.Descending,
  totalRecord: 0,
};
