import { Alert, Grid, Paper, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./AllCompensation.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useSelector } from "react-redux";
import { GetRecruiterCompensation } from "../../../Services/invoiceManagerService";
import { PermissionsEnums } from "../../../data/permission";
import convertToLocalizedDateTime from "../../../utils/AppDateUtils";
import constructName from "../../../utils/constructName";
import { getNevLink } from "../../../utils/getFileFromUrl";
import AppInfiniteScroll from "../../Common/AppInfiniteScroll";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { Searchbar } from "../../ui/Searchbar";

export const AllCompensation: React.FC = () => {
  const { features, modules } = useSelector(
    (state: any) => state.userPermissions
  );
  const combinedArray = [...features, ...modules];
  const permissionsArray = combinedArray || [];
  const viewMode = !permissionsArray.includes(
    PermissionsEnums.Compensation_Update
  );
  let initialFilter = {
    pageIndex: 1,
    pageSize: 10,
    sortColumn: "lastModified",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
  };

  let DefaultBreadcrumbs: BreadCrumbItem[] = [
    {
      isLeaf: false,
      sequence: 1,
      title: "Dashboard",
      to: getNevLink("/dashboard"),
    },
    {
      isLeaf: true,
      sequence: 2,
      title: "Individual Compensation",
      leafClasses: " v2LastLeft",
    },
  ];
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "310px" : "300px";
  const [filter, setfilter] = React.useState(initialFilter);
  const [data, setData] = React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);
  const [hasMoreData, setHasMoreFlag] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    getRecruiterCompensation(true);
  }, [filter.searchKeywords, filter.sortColumn, filter.sortOrder]);
  React.useEffect(() => {
    if (filter.pageIndex !== 1) {
      getRecruiterCompensation();
    }
  }, [filter.pageIndex]);

  const getRecruiterCompensation = (search = false) => {
    setLoading(true);
    GetRecruiterCompensation(filter)
      ?.then((response) => {
        console.log(response.data.entityList.length);
        if (response.data.entityList.length < filter.pageSize) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }

        if (search) {
          setData([...response.data.entityList]);
        } else {
          setData([...data, ...response.data.entityList]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  const fetchMoreData = () => {
    setfilter({ ...filter, pageIndex: filter.pageIndex + 1 });
  };
  const handleSort = (type: any) => {
    if (type == "name") {
      setfilter({
        ...filter,
        sortColumn: "recruiterFirstName",
        sortOrder: filter.sortOrder == "ascending" ? "descending" : "ascending",
        pageIndex: 1,
      });
    }
    if (type == "date") {
      setfilter({
        ...filter,
        sortColumn: "lastModified",
        sortOrder: filter.sortOrder == "ascending" ? "descending" : "ascending",
        pageIndex: 1,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={DefaultBreadcrumbs}
          />
        </Grid>
        <Grid item xs={12} md={12} data-testid="search">
          <Searchbar
            classes="ClientListingSearchbarContainer"
            isNewSeachBar={true}
            width={searchBarWidth}
            height="1.7rem"
            placeHolderText={"Search"}
            txtFontSize={"14px"}
            marginBottom="16px"
            marginLeft="0px"
            value={filter.searchKeywords}
            onChange={(e: any) =>
              setfilter({
                ...filter,
                searchKeywords: e.target.value,
                pageIndex: 1,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TableContainer component={Paper}>
            <AppInfiniteScroll
              dataLength={data.length}
              next={fetchMoreData}
              hasMore={hasMoreData}
              isLoading={loading}
              loader={true}
            >
              <Table aria-label="simple table" className="allcompensationTable">
                <TableHead className="allcompensation-fontsizes">
                  <TableRow>
                    <TableCell align="left" sx={{ px: 1, borderTop: 0 }}>
                      <span
                        data-testid={"sortName"}
                        onClick={() => handleSort("name")}
                        className="custom-arrw-btn"
                      >
                        Name{" "}
                        {filter.sortOrder == "ascending" ? (
                          <KeyboardArrowUpIcon className="sort-Job custom-arrw" />
                        ) : (
                          <KeyboardArrowDownIcon className="sort-Job custom-arrw" />
                        )}
                      </span>
                    </TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>Email</TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>C2C</TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>W2</TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0, minWidth: "100px" }}>
                      Full Time
                    </TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>OTC(USD)</TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>
                      <span
                        onClick={() => handleSort("date")}
                        className="custom-arrw-btn"
                      >
                        Last Modified
                        {filter.sortOrder == "ascending" ? (
                          <KeyboardArrowUpIcon className="sort-Job custom-arrw" />
                        ) : (
                          <KeyboardArrowDownIcon className="sort-Job custom-arrw" />
                        )}
                      </span>
                    </TableCell>
                    <TableCell sx={{ px: 1, borderTop: 0 }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <React.Fragment>
                    {data.map((item: any, index: number) => (
                      <TableRow
                        key={item?.lastModified ?? index}
                        className="hover-row v2-table-row"
                      >
                        <TableCell
                          sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}
                        >
                          {constructName(
                            item.recruiterFirstName,
                            item.recruiterMiddleName,
                            item.recruiterLastName
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {item.recruiterEmail}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {item.directContractCommission || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {item.w2Commission || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {item.fullTimeCommission || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {item.oneTimeCommission || "N/A"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          {convertToLocalizedDateTime(
                            item.lastModified,
                            "MMMM DD, YYYY  h:mm:ss A"
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "nowrap",
                            px: 1,
                            width: 100,
                            fontSize: "14px !important",
                          }}
                        >
                          <Button
                            disabled={viewMode}
                            variant="outlined"
                            className="roboto-font-sizes"
                            color="primary"
                            onClick={() =>
                              navigate(
                                getNevLink(
                                  `/compensation/individualrecruiter?recruiterGuid=${
                                    item.recruiterGuid
                                  }&recName=${constructName(
                                    item.recruiterFirstName,
                                    item.recruiterMiddleName,
                                    item.recruiterLastName
                                  )}`
                                )
                              )
                            }
                          >
                            Modify
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                </TableBody>
              </Table>{" "}
              {!loading && data.length === 0 && (
                <Alert severity="info">No Recruiter found</Alert>
              )}
            </AppInfiniteScroll>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
export default AllCompensation;
