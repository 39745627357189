import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {SuspendIcon } from "../../Common/Icons/SuspendIcon";
import { IncompleteIcon } from "../../Common/Icons/IncompleteIcon";
import { RejectIcon } from "../../Common/Icons/RejectIcon"
import { ApproveIcon } from "../../Common/Icons/ApproveIcon"
import RecruiterStatusUpdatePopup from './RecruiterStatusUpdatePopup';
import { RecruiterStatus } from "../../../models/interfaces";
import ShareContractDemoContract from './ShareContractDemoContract/ShareContractDemoContract';

interface IPROPS{
    handleActionBtn:any;
    handleDocumentsPopupClose:any;
    isOpenDialog:any;
    handlePopupSubmit:any;
    recruiterDetails:any;
}
export const RecruiterActions: React.FunctionComponent<IPROPS> = (props) => {
   const showContractFeature = false
    const handleRecruiterAction = (btnType : string, action:boolean)=>{
           props.handleActionBtn(btnType,action)
    }

    return (
        <>
        <Box sx={{ flexGrow: 1 }}>

        <Stack direction="row" justifyContent="right" alignItems="center" spacing={2}>
            { showContractFeature && props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Approved] && 
                <ShareContractDemoContract 
                recruiterDetails={props?.recruiterDetails}
                />
            }

            { (props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Pending] ||
             props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Approved] 
            ) &&
            <Button variant="outlined" startIcon={<SuspendIcon />} size="small" className="action-btn fontsize-verify"
            onClick={
                ()=>{ handleRecruiterAction(RecruiterStatus[RecruiterStatus.Suspended],true) }
             }
            >
                Suspend
            </Button>
            }
            { props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Pending] &&
            <Button variant="outlined" startIcon={<IncompleteIcon />} size="small" className="action-btn fontsize-verify"
            onClick={
                ()=>{ handleRecruiterAction(RecruiterStatus[RecruiterStatus.MarkIncomplete],true) }
             }
            >
                Incomplete
            </Button>
            }
            { (props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Pending] ||
                props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.MarkIncomplete]
            ) &&
            <Button variant="outlined" startIcon={<RejectIcon />} size="small" className="action-btn fontsize-verify"
            onClick={
                ()=>{ handleRecruiterAction(RecruiterStatus[RecruiterStatus.Rejected],true) }
             }
            >
                Reject
            </Button>
            }
            { (props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Pending] ||
                props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.MarkIncomplete] || 
                props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Rejected] ||
                props.recruiterDetails?.status === RecruiterStatus[RecruiterStatus.Suspended]
            )
            
            &&
            <Button variant="outlined" startIcon={<ApproveIcon />} size="small" className="action-btn fontsize-verify"
            onClick={
                ()=>{ handleRecruiterAction(RecruiterStatus[RecruiterStatus.Approved],true) }
             }
            >
                Approve
            </Button>
                }
            </Stack>

            {
            props.isOpenDialog &&
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RecruiterStatusUpdatePopup 
                        openPopup={props.isOpenDialog}
                        handleDocumentsPopupClose={props.handleDocumentsPopupClose}
                        handlePopupSubmit={props.handlePopupSubmit}
                        />
                    </Grid>
                </Grid>
            }
        </Box>
        </>
    )
}