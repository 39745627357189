import axios from "axios"

export const getShareJobPdfFile = async (body:any) =>{
    return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Job`,body,"ShareJob.pdf")
}

export const getSubmissionChangeLogPdfFile = async (body:any, authToken?:string) =>{
  return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Submission`,body,"change-log.pdf", authToken)
}

export const getInterviewDetailPdfFile = async (body:any) =>{
  return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Interview`,body,"Interview-Detail.pdf")
}


export const getScoreCardPdfFile = async (body:any, token?: string) =>{
  return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Scorecard`,body,"Score-card.pdf", token)
}

export const getPdfReportFile = async (resourceUrl:string,body:any,fileName:string, token?: string) => {

    let response = await fetch(resourceUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${ token ?? localStorage.getItem("accessToken") ?? ""}`,
        'Content-Type': 'application/json'
      },
      body:JSON.stringify(body)
    });
    let blobFile = await response.blob();
    return new File([blobFile], fileName);
  };

  export const getEarningFile = async (body:any) =>{
    return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Earning`,body,"Earning.pdf")
  }
  export const getCandidateFile = async (body:any) =>{
    return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/Candidate`,body,"Candidate.pdf")
  }

  export const recruiterScreeningResponsePDF = async (body:any, token:string) =>{
    return await getPdfReportFile(`${process.env.REACT_APP_RESOURCE_API}api/CreatePdf/RecruiterScreening`,body,"RecruiterProfileScreening.pdf",token)
  }

  export const recruiterSignedContractResponsePDF = async (signatureRequestId:any, token:string = "") =>{
    return await getPdfReportFile(`${process.env.REACT_APP_INTEGRATION_API}api/Contract/download-contract?signatureRequestId=${signatureRequestId}`,{signatureRequestId:signatureRequestId},"RecruiterSignedContract.pdf",token)
  }