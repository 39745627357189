import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { recruiterDashboardActivitiesCount } from "../../features/dashboard/recruiterDashboardSlice";
import { recruiterGraphicalDashboard } from "../../features/dashboard/recruiterGraphicalDashboardSlice";
import { setRecruiterSubmissionState as setActiveRecruiterSubmissionState } from "../../features/recruiterSubmission/activeRecruiterSubmissionSlice";
import { setRecruiterSubmissionState as setRejectedRecruiterSubmissionState } from "../../features/recruiterSubmission/rejectedRecruiterSubmissionSlice";
import { setFilterType, setStatusIds } from "../../features/submission/submissionSlice";
import formatAmount from "../../utils/formatAmount";
import { getNevLink } from "../../utils/getFileFromUrl";
import BannerText from "../Common/BannerText";
import { ExpiringJob } from "../Common/ExpiringJob";
import { StatusEnumList } from "../Common/SubmissionStatusEnumList";
import DashboardIcon1 from "./../../assets/images/dashboard-icon-1.svg";
import DashboardIcon2 from "./../../assets/images/dashboard-icon-2.svg";
import DashboardIcon3 from "./../../assets/images/dashboard-icon-3.svg";
import DashboardIcon4 from "./../../assets/images/dashboard-icon-4.svg";
import ActivityScrore from "./Cards/ActivityScore";
import Highlights from "./Cards/Highlights";
import RelevantCandidates from "./Cards/RelevantCandidates";
import SatisfactionTrend from "./Cards/SatisfactionTrend";
import ScoreBySkills from "./Cards/ScoreBySkills";
import StatisticsCard from "./Cards/StatisticsCard";
import PerformanceByScore from "./Cards/TechStatsCard";
import TopProfiles from "./Cards/TopProfiles";
import TopSubmissions from "./Cards/TopSubmissions";
import TotalResponses from "./Cards/TotalResponses";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

export default function Dashboard() {
  const dispatch = useDispatch();
  const{data} = useSelector((state) => state.recruiterDashboard)
  const{totalResponse} = useSelector((state) => state.recruiterGraphicalData)

  let activeSubmissionState =  useSelector((state) => state.activeRecruiterSubmissionState)
  let rejectedSubmissionState = useSelector((state) => state.rejectedRecruiterSubmissionState)
  let navigate = useNavigate()
  
  const handleCardClick = (statusIds, submissionType='') => {
    if (submissionType === 'active') {
      dispatch(setActiveRecruiterSubmissionState({
        ...activeSubmissionState,
        pageIndex : 1,
        statusIds: statusIds,
        durationFilter: { duration: "0", durationType: "All" },
        candidateGuids: [],
        recruiterFilter: [],
        selectedRecruiter: undefined,
        searchKeywords: "",
        statusClearAll: true
      }));
      dispatch(setStatusIds({statusIds:statusIds, filterType :"filter"}))
      dispatch(setFilterType("filter"))
    }
    else {
      dispatch(setRejectedRecruiterSubmissionState({
        ...rejectedSubmissionState,
        pageIndex : 1,
        statusIds: statusIds,
        durationFilter: { duration: "0", durationType: "All" },
        candidateGuids: [],
        recruiterFilter: [],
        selectedRecruiter: undefined,
        searchKeywords: "",
        statusClearAll: true
      }));
      dispatch(setFilterType("pendingFilter"))
      dispatch(setStatusIds({statusIds:statusIds, filterType :"pendingFilter"}))
    }
    
    navigate(getNevLink(`/submission/${submissionType}`));
  }

  useEffect(() => {
    dispatch(recruiterDashboardActivitiesCount())
    dispatch(recruiterGraphicalDashboard())

  }, [])
  
  let statisticsCount = data?.entity?.recruiterDashboard?.recruiterDashboardData?.statistics
  let graphicalData = totalResponse?.entity?.recruiterGraphicalDashboard

  return (
    <div>
     
      <ExpiringJob type="Recruiter"/>
      <BannerText bannerText="Snapshot of your activities" bannerType="secondary"/>
        
            <Item elevation={0}>
              
              <Typography
                variant="h2"
                color="inherit"
                component="span"
                className="abel abelh3 primary-color-text boldface"
                my={3}
              >
                Your Statistics
              </Typography>

              <Box>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "left", textAlign: "left" }}
                  className="dashboard-sml-cards"
                >
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }}  style={{ cursor:"pointer"}} 
                      data-testid="savedJobsNav"
                      onClick={() => {
                        localStorage.removeItem("jobAdvanceSearchData");
                        navigate('/savedJobs/recruiter');
                      }}
                    >
                      <StatisticsCard
                        bg="bg1"
                        yourStatText="Jobs you worked on"
                        bigNo={formatAmount(statisticsCount?.jobsWorkedOn)}
                        sinceText="Active"
                        icon={DashboardIcon1}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                      data-testid="SubmissionsNav"
                      onClick={()=>{
                        // need to set empty to overwrite retained info
                        handleCardClick([], 'active');
                      }}
                    >
                      <StatisticsCard
                        bg="bg2"
                        yourStatText="Submissions"
                        bigNo={formatAmount(statisticsCount?.submissions)}
                        sinceText="Active"
                        icon={DashboardIcon2}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                      data-testid="interviewsScheduledNav"
                      onClick={()=>{
                        // status corresponds to interview scheduled filter option
                        handleCardClick([StatusEnumList?.candidate_shortlisted?.id], 'active');
                      }}
                    >
                      <StatisticsCard
                        bg="bg3"
                        yourStatText="Interviews Scheduled"
                        bigNo={formatAmount(statisticsCount?.interviews)}
                        sinceText="Active"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                     data-testid="OffersNav"
                     onClick={()=>{
                        handleCardClick([StatusEnumList?.candidate_hired?.id], 'active');
                      }}
                    >
                      <StatisticsCard
                        bg="bg4"
                        yourStatText="Offers"
                        bigNo={formatAmount(statisticsCount?.offers)}
                        sinceText="Active"
                        icon={DashboardIcon4}
                      />
                    </Item>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ marginTop: "10px" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "left", textAlign: "left" }}
                  className="dashboard-sml-cards"
                >
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                      data-testid="submittedToAmNav"
                      onClick={()=>{
                        handleCardClick([StatusEnumList?.submitted_to_am?.id],'active');
                      }}
                    >
                      <StatisticsCard
                        bg="bg1"
                        yourStatText="Submitted to AM"
                        bigNo={formatAmount(statisticsCount?.submittedToAM)}
                        sinceText="Active"
                        icon={DashboardIcon1}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                      data-testid="InterviewsAcceptanceNav"
                      onClick={()=>{
                        handleCardClick([StatusEnumList?.waiting_for_offer?.id],'active')
                      }}
                    >
                      <StatisticsCard
                        bg="bg2"
                        yourStatText="Interviews Acceptance"
                        bigNo={formatAmount(statisticsCount?.acceptedByClient)}
                        sinceText="Active"
                        icon={DashboardIcon2}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                      data-testid="interviewRejectionsNav"
                      onClick={()=>{
                        handleCardClick([StatusEnumList?.candidate_rejected_in_interview?.id],'archived');
                      }}
                    >
                      <StatisticsCard
                        bg="bg3"
                        yourStatText="Interview Rejections"
                        bigNo={formatAmount(statisticsCount?.interviewRejections)}
                        sinceText="Active"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                    data-testid="ContractsActiveNav"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.contract_active?.id], 'active');
                    }}
                  >
                      <StatisticsCard
                        bg="bg4"
                        yourStatText="Contracts Active"
                        bigNo={formatAmount(statisticsCount?.contractActive)}
                        sinceText="Active"
                        icon={DashboardIcon4}
                      />
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              {/* Row 1 after statistics  */}
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-around", paddingTop: "5px" }}
                  className="dashboard-sml-cards2"
                >
                  {/* Total Responses */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <TotalResponses data={graphicalData?.totalResponses} />
                    </Item>
                  </Grid>
                  {/* Highlights */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <Highlights data={graphicalData?.highlights} />
                    </Item>
                  </Grid>
                  {/* Top Submissions */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <TopSubmissions data={graphicalData?.topSubmissions} />
                    </Item>
                  </Grid>
                </Grid>
              </Box>

              {/* Row 2 after statistics  */}
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-around", paddingTop: "5px" }}
                >
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      
                      {graphicalData?.performance!==undefined && <PerformanceByScore data={graphicalData?.performance}/> }  
                    </Item>
                  </Grid>
                  {/* Satisfaction Trend */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <SatisfactionTrend  data={graphicalData?.satisfactionTrendScore}/>
                    </Item>
                  </Grid>
                  {/* Relevant Candidates */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <RelevantCandidates data={graphicalData?.relevantCandidates} />
                    </Item>
                  </Grid>
                </Grid>
              </Box>
              {/* Row 3 after statistics  */}
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  sx={{ justifyContent: "space-around", paddingTop: "5px" }}
                >
                  {/* Top Profiles */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <TopProfiles data={graphicalData?.topProfiles}
                       />
                    </Item>
                  </Grid>
                  {/* Score by Skills */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <ScoreBySkills data={graphicalData?.scoreBySkills} />
                    </Item>
                  </Grid>
                  {/* Activity Score  */}
                  <Grid item xs={12} md={4} sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <ActivityScrore data={graphicalData?.activityScores} />
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          
    </div>
  );
}
