import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Grid } from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { Payrate } from "../ui/Payrate";
import moment from "moment";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import Alert from '@mui/material/Alert';
import { downloadRecruiterEarningPdf } from "../../utils/getFileFromUrl";
import { makeStyles } from "@material-ui/core";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { Fullname } from "../Common/Fullname";
import { calculateSumWithPrecision } from "../../utils/numericUtils";

const useStyles = makeStyles({
  tableCell: {
    width:"11.11%",
    flex: 1, // Make the columns equal size
  },
 });

export const RecruiterEarningTable = (props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <TableContainer component={Paper} className="adminearning-table-compnent">
      <AppInfiniteScroll
          dataLength={props?.data?.length}
          next={props.fetchMoreData}
          hasMore={props.hasMoreData}
          isLoading={props.loading}
          loader={true}
          height={"auto"}
                  >
        <Table sx={{ fontSize: "1px" }} stickyHeader aria-label="sticky table" className="AppTableList">
          <TableHead className="AppTableListHeader">
            <TableRow>
              <TableCell align="left">Candidate Name</TableCell>
              <TableCell align="left">Client</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Hours Worked</TableCell>

              <TableCell align="left">Commission Approved</TableCell>
              <TableCell align="left">Commission</TableCell>
              <TableCell align="left">Payment Terms</TableCell>
              <TableCell align="left">Commission Due Date</TableCell>
              <TableCell>Statement</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props?.data?.map((row,index) => {
              return (
                <TableRow key={ row?.projectId ?? index} className={`AppTableList-table-row ${row?.isAdjustmentEntry ? "adjustment-row" : ""}`}>
                <TableCell className={classes.tableCell}><Fullname fullName={row?.candidateName}/></TableCell>
                <TableCell className={classes.tableCell}>{row?.projectClientName}</TableCell>
                <TableCell className={classes.tableCell}>
                    <span className="projectId">{row?.projectId} </span>
                    <span className="projectName">{row?.projectName} </span>
                  </TableCell>
                <TableCell className={classes.tableCell}>{row?.totalHours}</TableCell>
                
                <TableCell className={classes.tableCell}>{row?.isApproved ? "Yes" : "No"}</TableCell>
                <TableCell className={classes.tableCell}>
                  <Payrate fontSize="0.875rem" packageType={""} packageValue={calculateSumWithPrecision(row?.totalCommission || 0, row?.oneTimeCommission || 0)} currency={row?.currencyType}/>
                </TableCell>
                <TableCell className={classes.tableCell}>{row?.paymentTerm}</TableCell>
                <TableCell className={classes.tableCell}>
                <span className="commissionDueDateText">
                    {moment(row?.commissionDueDate).format(
                        "MMMM DD, YYYY"
                      )}
                      </span>
                </TableCell>

                  <TableCell  className={classes.tableCell} data-testid={"downloadEarningPDF"} onClick={()=>downloadRecruiterEarningPdf([{...row,recruiterGuid:getUserDetails()?.id}])}>
                  <SaveAltOutlinedIcon color="primary" className="pointer" />

                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </AppInfiniteScroll>
      </TableContainer>
      {props?.loading && (
            <p
              style={{
                marginTop: "50px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              Please wait...
            </p>
          )}
          {!props?.loading && props?.data?.length === 0 && (
            <Alert severity="info" style={{ marginTop: "5px" }}>
              No Records Found
            </Alert>
          )}
    </Grid>
  );
};
