import React, { useEffect } from 'react'

import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material'
import useApi from '../../hooks/useApi'
import { GetAllIndustries } from '../../Services/recruiterService'
import toTitles from '../../utils/toTitles'
import { InputLabel } from '@material-ui/core'
import { Asterisk } from './Asterisk'
import { ternary } from '../../utils/complexityUtils'

export const IndustryAutocomplete = (props) => {
    const placeholder = ternary(props?.placeholder, props?.placeholder, "Search Industries")
    const industrySelectionLimit = 5
    const [searchIndustry, setsearchIndustry] = React.useState("")

    const onIndustryInputChange = (e, value) => {
        setsearchIndustry(value)
    }
    let industries = props.industries //formik.values.industries
    const allIndustries = useApi(() => GetAllIndustries(searchIndustry));
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            allIndustries.request();
           
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchIndustry])

    let isRequired;
    if (props?.isRequired) {
      isRequired = true;
    } else if (props?.isRequired === undefined) {
      isRequired = true;
    } else {
      isRequired = false;
    }
    return (
        <>       
         <Autocomplete
            autoComplete="off"
            noOptionsText={allIndustries?.loading ? 'Loading...' : "No options"}
            required
            multiple
            freeSolo={props.readOnly}
            readOnly={props.readOnly}
            id="size-small-standard-multi3"
            getOptionDisabled={(option) => industries?.some(e => e.id === option.id) || industries?.length === 5 ? true : false}
            size="medium"
            onInputChange={onIndustryInputChange}
            onBlur={props.onBlur}
            data-testid="industries"
            onChange={props.onChange}
            options={allIndustries?.data?.entityList?.map(({ id: id, industryName: industry }) => ({
                id,
                industry,
            })) || []}
            value={industries}
            defaultValue={industries}
            getOptionLabel={(option) => option.industry}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={toTitles(option.industry) ?? index}
                        color={props?.readOnly?  'default' : 'primary'}
                        size="medium"
                        label={option.industry}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    autoComplete='off'
                    onKeyPress={(e) => {industries?.length === industrySelectionLimit? e.preventDefault() : console.log();}}
                
                    variant="standard"
                    name={"industries"}
                    label={props?.label ? props?.label : "Tell us about your Industry Preference"}
                    required={isRequired}
                    error={props?.isError ? true : false}
                    helperText={props?.helperText}
                    placeholder={!props?.readOnly && ternary(industries?.length < industrySelectionLimit,placeholder,'')} 
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: props?.readOnly,
                        endAdornment: (
                            <React.Fragment>
                                {allIndustries?.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}

                />
            )}
            
        />
        </>

    )
}
