import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { getTextNameEllipse } from "../../../utils/StringUtil";

interface StringWithTooltipProps {
  text: string;
  maxEllipseTextSize?: number;
}

const StringWithTooltip: React.FC<StringWithTooltipProps> = ({
  text,
  maxEllipseTextSize = 20,
}) => {
  return text?.length > maxEllipseTextSize ? (
    <Tooltip title={text}>
      <Typography className="textToolTip" noWrap>
        {getTextNameEllipse(text ?? "", maxEllipseTextSize)}
      </Typography>
    </Tooltip>
  ) : (
    <Typography className="textToolTip">
      {getTextNameEllipse(text ?? "", maxEllipseTextSize)}
    </Typography>
  );
};

export default StringWithTooltip;
