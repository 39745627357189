import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import { Searchbar } from "../ui/Searchbar";
import { useMediaQuery } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PendingSubmissionFilter from "./PendingSubmissionFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPendingSubmissionState,
  setPendingSubmissionListState,
} from "../../features/PendingSubmission/PendingSubmissionListStateSlice";
import ClearIcon from "@mui/icons-material/Clear";

interface PendingSubmissionTableToolbarProps {
  totalRecords: any;
  handleOnLocationChange: any;
  onContractTypeChange: any;
}

const PendingSubmissionTableToolbar: React.FC<
  PendingSubmissionTableToolbarProps
> = ({
  totalRecords,
  handleOnLocationChange,
  onContractTypeChange,
}) => {
  // Initialisation
  const dispatch = useDispatch();
  //acces the redux toolkit
  const PendingSubmissionListState = useSelector(
    (state: any) => state.pendingSubmission
  );

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "310px" : "320px";

  const [anchorFilter, setAnchorFilter]: any = useState(false);
  const filterRef = React.useRef(null);

  const handleFilterClick = (event: any) => {
    setAnchorFilter(anchorFilter ? null : event.currentTarget);
  };

  const handleKeyWordChange = (e: any) => {
    dispatch(
      setPendingSubmissionListState({
        ...PendingSubmissionListState,
        searchKeywords: e.target.value,
        pageIndex: 1,
      })
    );
  };


  const handleSortByChange = () => {
    let selectedOrder =
      PendingSubmissionListState.sortOrder === "Ascending"
        ? "Descending"
        : "Ascending";
    dispatch(
      setPendingSubmissionListState({
        ...PendingSubmissionListState,
        sortOrder: selectedOrder,
        pageIndex: 1,
      })
    );
  };

  // Check is any filter is apply
  const _hasAnyFilterApply = () => {
    return (
      (PendingSubmissionListState?.location &&
        PendingSubmissionListState?.location?.length > 0) ||
      (PendingSubmissionListState?.contractType &&
        PendingSubmissionListState.contractType?.length > 0) ||
      (PendingSubmissionListState?.searchKeywords &&
        PendingSubmissionListState?.searchKeywords !== "")
    );
  };

  // clear All
  const _handleClearAll = () => {
    dispatch(resetPendingSubmissionState());
    if (filterRef?.current) {
      filterRef?.current?.handleClearAll();
    }
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        lg={12}
        md={12}
        spacing={2}
        className="custom-just-contnt"
      >
        <Grid container spacing={0}>
          {/* Row 1 */}
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Grid
              container
              spacing={2}
              justifyContent={{ sm: "flex-start", md: "space-between" }}
            >
              <Grid item>
                <Typography className="listing-total-heading">
                  Pending Submissions({totalRecords})
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justifyContent={{ sm: "flex-start", md: "flex-end" }}
                >
                  <Grid item style={{ position: "relative" }} >
                    <Searchbar
                      classes="ClientListingSearchbarContainer"
                      isNewSeachBar={true}
                      width={searchBarWidth}
                      height="1.7rem"
                      placeHolderText={"Search"}
                      txtFontSize={"14px"}
                      marginBottom="16px"
                      value={PendingSubmissionListState?.searchKeywords}
                      onChange={handleKeyWordChange}
                      onEnter={handleKeyWordChange}
                      onIconClick={handleKeyWordChange}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      component="div"
                      size="large"
                      className="v2-base-btn cust-btn-bgClr toolbarActionBtn"
                      startIcon={<FilterAltOutlinedIcon />}
                      onClick={handleFilterClick}
                      data-testid="filter-button"
                    >
                      Filter
                    </Button>
                    <Button
                      data-testid="sortTable"
                      variant="text"
                      className="v2-base-btn cust-btn-bgClr toolbarActionBtn"
                      component="div"
                      size="large"
                      onClick={handleSortByChange}
                      sx={{
                        textTransform: "none",
                      }}
                      startIcon={
                        PendingSubmissionListState?.sortOrder === "Ascending" ? (
                          <KeyboardDoubleArrowDownOutlinedIcon />
                        ) : (
                          <KeyboardDoubleArrowUpIcon />
                        )
                      }
                    >
                      Sort by
                    </Button>
                    <Button
                      variant="text"
                      component="div"
                      size="large"
                      className={`toolbarActionBtn ${
                        _hasAnyFilterApply()
                          ? "v2-base-btn"
                          : "disable-custom-Btn-fontsizes"
                      } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
                      startIcon={<ClearIcon />}
                      onClick={_handleClearAll}
                      disabled={!_hasAnyFilterApply()}
                      data-testid="clear-button"
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <PendingSubmissionFilter
          ref={filterRef}
          anchorFilter={anchorFilter}
          onClose={handleFilterClick}
          onChange={handleOnLocationChange}
          onContractTypeChange={onContractTypeChange}
        />
      </Grid>
    </>
  );
};

export default PendingSubmissionTableToolbar;
