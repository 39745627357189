import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import * as Yup from "yup";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import SuccessImg from "./../../assets/images/check-circle.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ErrorMessage } from "../ui/ErrorMessage";
import { ThemeProvider } from "@mui/material/styles";
import staticPageTheme from "../../Shared/theme/staticPageTheme";
import { Asterisk } from "../ui/Asterisk";
import BannerText from "../Common/BannerText";
import { preferredCountries } from "../../data/phoneInput/preferredCountries";
import { CircularProgress } from "@mui/material";
import "./ContactUS.scss";
import { SendContactEmail } from "../../Services/idProviderService";
import { phoneNumberRegex } from "../../constants/regex";
import SuccessIcon from "../../assets/images/Approved.svg"
interface ContactUsProps {
  // Define your props here if needed
}

export interface ContactUsFormValues {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
}

const ContactUs: React.FC<ContactUsProps> = () => {
    
  const [error, setError] = React.useState({ hasError: false, errorMsg: "" });
  const [openDialog, setOpenDialog] = React.useState(false);

  const initialState: ContactUsFormValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const ContactUsSchema = Yup.object({
    name: Yup.string().required("Please enter your full name"),
    email: Yup.string().email().required("Please enter a valid email address"),
    phoneNumber: Yup.string()
      .required("Please enter your phone number")
      .matches(phoneNumberRegex, "Entered Phone number is not valid"),
    message: Yup.string().required("Please enter a message"),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: ContactUsSchema,
    onSubmit: async (values, actions) => {
      _sendContactUsEmail(values)
    },
  });

  const _sendContactUsEmail = (requestPayload:ContactUsFormValues) =>{
    SendContactEmail(requestPayload)
    .then((response) => {
      if (
        response?.data?.status === 200 &&
        response.data.message.appStatusCode === "IP_RSF"
      ) {
        setOpenDialog(true);
      } else {
        setError({
          hasError: true,
          errorMsg: "Unexpected error, try again",
        });
      }
    })
    .catch((error:any)=>{
      console.log(error)
    })
  }

  return (
    <div>
      <div>
        <ThemeProvider theme={staticPageTheme}>
          <Container
            maxWidth="xl"
            className="login-banner-text"
            sx={{
              marginTop: "16px",
            }}
          >
            <BannerText bannerText="How do we reach you?" />
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <Typography
                variant="h5"
                component="span"
                className="letter-spacing-normal lets-have-txt"
                gutterBottom
                textAlign={"center"}
                sx={{ paddingTop: "80px" }}
              >
                Let's Have A Chat
              </Typography>
            </div>

            <Container>
              <Grid container alignItems="center">
                <Grid item md={6} className={"contactus-grid"}>
                  <div>
                    <Typography className="form-text-font" color="inherit">
                      Your Name
                      <span>
                        <Asterisk />
                      </span>
                    </Typography>
                    <div style={{ paddingRight: "0px" }}>
                      <TextField
                        style={{ fontFamily: "roboto !important" }}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        required
                        name="name"
                        placeholder="Your Full Name"
                        type="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        inputProps={{
                          maxLength: 60,
                          "data-testid": "input-name",
                        }}
                        fullWidth
                        id="fullWidth"
                        sx={{
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      errorText={formik.touched.name && formik.errors.name}
                    />
                    <Typography color="inherit" className="form-text-font">
                      Your email
                      <span>
                        <Asterisk />
                      </span>
                    </Typography>
                    <div style={{ paddingLeft: "0px" }}>
                      <TextField
                        required
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        name="email"
                        type="email"
                        placeholder="Your email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        inputProps={{
                          maxLength: 50,
                          "data-testid": "input-email",
                        }}
                        fullWidth
                        id="fullWidth"
                        sx={{
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      errorText={formik.touched.email && formik.errors.email}
                    />
                    <Typography color="inherit" className="form-text-font">
                      Your Phone Number{" "}
                      <span>
                        <Asterisk />
                      </span>
                    </Typography>
                    <div style={{ paddingLeft: "0px" }}>
                      <div>
                        <PhoneInput
                          placeholder="Your Phone Number"
                          inputClass="form-controlContactUs"
                          containerClass="react-tel-inputContactUs"
                          inputProps={{
                            required: true,
                            "data-testid": "input-phone",
                          }}
                          inputStyle={{
                            borderColor:
                            formik?.touched?.phoneNumber &&
                            Boolean(formik?.errors?.phoneNumber) ? "red" : undefined,
                            width: "100%",
                          }}
                          onChange={(ev) => {
                            formik.setFieldValue("phoneNumber", ev);
                          }}
                          preferredCountries={preferredCountries}
                          country={"us"}
                          preserveOrder={["preferredCountries"]}
                          value={formik.values.phoneNumber}
                        />
                      </div>
                    </div>
                    <ErrorMessage
                      errorText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                    <Typography color="inherit" className="form-text-font">
                      Message
                      <span>
                        <Asterisk />
                      </span>
                    </Typography>

                    <div
                      style={{ paddingLeft: "0px" }}
                      className="text-font-family"
                    >
                      <TextField
                        required
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        name="message"
                        type="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        multiline
                        fullWidth
                        inputProps={{
                          maxLength: 250,
                          "data-testid": "input-message",
                        }}
                        rows={7}
                        placeholder="What do you want to discuss about?"
                        id="outlined-multiline-static"
                        sx={{
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <ErrorMessage
                      errorText={
                        formik.touched.message && formik.errors.message
                      }
                    />
                    <div style={{ marginTop: "30px", textAlign: "center" }}>
                      <Button
                        data-testid="btn-sendmessage"
                        variant="contained"
                        className="SendMessageButton text-font-family"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        disabled={formik.isSubmitting}
                        endIcon={
                          formik.isSubmitting ? (
                            <CircularProgress size={"16px"} color={"inherit"} />
                          ) : (
                            ""
                          )
                        }
                      >
                        Send the Message &nbsp;{">"}
                      </Button>
                    </div>
                    <ErrorMessage errorText={error.errorMsg} />
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </ThemeProvider>
      </div>

      <Dialog open={openDialog}>
        <DialogContent sx={{ bgcolor: "#fff" }}>
          <IconButton
            aria-label="close"
            data-testid="closeIconBtn"
            onClick={() => {
              setOpenDialog(false);
              formik.resetForm();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText
            component="div"
            id="success-dialog-description"
            textAlign="center"
          >
            {" "}
            <figure>
              <img src={SuccessIcon} className="PopupStatusIcon" alt="Success Icon" />
            </figure>
            <Typography
              component="div"
              className="PopupPrimaryTitle"
              fontWeight={600}
              my={2}
            >
              Details submitted <span className='successTextColor'> Successfully</span>
            </Typography>
            <Typography component="div" className="PopupSecondaryTitle" my={2}>
              Thank you for contacting us! You will hear back soon.
            </Typography>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ContactUs;
