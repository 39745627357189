import { Paging, SortType } from "../app/AppModels";

export interface ClientJobFilter extends Paging {
  searchKeywords?: string;
  showRemoteJobs : boolean;
  showFeaturedJobs : boolean
  showDisabledJobs : boolean;
  showPendingJobs : boolean;
  jobTypes : any[];
  domains : any[];
  locations?: string[];
  clientGuid?: string;
  recruiterGuid?: any;
  advanceSearch : {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate:  0,
    maximumPayRate:  0,
    currency: "",

  }
}
export const clientJobFilterDefault: ClientJobFilter = {
  isSorted: true,
  pageIndex: 1,
  pageSize: 10,
  sortColumn: "jobStartDate",
  sortOrder: SortType.Ascending,
  totalRecord: 0,
  domains : [],
  jobTypes : [],
  showRemoteJobs : false,
  showFeaturedJobs : false,
  showDisabledJobs : false,
  showPendingJobs: false,
  advanceSearch : {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate:  0,
    maximumPayRate:  0,
    currency: "",

  },
  clientGuid:"",
  recruiterGuid:""

};

export interface ClientJobStateFilter{
  locations?: any[];
  searchKeywords?: string;
  showRemoteJobs : boolean;
  showFeaturedJobs : boolean
  showDisabledJobs : boolean
  showPendingJobs : boolean;
  clientGuid?:string;
  recruiterGuid?: string;
  domains : any[]
  jobTypes : any[]
  advanceSearch : {
    jobTitle: [],
    location: [],
    client: [],
    minimumPayRate:  0,
    maximumPayRate:  0,
    currency: "",
  }
}
export interface JobListFilterType {
  AllJob:"filter";
  PendingFilter:"pendingFilter";
  SavedJobPageFilter:"savedJobPageFilter"

}
export interface ClientJobStateType {
  filter: ClientJobStateFilter;
  savedJobPageFilter: ClientJobStateFilter;
  filterType: "filter" | "pendingFilter" | "savedJobPageFilter"
  pendingFilter: ClientJobStateFilter
}
