import {
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {
  GetSubmissions
} from "../../Services/candidateService";
import getLoggedInUserRole from "../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../Shared/utils/getUserDetails";
import { UserAuth } from "../../models/interfaces";
import { getNevLink } from "../../utils/getFileFromUrl";
import ContractGrid from "./Grids/ContractGrid";
import AppBreadCrumb from "../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../models/AppModels";

interface ContractsProps {}


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Contracts: React.FunctionComponent<ContractsProps> = (props) => {

  const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to:getNevLink('/dashboard') },
    {
      isLeaf: true,
      sequence: 2,
      title: "Contracts",
      leafClasses: " v2LastLeft",
    },
  ];

  const [value, setValue] = React.useState(0);
  const [dataList, setDataList] = React.useState<{
    complete: any[];
    active: any[];
    completeCount: number;
    activeCount: number;
  }>({ active: [], complete: [], activeCount: 0, completeCount: 0 });
  const [dataCompleteList, setDataCompleteList] = React.useState<{
    complete: any[];
    completeCount: number;
  }>({ complete: [], completeCount: 0 });
  const userType = getLoggedInUserRole();
  const userDetails = getUserDetails();
  const [loading, setLoading] = React.useState(false);

  const activeContractStatus = ["cb083aae-7ed7-4417-b044-92f4a88b975c"];
  const completeContractStatus = ["qw083aae-7ed7-4407-b044-92f4a88b977c"];

  const [hasMoreDataActive, setHasMoreFlagActive] = React.useState<any>(null);
  const [hasMoreDataComplete, setHasMoreFlagComplete] =
    React.useState<any>(null);
  const [filterState, setfilterState] = React.useState({
    recruiterGuid: userType == UserAuth.Recruiter ? userDetails.id : "",
    jobId: "",
    searchKeywords: "",
    location: [],
    contractType: [],
    showPendingSubmissions: false,
    pageIndex: 1,
    pageSize: 20,
    sortColumn: "",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    recruiterFilter: [],
    statusIds: activeContractStatus,
  });

  const [completeFilterState, setcompleteFilterState] = React.useState({
    recruiterGuid: userType == UserAuth.Recruiter ? userDetails.id : "",
    jobId: "",
    searchKeywords: "",
    location: [],
    statusIds: completeContractStatus,
    contractType: [],
    showPendingSubmissions: false,
    pageIndex: 1,
    pageSize: 20,
    sortColumn: "",
    sortOrder: "Descending",
    isSorted: true,
    totalRecord: 0,
    recruiterFilter: [],
  });

  React.useEffect(() => {
    if(filterState.pageIndex === 1){
      getActiveDataList({ ...filterState });
    }    
  }, [filterState.pageIndex]);

  React.useEffect(() => {
    if(filterState.pageIndex > 1){
      getActiveDataList({ ...filterState });
    }    
  }, [filterState.pageIndex]);

  React.useEffect(() => {
    if(filterState.pageIndex === 1){
      getCompleteContractDataList({
        ...completeFilterState,
      });
    }
  }, [completeFilterState.pageIndex]);

  React.useEffect(() => {
    if(filterState.pageIndex > 1){
      getCompleteContractDataList({
        ...completeFilterState,
      });
    }
  }, [completeFilterState.pageIndex]);

  const getActiveDataList = (reqData: any) => {
    setLoading(true);
    GetSubmissions(reqData)
      .then((response: any) => {
        if (response?.data?.entityList?.length < filterState.pageSize) {
          setHasMoreFlagActive(false);
        } else {
          setHasMoreFlagActive(true);
        }

        if (filterState.pageIndex === 1) {
          setDataList({
            ...dataList,
            active: [...response.data.entityList],
            activeCount: response?.data?.totalRecords,
          });
        } else {
          setDataList({
            ...dataList,
            active: [...dataList.active, ...response.data.entityList],
            activeCount: response?.data?.totalRecords,
          });
        }
      })
      .catch((error) => {
        console.log(error, error, error.request?.status);
      })
      .finally(() => setLoading(false));
  };

  const getCompleteContractDataList = (reqData: any) => {
    setLoading(true);
    GetSubmissions(reqData)
      .then((response: any) => {
        if (response?.data?.entityList?.length < completeFilterState.pageSize) {
          setHasMoreFlagComplete(false);
        } else {
          setHasMoreFlagComplete(true);
        }

        if (completeFilterState.pageIndex === 1) {
          setDataCompleteList({
            ...dataCompleteList,
            complete: [...response.data.entityList],
            completeCount: response?.data?.totalRecords,
          });
        } else {
          setDataCompleteList({
            ...dataCompleteList,
            complete: [
              ...dataCompleteList.complete,
              ...response.data.entityList,
            ],
            completeCount: response?.data?.totalRecords,
          });
        }
      })
      .catch((error) => {
        console.log(error, error, error.request?.status);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const fetchNextData = (type: "Active" | "Complete") => {

    if (type === "Active")
      setfilterState({ ...filterState, pageIndex: filterState.pageIndex + 1 });
    else {
      setcompleteFilterState({
        ...completeFilterState,
        pageIndex: completeFilterState.pageIndex + 1,
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid  item xs={12} md={12}>
          <AppBreadCrumb
            classes="custom-appbreadcrumb"
            BreadCrumbItems={DefaultBreadCrumbs}
          />
        </Grid>        
        <Grid  item xs={12} md={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              data-testid="Active"
              className="custom-tab-txt contarct-tab custom-tabs-fontweight"
              sx={{ textTransform: "none", margin: "-15px" }}
              label={`Active Contracts (${dataList.activeCount})`}
              {...a11yProps(0)}
            />
            <Tab
              data-testid="Completed"
              className="custom-tab-txt custom-tabs-fontweight"
              sx={{ textTransform: "none" }}
              label={`Completed Contracts (${dataCompleteList.completeCount})`}
              {...a11yProps(1)}
            />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className="tabs-panels-header contractTab"
          >
            <ContractGrid
              isLoading={loading}
              data={dataList.active}
              hasMoreData={hasMoreDataActive}
              fetchNextData={() => fetchNextData("Active")}
            ></ContractGrid>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            sx={{ padding: 0 }}
            className="tabs-panels-header contractTab"
          >
            <ContractGrid
              isLoading={loading}
              data={dataCompleteList.complete}
              hasMoreData={hasMoreDataComplete}
              fetchNextData={() => fetchNextData("Complete")}
            ></ContractGrid>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default Contracts;
