import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

import { Alert, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  GetInterviewingCountByJob,
  GetSubmissionCountByJob
} from "../../../Services/candidateService";
import { GetJobById } from "../../../Services/jobService";
import formatName from "../../../Shared/utils/formatStrings";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { PermissionsEnums } from "../../../data/permission";
import {
  setSelectedIndex as setActiveSelectedIndex,
  setSelectedRow as setActiveSelectedRow,
} from "../../../features/recruiterSubmission/activeRecruiterSubmissionRowSlice";
import {
  setSelectedIndex as setRejectedSelectedIndex,
  setSelectedRow as setRejectedSelectedRow,
} from "../../../features/recruiterSubmission/rejectedRecruiterSubmissionRowSlice";
import {
  CandidateFinalStatuses,
  UserAuth
} from "../../../models/interfaces";
import AppInfiniteScroll from "../../Common/AppInfiniteScroll";
import { Fullname } from "../../Common/Fullname";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import PreviewFileDialog from "../../Common/PreviewFileDialog";
import { Payrate } from "../../ui/Payrate";
import CandidateInterviewCancelPopup from "../NewViewSubmission/Popups/CandidateInterviewCancelPopup";
import CandidateInterviewPopup from "../NewViewSubmission/Popups/CandidateInterviewPopup";
import ConfirmRescheduleInterviewPopup from "../NewViewSubmission/Popups/ConfirmRescheduleInterviewPopup";
import ConfirmShareAvailabilityPopup from "../NewViewSubmission/Popups/ConfirmShareAvailabilityPopup";
import ConversationHistoryPopup from "../NewViewSubmission/Popups/ConversationHistoryPopup";
import DocumentsPopup from "../NewViewSubmission/Popups/DocumentsPopup";
import HiredOfferAccepted from "../NewViewSubmission/Popups/HiredOfferAccepted";
import InterviewDetailsPopup from "../NewViewSubmission/Popups/InterviewDetailsPopup";
import InteviewFeedbackDuePopup from "../NewViewSubmission/Popups/InteviewFeedbackDuePopup";
import OfferDeclined from "../NewViewSubmission/Popups/OfferDeclined";
import OfferDetailsPopup from "../NewViewSubmission/Popups/OfferDetailsPopup";
import RTRBypass from "../NewViewSubmission/Popups/RTRBypass";
import RejectPopup from "../NewViewSubmission/Popups/Reject";
import SubmittedToClientPopup from "../NewViewSubmission/Popups/SubmittedToClientPopup";
import UpdateInterviewStatus from "../NewViewSubmission/Popups/UpdateInterviewStatus";
import "../NewViewSubmission/styles.scss";
import SubmissionActionButton from "./SubmissionActionButton";
import SubmissionTimeline from "./SubmissionTimeline";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";

interface SubmissionRowProps {
  candidates: any;
  type: string; // Define the type for type
  hasMoreData: boolean;
  fetchMoreData: () => void;
  loading: boolean;
  onRejectSuccess: () => void;
  onCommentAddedSuccess: () => void;
  onRTRBypassSuccess: () => void;
  resetPayloadHandlerForUpdate: (jobId:any, id:any) => void;
  onUpdateStatusSuccessClose: (message:string) => void;
  userRole:any;
}

const SubmissionRow: React.FC<SubmissionRowProps> = ({
  candidates,
  type,
  hasMoreData,
  fetchMoreData,
  loading,
  onRejectSuccess,
  onCommentAddedSuccess,
  onRTRBypassSuccess,
  resetPayloadHandlerForUpdate,
  onUpdateStatusSuccessClose,
  userRole
}) => {
  let userDetails = getUserDetails();
  const [popup, setPopup] = React.useState({
    recruiterInfo: false,
    documents: false,
    contact: false,
    reject: false,
    hiredofferaccepted: false,
    offerdeclined: false,
    conversation: false,
    offerDetails: false,
    interviewDetails: false,
    submittedToClient : false,
    isConfirmShareInterviewAvailbility:false,
    shareInterviewAvailbility:false,
    viewSharedAvailability:false,
    interviewReschedule:false,
    nextRoundOfInterview:false,
    isConfirmInterviewRescheduled:false,
    InterviewRescheduled:false,
    interviewFeedbackDue:false,
    cancelInterview:false,
    updateInterviewStatus:false

  });
  const activeRecruiterSubmissionRow = useSelector(
    (state: any) => state.activeRecruiterSubmissionRow
  );
  const rejectedRecruiterSubmissionRow = useSelector(
    (state: any) => state.rejectedRecruiterSubmissionRow
  );
  const recruiterSubmissionRow =
    type === "active"
      ? activeRecruiterSubmissionRow
      : rejectedRecruiterSubmissionRow;

  const [rtrPopup, setRTRPopup] = React.useState({
    isOpen: false,
    submissionId: "",
    recruiterName: "",
    recruiterEmail: "",
  });


  const dispatch = useDispatch();
  const [jobData, setJobData] = React.useState([]);
  const [submissionForPopup, setSubmissionForPopup] = React.useState({});
  const [isConfirmRTR, setIsConfirmRTR] = React.useState(false);
  const userType = getLoggedInUserRole();
  const [filePreview, setFilePreview] = React.useState(undefined);
  const [selectedRowInfo, setSelectedRowInfo] = React.useState<any>();

  const { filterType } = useSelector(
    (state: any) => state.submissionReducer
  );
  const { statusIds } = useSelector(
    (state: any) => state.submissionReducer[filterType]
  );

  const handleDispatch = (index: any, rowDetails: any) => {
    if (type === "active") {
      dispatch(setActiveSelectedRow(rowDetails));
      dispatch(setActiveSelectedIndex(index));
    } else {
      dispatch(setRejectedSelectedRow(rowDetails));
      dispatch(setRejectedSelectedIndex(index));
    }
  };
  React.useEffect(() => {
    if (
      recruiterSubmissionRow.rowIndex != null &&
      recruiterSubmissionRow.selectedRow !== ""
    ) {
      if (candidates && candidates.length > 0) {
        expandRowAndGetJobDetails(
          recruiterSubmissionRow.rowIndex,
          {jobId:candidates[recruiterSubmissionRow.rowIndex]?.jobId}
        );
      }
    }
  }, [candidates]);

  React.useEffect(()=>{
    handleDispatch(null, null);
    setSelectedRowInfo(null);
  },[statusIds])

  const _handleUpdateStatusPopup = (action: 'submittedToClient' | 'shareInterviewAvailbility' | string, message:string | "")=>{
    setPopup(
      { ...popup, 
        submittedToClient: false, 
        shareInterviewAvailbility:false, 
        viewSharedAvailability:false,
        interviewReschedule:false,
        nextRoundOfInterview:false,
        InterviewRescheduled:false,
        interviewFeedbackDue:false,
        cancelInterview:false,
        updateInterviewStatus:false,
        hiredofferaccepted:false,
        offerdeclined:false
      });
    onUpdateStatusSuccessClose(message);
    setJobData([]);
  }

  const _onConfirmReSchedule = ()=>{
    setPopup(
      { ...popup, 
        isConfirmInterviewRescheduled: false,
        InterviewRescheduled:true
      });
  }

  const expandRowAndGetJobDetails = (index: any, rowDetails: any) => {
    //expand row at current index
    if (!jobData.find((x: any) => x?.id === rowDetails?.jobId)) {

      GetJobById(rowDetails?.jobId)
        ?.then((res:any) => {
          if (
            res?.data?.status === 200 &&
            res?.data?.message?.appStatusCode === "RC_RSF"
          ) {
            handleDispatch(index, res?.entity);

            return Promise.all([
              GetInterviewingCountByJob(
                rowDetails?.jobId,
                ternary(
                  userType === UserAuth.Recruiter,
                  valueOrDefault(userDetails.id, ""),
                  ""
                )
              ),
              GetSubmissionCountByJob(
                rowDetails?.jobId,
                ternary(
                  userType === UserAuth.Recruiter,
                  valueOrDefault(userDetails.id, ""),
                  ""
                )
              ),
            ])
              .then(([interviewingCountRes, resumeCountRes]) => {
                const interviewingCount =
                  interviewingCountRes?.data?.totalRecords || 0;
                const resumeCount = resumeCountRes?.data?.totalRecords || 0;

                const updatedJobData = {
                  ...res?.data?.entity,
                  interviewingCount,
                  resumeCount,
                };
                setJobData((current) => [...current, updatedJobData]);
              })
              .catch((error) => {
                console.error(
                  "Error fetching interviewingCount or resumeCount:",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching job details:", error);
        });
      }
    
  };
  const getJobDetails = (jobId: string) => {
    return jobData.find((x: any) => x?.id === jobId);
  };

  const { features, modules } = useSelector(
    (state: any) => state.userPermissions
  );

  const submissionRow = (row: any, index: any) => {
    let showUpdateBtn =
      (row?.finalStatus === CandidateFinalStatuses.CandidateSubmitted ||
        row?.finalStatus === CandidateFinalStatuses.SubmittedToAM ||
        row?.finalStatus === CandidateFinalStatuses.CandidateRTROverDue ||
        row?.finalStatus === CandidateFinalStatuses.PendingRecruiterReview ||
        row?.finalStatus === CandidateFinalStatuses.RecruiterAssigned) &&
      (features.includes(PermissionsEnums.Submissions_Update) ||
        modules.includes(PermissionsEnums.Submissions_Update));
    let currentjobDetails = getJobDetails(row?.jobId);
    const expandRowHandler = (index: any, row: any) => {
      if (recruiterSubmissionRow.rowIndex === index) {
        handleDispatch(null, null);
        setSelectedRowInfo(null);
      } else {
        setSelectedRowInfo(row)
        handleDispatch(index, row);
        expandRowAndGetJobDetails(index, row);
      }
    };
    return (
      <React.Fragment>
        <TableRow
          sx={{
            "& td":
              recruiterSubmissionRow.rowIndex === index ? { border: 0 } : {},
          }}
        >
          <TableCell
            sx={{
              whiteSpace: "nowrap",
              px: 1,
              width: 100,
              fontSize: "15px",
              paddingLeft: "7px !important",
            }}
          >
            <Link
              target={"_blank"}
              to={`/jobdetail/${row.jobId}/submission${type}`}
              variant="body2"
              style={{ color: "#4540DB", textDecoration: "none" }}
            >
              {row.jobId}
            </Link>
          </TableCell>

          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ px: 1, width: 90 }}
            data-testid="job-title-cell"
          >
            {
              <Typography className="submission-tblData">
                {row.jobTitle}
              </Typography>
            }
          </TableCell>
          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ whiteSpace: "nowrap", px: 1, width: 100 }}
          >
            <Typography className="submission-tblData">
              {" "}
              <Fullname fullName={row.firstName + " " + row.lastName} />
            </Typography>
          </TableCell>
          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ px: 1, width: 100 }}
          >
            <Typography component="span" className="submission-tblData">
              {row.jobClientName}
            </Typography>
          </TableCell>
          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ px: 1, width: 100 }}
          >
            <Typography
              color="textPrimary"
              component="span"
              className="submission-tblData"
            >
              {`${row.jobCity + ", " + row.jobStateName}`}
            </Typography>
          </TableCell>
          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ px: 1, whiteSpace: "nowrap", width: 100 }}
          >
            <Typography
              variant="caption"
              gutterBottom
              className="submission-tblData"
            >
              {row?.contractType != null ? row?.contractType : "N/A"}
            </Typography>
          </TableCell>
          <TableCell
            onClick={() => {
              expandRowHandler(index, row);
            }}
            sx={{ px: 1, width: 100 }}
            data-testid={"payrate-cell"}
          >
            <Payrate
              fontSize="14"
              highLightCurrency={false}
              packageType={row?.payRateType}
              packageValue={row?.payRate}
              currency={row?.packageCurrency}
            />
          </TableCell>
          {(userType === UserAuth.Admin || userType === UserAuth.SuperAdmin  || userType === UserAuth.Client || userType === UserAuth.ClientTeamMember) && (
            <TableCell
              onClick={() => {
                expandRowHandler(index, row);
              }}
              sx={{ whiteSpace: "nowrap", px: 1, width: 100, fontSize: "15px" }}
            >
              <Typography className="submission-tblData">
                <Fullname
                  fullName={formatName(
                    row.recruiterFirstName,
                    row.recruiterLastName
                  )}
                />
              </Typography>
            </TableCell>
          )}

          <TableCell
            sx={{
              whiteSpace: "nowrap",
              px: 1,
              width: "11.4%",
              padding: "0px",
            }}
          >
            {showUpdateBtn ? (
              <Button
              data-testid="submission"
              className="submission-action-btn"  
              style={{ width: "80%" }}
              
              onClick={() => {
                  expandRowHandler("update", "update");
                  resetPayloadHandlerForUpdate(row?.jobId, row?.id);
                }}
                variant="outlined"
                color="primary"
              >
                Update
              </Button>
            ) : (
              <ModuleComponent
                moduleId={PermissionsEnums.Submissions_Documents}
              >
                <Button
                  className="submission-action-btn"
                  variant="outlined"
                  color="primary"
                  style={{ width: "80%" }}
                  onClick={() => {
                    setSubmissionForPopup(row);
                    setPopup({ ...popup, documents: true });
                  }}
                >
                  Documents
                </Button>
              </ModuleComponent>
            )}
          </TableCell>
        </TableRow>

        {recruiterSubmissionRow.rowIndex === index && (
          <TableRow
            sx={{
              padding: 0,
            }}
          >
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
              <Box mt={1} sx={{ marginBottom: "1.125rem" }}>
                <SubmissionActionButton
                  setRTRPopup={setRTRPopup}
                  index={index}
                  jobData={jobData}
                  row={row}
                  currentjobDetails={currentjobDetails}
                  type={type}
                  showUpdateBtn={showUpdateBtn}
                  popup={popup}
                  setPopup={setPopup}
                  setSubmissionForPopup={setSubmissionForPopup}
                />
                <Grid mt={2} mb={"25px"} container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SubmissionTimeline data={row} />
                  </Grid>
                </Grid>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <TableContainer
        component={Paper}
        className="custom-table-compnent-new responsive-infinite-scroll-cls"
      >
        <AppInfiniteScroll
          dataLength={candidates.length}
          next={fetchMoreData}
          hasMore={hasMoreData}
          isLoading={loading}
          loader={true}
          classNames={"SubmissionTable"}
        >
          <Table aria-label="simple table" className="custom-sub-ht">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ px: 1 }}>
                  Job ID
                </TableCell>
                <TableCell sx={{ px: 1 }}>Job Title</TableCell>
                <TableCell sx={{ px: 1 }}>Name</TableCell>
                <TableCell sx={{ px: 1 }}>Client</TableCell>
                <TableCell sx={{ px: 1 }}>Location</TableCell>
                <TableCell sx={{ px: 1 }}>Type</TableCell>
                <TableCell sx={{ px: 1 }}>Pay Rate</TableCell>
                {(userType === UserAuth.Admin ||
                  userType === UserAuth.SuperAdmin || userType === UserAuth.Client || userType === UserAuth.ClientTeamMember ) && (
                  <TableCell sx={{ px: 1 }}>Recruiter</TableCell>
                )}
                <TableCell sx={{ px: 1 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates &&
                candidates.map((row, index) => submissionRow(row, index))}
            </TableBody>
          </Table>
        </AppInfiniteScroll>
      </TableContainer>

      {popup.documents && (
        <DocumentsPopup
          submission={submissionForPopup}
          openPopup={popup?.documents}
          handleDocumentsPopupClose={() =>
            setPopup({ ...popup, documents: false })
          }
          setFilePreview={setFilePreview}
        />
      )}

      {popup.interviewDetails && (
        <InterviewDetailsPopup
          openPopup={popup.interviewDetails}
          handleInterviewDetailsClose={() =>
            setPopup({ ...popup, interviewDetails: false })
          }
          data={selectedRowInfo}
        />
      )}
      {popup.conversation && (
        <ConversationHistoryPopup
          comments={popup.comments}
          openPopup={popup.conversation}
          submission={submissionForPopup}
          handleDocumentsPopupClose={() =>
            setPopup({ ...popup, conversation: false })
          }
          onCommentAddedSuccess={()=>{            
            onCommentAddedSuccess()
            setPopup({ ...popup, conversation: false })
          }}
        />
      )}

      {popup.offerDetails && (
        <OfferDetailsPopup
          openPopup={popup.offerDetails}
          handleofferdetailsClose={() =>
            setPopup({ ...popup, offerDetails: false })
          }
          data={selectedRowInfo}
          type={type}
        />
      )}
      {popup.reject && (
        <RejectPopup
          openPopup={popup.reject}
          handleDocumentsPopupClose={() =>
            setPopup({ ...popup, reject: false })
          }
          onRejectSuccess={onRejectSuccess}
          selectedRowDetails={selectedRowInfo}
          userRole={userRole}
        />
      )}

      { popup.submittedToClient && (
        <SubmittedToClientPopup
          openPopup={popup.submittedToClient}
          handleDocumentsPopupClose={() =>
            setPopup({ ...popup, submittedToClient: false })
          }
          onSuccess={_handleUpdateStatusPopup}
          selectedRowDetails={selectedRowInfo}
        />
      )}

  {popup?.isConfirmShareInterviewAvailbility &&

    <ConfirmShareAvailabilityPopup
      isOpen={popup?.isConfirmShareInterviewAvailbility}
      handleOnClose={()=>{
        setPopup({ ...popup, isConfirmShareInterviewAvailbility: false,  })
      }}
      handleOnShareAvailability={()=>{
        setPopup({ ...popup, shareInterviewAvailbility: true, isConfirmShareInterviewAvailbility: false })
      }}
    />
  }
    { popup.shareInterviewAvailbility && (
            <CandidateInterviewPopup
              isOpen={popup.shareInterviewAvailbility}
              handleOnClose={() =>
                setPopup({ ...popup, shareInterviewAvailbility: false })
              }
              selectedRowDetails={selectedRowInfo}
              type="shareInterviewAvailbility"
              onSuccess={_handleUpdateStatusPopup}
            />
          )}

      { popup.viewSharedAvailability && (
            <CandidateInterviewPopup
              isOpen={popup.viewSharedAvailability}
              handleOnClose={() =>
                setPopup({ ...popup, viewSharedAvailability: false })
              }
              selectedRowDetails={selectedRowInfo}
              type="viewSharedAvailability"
              onSuccess={_handleUpdateStatusPopup}
            />
          )}

          { popup.nextRoundOfInterview && (
            <CandidateInterviewPopup
              isOpen={popup.nextRoundOfInterview}
              handleOnClose={() =>
                setPopup({ ...popup, nextRoundOfInterview: false })
              }
              selectedRowDetails={selectedRowInfo}
              type="shareInterviewAvailbility"
              source={"nextRoundOfInterview"}
              onSuccess={_handleUpdateStatusPopup}
            />
          )}

{ popup.isConfirmInterviewRescheduled && (
            <ConfirmRescheduleInterviewPopup
              isOpen={popup.isConfirmInterviewRescheduled}
              handleOnClose={() =>
                setPopup({ ...popup, isConfirmInterviewRescheduled: false })
              }
              onConfirmReSchedule={_onConfirmReSchedule}
            />
          )}

      { popup.InterviewRescheduled && (
            <CandidateInterviewPopup
              isOpen={popup.InterviewRescheduled}
              handleOnClose={() =>
                setPopup({ ...popup, InterviewRescheduled: false })
              }
              selectedRowDetails={selectedRowInfo}
              type="shareInterviewAvailbility"
              source={"InterviewRescheduled"}
              onSuccess={_handleUpdateStatusPopup}
            />
          )}

      { popup.cancelInterview && (
              <CandidateInterviewCancelPopup
                openPopup={popup.cancelInterview}
                handleDocumentsPopupClose={() =>
                  setPopup({ ...popup, cancelInterview: false })
                }
                onSuccess={_handleUpdateStatusPopup}
                selectedRowDetails={selectedRowInfo}
              />
            )
        }

        { popup.interviewFeedbackDue && (
          <InteviewFeedbackDuePopup
            openPopup={popup.interviewFeedbackDue}
            handlePopupClose={() =>
              setPopup({ ...popup, interviewFeedbackDue: false })
            }
            onSuccess={_handleUpdateStatusPopup}
            selectedRowDetails={selectedRowInfo}
          />
        )}

      { popup.updateInterviewStatus && (
          <UpdateInterviewStatus
            openPopup={popup.updateInterviewStatus}
            handlePopupClose={() =>
              setPopup({ ...popup, updateInterviewStatus: false })
            }
            onSuccess={_handleUpdateStatusPopup}
            selectedRowDetails={selectedRowInfo}
          />
        )}

      { popup.hiredofferaccepted && (
          <HiredOfferAccepted
            openPopup={popup.hiredofferaccepted}
            handleDocumentsPopupClose={() =>
              setPopup({ ...popup, hiredofferaccepted: false })
            }
            selectedRowDetails={selectedRowInfo}
            onSuccess={_handleUpdateStatusPopup}
          />
        )}

      {popup.offerdeclined && (
        <OfferDeclined
          openPopup={popup.offerdeclined}
          handleDocumentsPopupClose={() =>
            setPopup({ ...popup, offerdeclined: false })
          }
          selectedRowDetails={selectedRowInfo}
          userRole={userRole}
          onSuccess={_handleUpdateStatusPopup}
        />
      )}

        {rtrPopup && (
          <RTRBypass
            rtrPopup={rtrPopup}
            setIsConfirmRTR={setIsConfirmRTR}
            handleClose={(val) => {
              setRTRPopup({ ...rtrPopup, isOpen: val });
              if (isConfirmRTR) {
                onRTRBypassSuccess();
              }
            }}
          />
        )}
        {loading && (
          <p
            style={{
              marginTop: "50px",
              marginBottom: "4px",
              textAlign: "center",
            }}
          >
            Please wait...
          </p>
        )}
        {!loading && candidates?.length === 0 && (
          <TableContainer component={Paper} className="custom-table-compnent">
            <Alert severity="info">No records found.</Alert>
          </TableContainer>
        )}
        {filePreview !== undefined && (
          <PreviewFileDialog
            open={filePreview !== undefined}
            handleOpenClose={() => setFilePreview(undefined)}
            fileName={(filePreview as any)?.fileName}
            fileURL={(filePreview as any)?.resourceUrl}
            fileType={(filePreview as any)?.fileType}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default SubmissionRow;
