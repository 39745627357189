import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./PendingSubmissionTable.css";
import Alert from "@mui/material/Alert";
import { Payrate } from "../ui/Payrate";
import { jobLocation } from "../../utils/jobUtils";
import AppInfiniteScroll from "../Common/AppInfiniteScroll";
import "./PendingSubmission.scss";
import { Fullname } from "../Common/Fullname";
interface PendingSubmissionTableProps {
  dataList: any;
  fetchNextData: any;
  hasMoreData: any;
  loading: any;
}

const PendingSubmissionTable: React.FC<PendingSubmissionTableProps> = ({
  dataList,
  fetchNextData,
  hasMoreData,
  loading,
}) => {
  let navigate = useNavigate();

  return (
    <>
      <Grid container item xs={12} sm={12} md={12}>
        <TableContainer component={Paper} className="custom-table-compnent" data-testid="scrollableDiv">
          <AppInfiniteScroll
            dataLength={dataList.length}
            next={fetchNextData}
            hasMore={true}
            isLoading={loading}
            loader={true}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ml: 2 }}>Job ID</TableCell>
                  <TableCell>Job Title</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ minWidth: "115px" }}>
                    Client/ Location
                  </TableCell>
                  <TableCell>Contract</TableCell>
                  <TableCell style={{ minWidth: "70px" }}
                  >Pay Rate</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((row: any, index: any) => (
                  <TableRow
                    key={ row?.jobId ?? index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell sx={{ px: 1 }}>
                      <span style={{ color: "#4540DB" }}>{row.jobId}</span>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 150 }}>{row.jobTitle}</TableCell>
                    <TableCell className="tbl-namepadding"> <Fullname fullName={row?.firstName +" "+ row?.lastName}/></TableCell>
                    <TableCell sx={{ px: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{ margin: "0 !important" }}
                          className="cust-font-jobDetails"
                        >
                          {row.jobClientName}
                        </span>{" "}
                        <span>
                          {" "}
                          {jobLocation(
                            row?.remoteJob,
                            row.jobCity,
                            row.jobStateName
                          )}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ px: 1, width: 100 }}>{row.contractType}</TableCell>
                    <TableCell sx={{ px: 1 }}>
                      <Payrate
                        fontSize="14px"
                        packageType={row.payRateType}
                        packageValue={row.payRate}
                        currency={row.packageCurrency}
                      />
                    </TableCell>
                    <TableCell sx={{ px: 1 }}>{row.finalStatus}</TableCell>
                    <TableCell sx={{ px: 1 }}>
                      <Box>
                        <Button
                          variant="contained"
                          data-testid="primary"
                          color="primary"
                          className="jobs-button jobdetail custom-Submit-btn-font"
                          style={{ minWidth: "102px" }}
                          onClick={() => {
                            navigate(
                              `/submitresume/${row.jobId}/pendingsubmission/?id=${row.id}`
                            );
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AppInfiniteScroll>
          {loading && (
            <p
              style={{
                textAlign: "center",
              }}
            >
              Please wait...
            </p>
          )}
          {!loading && dataList.length === 0 && (
            <Alert severity="info" style={{ marginTop: "5px" }}>
              No Records Found
            </Alert>
          )}
        </TableContainer>
      </Grid>
    </>
  );
};

export default PendingSubmissionTable;
