import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useRef, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./JobPage.scss";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { UpdateJobAsFeatured, UpdateJobAsNotActive } from "../../../Services/jobService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import { PermissionsEnums } from "../../../data/permission";
import {
  handleAdvanceSearchKeyword,
  setActiveJobPage,
  setDomainOnSignin,
  useGetJobsMutation,
  useGetSavedJobsMutation,
} from "../../../features/Jobs/clientJobSlice";
import { SortType } from "../../../models/app/AppModels";
import { PageName } from "../../../models/app/Page/PageModel";
import {
  ClientJobFilter,
  clientJobFilterDefault,
} from "../../../models/client/ClienJobModels";
import { UserAuth } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import AppInfiniteScroll from "../../Common/AppInfiniteScroll";
import AppSort from "../../Common/AppSort";
import { ModuleComponent } from "../../Common/Permission/ModuleComponent";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import ContactInformationPopup from "../../ViewSubmissions/NewViewSubmission/Popups/ContactInformationPopup";
import { GotoTop } from "../../ui/GotoTop";
import { JobInactivePopup } from "./JobInactivePopup";
import { JobRow } from "./JobRow";
import { GetJobAlertById } from "../../../Services/recruiterJobAlertService";

interface JobListingTableProps {
  setTotalRecords: any;
  pageNameType?: PageName.ALLJOBPAGE | PageName.SAVEDJOBPAGE 
}

  const JobListingTable: React.FC<JobListingTableProps> = ({
    setTotalRecords,
    pageNameType

  }) => {
  let { page } = useParams();
  const [searchParams] = useSearchParams();
  const queryAlertId = searchParams.get("jobAlertId") ?? "";

  function _getActiveJobPage() {
    if (page === "pending") return "pendingFilter";
    else if (page === "recruiter") return "savedJobPageFilter";
    return "filter";
  }

  const dispatch = useDispatch();  
  dispatch(setActiveJobPage(_getActiveJobPage()));
  
  const { filterType } = useSelector(
    (state: AppState) => state.clientJobReducer
  );  
  const isMounted = useRef(false);
  let userRole = getLoggedInUserRole();

  let { id } = useParams();    
  let userId = id ? id : JSON.parse(localStorage.getItem("user") as any)?.userId;
  const { recruiterData } = useSelector((state: any) => state.recruiter);

  const {
    locations,
    searchKeywords,
    domains,
    showRemoteJobs,
    showFeaturedJobs,
    jobTypes,
    showDisabledJobs,
    advanceSearch,
    showPendingJobs,
    clientGuid
  } = useSelector((state: AppState) => state.clientJobReducer[filterType]);
  const [sort, setSort] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [displayConfirmPopup, setDisplayConfirmPopup] = useState(false);
  const [onConfirmCallback, setOnConfirmCallback] = useState<() => () => void>(()=>()=>{});
  const [snackBarPopup, setSnackBarPopup] = useState<any>({
    isSuccess: false,
    isError: false,
    message: "",
  });
;

  const [jobService, jobResult] = useGetJobsMutation();
  const [savedJobService, savedJobResult] = useGetSavedJobsMutation();
  const [paging, setPaging] = useState<ClientJobFilter>({
    ...clientJobFilterDefault,
    
    locations:locations,
    searchKeywords: searchKeywords,
    domains:domains,
    showRemoteJobs:showRemoteJobs,
    showFeaturedJobs:showFeaturedJobs,
    jobTypes:jobTypes,
    showDisabledJobs:showDisabledJobs,
    advanceSearch:advanceSearch,
    
    showPendingJobs: Boolean(page === "pending"),
    ...(userRole === UserAuth.Recruiter) && {recruiterStatus: "Approved"},
    ...(pageNameType === PageName.SAVEDJOBPAGE) && {recruiterGuid : userId},
    ...(pageNameType === PageName.SAVEDJOBPAGE) && {jobIds : [] }
  });
  const [clientJob, setClientJob] = useState<any[]>([]);
  const [displayContactPopup, setDisplayContactPopup] = React.useState(false);
  const [contactInformationData, setcontactInformationData] = useState({
    accountManager: null,
    recruiter: null,
    candidate: null,
    employerDetails : null,
    locationGroup: null,
    isClientjob: false
  });

  const [selectedNotActiveJob, setSelectedNotActiveJob] = useState({
    jobId: null,
    jobTitle: null,
    createdBy: userId,
    isDisabled: null,
    isFeatured: null,
    type:null,
    recruiterSavedJobsCount:0
});
const [actionType, setActionType ] = useState<string | null>(null);
const [resposeMessage, setResponseMessage] = useState(null);

React.useEffect(() => {
  if(queryAlertId !==""){
    GetJobAlert(queryAlertId)
  }
}, [queryAlertId]);
  
  React.useEffect(() => {
    setPaging({...paging,pageIndex: 1, showPendingJobs:page === "pending" ? true : false})
  }, [page]);

  React.useEffect(() => {
    if(pageNameType === PageName.ALLJOBPAGE)
    {
      jobService(paging);      
    }
    else if(pageNameType === PageName.SAVEDJOBPAGE)
    {      
      savedJobService(paging)
    }
    
  }, [paging]);

  React.useLayoutEffect(() => {
    if (isMounted.current) {
      setPaging({
        ...paging,
        pageIndex: 1,
        searchKeywords: searchKeywords,
        domains: domains,
        showRemoteJobs: showRemoteJobs,
        showFeaturedJobs: showFeaturedJobs,
        jobTypes: jobTypes,
        showDisabledJobs: showDisabledJobs,
        advanceSearch: advanceSearch,
        showPendingJobs: showPendingJobs,
        clientGuid : clientGuid,
        recruiterGuid:userId
      });
    } else {
      isMounted.current = true;
    }
  }, [
    locations,
    searchKeywords,
    domains,
    showRemoteJobs,
    showFeaturedJobs,
    jobTypes,
    showDisabledJobs,
    advanceSearch,
    showPendingJobs,
  ]);
  
  React.useEffect(() => {
    if (pageNameType === PageName.ALLJOBPAGE) {
      if (paging.pageIndex === 1) {
        setClientJob([...(jobResult.data?.entityList ?? [])]);
      } else {
        setClientJob([...clientJob, ...(jobResult.data?.entityList ?? [])]);
      }
    } else {
      if (paging.pageIndex === 1) {
        setClientJob([...(savedJobResult.data?.entityList ?? [])]);
      } else {
        setClientJob([
          ...clientJob,
          ...(savedJobResult.data?.entityList ?? []),
        ]);
      }

    }
  }, [jobResult.data?.entityList, savedJobResult.data?.entityList]);

  React.useEffect(() => {
    if (pageNameType === PageName.ALLJOBPAGE) {
      if (jobResult.data?.totalRecords !== undefined) {
        setTotalRecords(jobResult.data?.totalRecords);
      }
    } else {
      if (savedJobResult.data?.totalRecords !== undefined) {
        setTotalRecords(savedJobResult.data?.totalRecords);
      }
    }
  }, [jobResult.data?.totalRecords, savedJobResult.data?.totalRecords]);

  React.useEffect(() => { 
    if(pageNameType === PageName.ALLJOBPAGE){
      setHasMore(
        jobResult.data?.totalRecords &&
          jobResult.data?.totalRecords > clientJob.length
          ? true
          : false
      );
    } else{
      setHasMore(
        savedJobResult.data?.totalRecords &&
        savedJobResult.data?.totalRecords > clientJob.length
          ? true
          : false
      );
    }

  }, [clientJob]);

  const GetJobAlert = (alertId:string) =>{
    GetJobAlertById(alertId).then(res => {
      if(res?.data?.status == 200 && res?.data?.message?.appStatusCode == "RC_RSF"){

        const advanceSearch =
        {
          jobTitle:res?.data?.entity?.jobTitle || [],
          location:res?.data?.entity?.location || [],
          client:res?.data?.entity?.client || [],
          minimumPayRate:res?.data?.entity?.minimumPayRate ?? 0 ,
          maximumPayRate:res?.data?.entity?.maximumPayRate ?? 0 ,
          currency:res?.data?.entity?.currency || ''
        }
          dispatch(setDomainOnSignin([ ...res?.data?.entity?.domains ]))
          dispatch(handleAdvanceSearchKeyword(advanceSearch));       
      }      
    })
  }
  
  const fetchMore = () => {
    setPaging({
      ...paging,
      pageIndex: paging.pageIndex + 1,
    });
  };

  const handleUpdateFeatureJob = (featureJobRequest:any,isNotActiveMode?:boolean,notActiveReqPayloadData?:any) =>{
    UpdateJobAsFeatured(featureJobRequest)
      .then((response) => {
        if(isNotActiveMode){
          updateJobAsNotActive(notActiveReqPayloadData);
        }else{
          setResponseMessage(response?.data?.message?.appStatusDescription)
        }
      })
      .catch((error) => {
        console.log(error);
      
      })
  }

  const _handleUpdateJobAsNotActive = (notActiveJobPayload:any) =>{
    UpdateJobAsNotActive(notActiveJobPayload)
    .then((response) => {
      setResponseMessage(response?.data?.message?.appStatusDescription)
      setDisplayConfirmPopup(false);      
    })
    .catch((error) => {
      console.log(error);
    }).finally(()=>{
      // does nothing, not sure if intentional
    })
  }

  const updateJobAsNotActive = (notActiveJobPayload:any) =>{
    _handleUpdateJobAsNotActive(notActiveJobPayload)    
  }

  const checkedSelectedJobRow = (selectedJobId:any,selectedJobTitle:any,isFeaturedJob:any,isNotActive:any,checkedType:any,recruiterSavedJobsCount:any) =>{
    setSelectedNotActiveJob({
      ...selectedNotActiveJob, 
      jobId:selectedJobId, 
      jobTitle : selectedJobTitle,
      isFeatured: isFeaturedJob,
      isDisabled : isNotActive,
      type:checkedType,
      recruiterSavedJobsCount:recruiterSavedJobsCount
    })

    let notActiveReqPayloadData = {
      jobId: selectedJobId,
      jobTitle:selectedJobTitle,
      createdBy: userId,
      isDisabled: isNotActive,
    } 

    let featureJobRequest = {
      jobId: selectedJobId,
      createdBy: userId,
      isFeatured: isFeaturedJob,
    };

    if(checkedType === 'feature'){
      setActionType('feature');
      UpdateJobAsFeatured(featureJobRequest)
      .then((response) => {
        setResponseMessage(response?.data?.message?.appStatusDescription);
      })
      .catch((error) => {
        console.log(error);
      
      })
    }

    if(checkedType === 'notActive')
    {
      if(!isNotActive){
        setActionType('notActive');
        _handleUpdateJobAsNotActive(notActiveReqPayloadData)
      }else{
        setDisplayConfirmPopup(true);
      }
      
    }
    
  }

  const handleConfirmYes = () =>{
    setDisplayConfirmPopup(false);
    onConfirmCallback();
    let featureJobRequest = {
      jobId: selectedNotActiveJob.jobId,
      createdBy: userId,
      isFeatured: selectedNotActiveJob.isFeatured,
    };

    let notActiveReqPayloadData = {
      jobId: selectedNotActiveJob.jobId,
      jobTitle:selectedNotActiveJob.jobTitle,
      createdBy: userId,
      isDisabled: selectedNotActiveJob.isDisabled,
    } 
        if(selectedNotActiveJob.isFeatured){
          if(selectedNotActiveJob.isDisabled)
          {

            setActionType('Yes');
            // when selecte job has already mark as feartured then first remove from feature job then mark as not active 
            handleUpdateFeatureJob({...featureJobRequest,isFeatured:!selectedNotActiveJob.isFeatured}, true, notActiveReqPayloadData) 
            
          }else{
            setActionType('Yes');
            updateJobAsNotActive(notActiveReqPayloadData);
          }
        }else{
          setActionType('Yes');
          updateJobAsNotActive(notActiveReqPayloadData);          
        }
      
 
  }

  const handleSortClicked = (sortColumnName: string) => {
    setSort(!sort);
    setPaging({
      ...paging,
      sortColumn: sortColumnName,
      isSorted: true,
      sortOrder:
        paging.sortOrder === SortType.Ascending
          ? SortType.Descending
          : SortType.Ascending,
      pageIndex: 1,
    });
  };

  const _handleOnSuccessMoveToPending = ()=>{
    
    if(paging.pageIndex <= 1){
      if(pageNameType === PageName.ALLJOBPAGE)
      {
        jobService(paging);
      }
      else if(pageNameType === PageName.SAVEDJOBPAGE)
      {
        savedJobService(paging)
      }
    }else{
      setPaging({
        ...paging,
        pageIndex: 1,
      });
    }

    setSnackBarPopup({
      ...snackBarPopup,
      isSuccess: true,
      isError: false,
      message: "Job moved to pending state successfully.",
    })
  }

  const _handleSnackbarClose = () =>{
    setSnackBarPopup({
      ...snackBarPopup,
      isSuccess: false,
      isError: false,
      message: "",
    })
    
  }

  function showJobIDHeader() {
    return (
      page == "all" || 
      (
        page == "pending" && 
        !((userRole == UserAuth.Client) || (userRole == UserAuth.ClientTeamMember))
      ) ||
      pageNameType == PageName.SAVEDJOBPAGE
    );
  }

  function renderJobIDHeader() {
    return (
      <>
        {userRole !== UserAuth.NotLoggedIn ?
          <>
            {showJobIDHeader() && (
              <TableCell
                data-testid="name-sort"
                className="minWidthROw"
              >
                <Grid container>
                  <Grid className="custom-Client-fontsizes pointer">
                    Job ID
                  </Grid>
                </Grid>
              </TableCell>
            )}
            {" "}
          </> 
          : ""
        }
      </>
    );
  }

  function renderJobTypeHeader() {
    return (
      <TableCell sx={{ px: 1 }} className="minWidthROw">
        Type
      </TableCell>
    );
  }

  function renderJobTitleHeader() {
    return (
      <TableCell
        data-testid="name-sort"
        className="minWidthROw"
        onClick={() => handleSortClicked("jobTitle")}
      >
        <Grid container>
          <Grid className="custom-Client-fontsizes pointer">
            Job Title
          </Grid>
          <Grid>
            <AppSort
              sortType={
                sort ? SortType.Ascending : SortType.Descending
              }
              disable={paging.sortColumn !== "jobTitle"}
            ></AppSort>
          </Grid>
        </Grid>
      </TableCell>
    );
  }

  function renderJobTypeAndTitleHeaders() {
    if (page == "pending") {
      return (
        <>
          {renderJobTitleHeader()}
          {renderJobTypeHeader()}
        </>
      );
    }

    return (
      <>
        {renderJobTypeHeader()}
        {renderJobTitleHeader()}
      </>
    );
  }

  function showPartnerContactsHeader() {
    return userRole === UserAuth.Admin ||
      userRole == UserAuth.SuperAdmin ||
      (userRole === UserAuth.Client && page === "all") ||
      (userRole === UserAuth.ClientTeamMember && page === "all");
  }

  function renderParterContactsHeader() {
    if (!showPartnerContactsHeader()) return null;
    
    return (
      <ModuleComponent moduleId={PermissionsEnums.Jobs_Partner_Contacts}>
        <TableCell width={100} style={{ minWidth: 150 }}>
          Partner Contacts
        </TableCell>
      </ModuleComponent>
    );
  }

  function renderBillRateHeader() {
    return (
      <TableCell
        data-testid="billrate-sort"
        className=""
        onClick={() => handleSortClicked("billRate")}
      >
        <Grid container className="custom-updown-arrow">
          <Grid className="custom-Client-fontsizes pointer">
            Bill Rate
          </Grid>
          <Grid>
            <AppSort
              sortType={
                sort ? SortType.Ascending : SortType.Descending
              }
              disable={paging.sortColumn !== "billRate"}
            ></AppSort>
          </Grid>
        </Grid>
      </TableCell>
    );
  }

  function renderPayRateHeader() {
    return (
      <TableCell
        data-testid="rate-sort"
        className=""
        onClick={() => handleSortClicked("packagevalue")}
      >
        <Grid container className="custom-updown-arrow">
          <Grid className="custom-Client-fontsizes pointer">
            Pay Rate
          </Grid>
          <Grid>
            <AppSort
              sortType={
                sort ? SortType.Ascending : SortType.Descending
              }
              disable={paging.sortColumn !== "packagevalue"}
            ></AppSort>
          </Grid>
        </Grid>
      </TableCell>
    );
  }

  function renderRateHeader() {
    if (userRole === UserAuth.Client || userRole === UserAuth.ClientTeamMember) {
      return renderBillRateHeader();
    }
    else if (userRole === UserAuth.NotLoggedIn) return <>{""}</>;
    else {
      return renderPayRateHeader();
    }
  }

  return (
    <div>
      <Grid
        container
        item
        xs={12}
        md={12}
        spacing={2}
        className="Client-listingTable-wrapper"
        style={{ marginTop: "10px" }}
      >
        <TableContainer component={Paper} className="Client-table">
          <AppInfiniteScroll
            dataLength={clientJob?.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={true}
            isLoading={jobResult.isLoading}
          >
            <Table
              aria-label="simple table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 4px",
                backgroundColor: "#fafaff",
              }}
            >
              <TableHead className="custom-Client-fontsizes">
                <TableRow>
                  {" "}
                  
                  {renderJobIDHeader()}

                  {renderJobTypeAndTitleHeaders()}
                  
                  <TableCell width={230} style={{ minWidth: 200 }}>
                    Description
                  </TableCell>

                  {userRole !== UserAuth.NotLoggedIn && (
                    <TableCell
                      data-testid="date-sort"
                      className="minWidthROw"
                    >
                      <Grid container>
                        <Grid className="custom-Client-fontsizes pointer">
                          Client/Location
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}

                  {userRole === UserAuth.NotLoggedIn && (
                    <TableCell className="minWidthROw rec-Location-width">
                      Location
                    </TableCell>
                  )}
                  
                  {renderParterContactsHeader()}

                  {renderRateHeader()}
                  {userRole === UserAuth.NotLoggedIn ? "" : <TableCell style={{whiteSpace:"nowrap"}} className="minWidthROw1">Posting Date</TableCell>}
                  <TableCell className="minWidthROw1">Action</TableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {clientJob.map((row: any, index: number) => {
                    return (
                      <JobRow
                        key={row?.id ?? index}
                        resposeMessage={resposeMessage}
                        setResponseMessage={setResponseMessage}
                        selectedNotActiveJob={selectedNotActiveJob}
                        checkedSelectedJobRow={(
                          event: any,
                          selectedJobTitle: string,
                          isFeaturedJob: boolean,
                          isNotActive: boolean,
                          checkedtype: any,
                          recruiterSavedJobsCount: number
                        ) =>
                          checkedSelectedJobRow(
                            event,
                            selectedJobTitle,
                            isFeaturedJob,
                            isNotActive,
                            checkedtype,
                            recruiterSavedJobsCount
                          )
                        }
                        actionType={actionType}
                        parent={"jobs"}
                        row={row}
                        index={index}
                        page={page}
                        pageNameType={pageNameType}
                        recruiterData={recruiterData}
                        userId={userId}
                        recruiterID={id}
                        userRole={userRole}
                        setDisplayConfirmPopup={setDisplayConfirmPopup}
                        setCallback={setOnConfirmCallback}
                        setDisplayContactPopup={setDisplayContactPopup}
                        setcontactInformationData={setcontactInformationData}
                        handleOnSuccessMoveToPending={
                          _handleOnSuccessMoveToPending
                        }
                      />
                    );
                  })}
                </>
              </TableBody>
            </Table>

            {pageNameType === PageName.ALLJOBPAGE ? (
              <>
                {jobResult.isLoading && (
                  <p
                    style={{
                      marginTop: "50px",
                      marginBottom: "4px",
                      textAlign: "center",
                    }}
                  >
                    Please wait...
                  </p>
                )}
                {!jobResult.isLoading && jobResult.data?.totalRecords === 0 && (
                  <Alert severity="info" style={{ marginTop: "5px" }}>
                    No Records Found
                  </Alert>
                )}
              </>
            ) : (
              <>
                {savedJobResult.isLoading && (
                  <p
                    style={{
                      marginTop: "50px",
                      marginBottom: "4px",
                      textAlign: "center",
                    }}
                  >
                    Please wait...
                  </p>
                )}
                {!savedJobResult.isLoading &&
                  savedJobResult.data?.totalRecords === 0 && (
                    <Alert severity="info" style={{ marginTop: "5px" }}>
                      No Records Found
                    </Alert>
                  )}
              </>
            )}
          </AppInfiniteScroll>
        </TableContainer>
      </Grid>
      {snackBarPopup.isSuccess && (
        <SnackbarPopup
          message={snackBarPopup.message}
          open={snackBarPopup.isSuccess}
          onClose={_handleSnackbarClose}
        />
      )}

      {displayConfirmPopup && (
        <JobInactivePopup
          openPopup={displayConfirmPopup}
          handleConfirm={handleConfirmYes}
          handleClose={() => setDisplayConfirmPopup(false)}
          selectedNotActiveJob={selectedNotActiveJob}
        />
      )}

      {displayContactPopup && (
        <ContactInformationPopup
          isClientJob={contactInformationData?.isClientjob}
          contactData={contactInformationData}
          openPopup={displayContactPopup}
          onlyPartnerInfo={true}
          recruitersGroupInfo={contactInformationData?.locationGroup}
          handleDocumentsPopupClose={() => setDisplayContactPopup(false)}
        />
      )}

      <div
        style={{
          position: "fixed",

          right: "6%",

          zIndex: "50",
          top: "81%",
        }}
      >
        <GotoTop />
      </div>
    </div>
  );
};

export default JobListingTable;

