import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import Line from "./../../assets/images/Line.svg";
import GoogleButton from "./GoogleButton";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import Grid from "@mui/material/Grid";
import SignupPopup, { SignUpOptionType } from "./Popups/SignupPopup";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DividerLines from "./../../assets/images/divider.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const SignupForm: React.FC<any> = ({ formType }) => {
  
  const [email, setEmail] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(true);
  const [selectedOption, setSelectedOption] = React.useState<SignUpOptionType>("");

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box className="SignFormMainWrapper login-banner-right">
        <Box style={{flexGrow: 1,padding:"20px 30px 0px", marginBottom: 0}} className="login-forms">
          {/* This box is non-functional and only included for design reasons */}
          <Box
            className={` ${!showConfirmation ? "" : "d-none"}`}
            data-testid="box-signin"
          >
            <Typography
              variant="h2"
              color="inherit"
              component="span"
              className="custom-wlcm-txt"
              sx={{
                fontSize: {
                  xs: "32px !important",
                  sm: "32px !important",
                  md: "32px !important",
                },
                letterSpacing: "normal !important",
              }}
            >
              Welcome to{" "}
              <span style={{ fontWeight: 700, color: "rgba(53, 53, 53, 1)" }}>
                JobRialto
              </span>
            </Typography>
            <Typography
              variant="h2"
              color="inherit"
              component="div"
              className="custom-signin-txt"
            >
              Sign In
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                flexDirection: "row",
                "& .MuiTextField-root": { m: 1 },
              }}
            >
              <FormControl
                variant="standard"
                required
                fullWidth
                margin="normal"
              >
                <InputLabel className="custom-email-txt" htmlFor="email">Your email</InputLabel>
                <Input
                  name="username"
                  type="email"
                  className="custom-pwd-txt custom-input-bckgrnd-color"
                  inputProps={{ "data-testid": "input-signin-email" }}
                />
              </FormControl>
              <FormControl
                required
                variant="standard"
                fullWidth
                margin="normal"
              >
                <InputLabel className="custom-email-txt" htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  name="password"
                  className="custom-pwd-txt custom-input-bckgrnd-color"
                  autoComplete="no"
                  inputProps={{ "data-testid": "input-signin-password" }}
                  endAdornment={
                    <span
                      className="showHideIcon"
                      data-testid="icon-showpassword"
                    >
                      <InputAdornment position="end">
                        <IconButton size="small">
                          <VisibilityOff style={{color:"#353535"}}/>
                        </IconButton>
                      </InputAdornment>
                    </span>
                  }
                />
              </FormControl>
              <br />

              <Box>
                <Link
                  style={{ textDecoration: "none" }}
                  data-testid="link-forgotpassword"
                >
                  <span
                  className="custom-forgotpwd-txt"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      fontSize: "16px",
                      color: "rgba(53, 53, 53, 1)",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </span>
                </Link>
              </Box>
              <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  className="custom-signin-btn signup-button"
                  fullWidth
                  data-testid="btn-signin"
                >
                  Sign In
                </Button>
              </Grid>
              <Box sx={{ textAlign: "center" }}>
                <img src={DividerLines} alt="" width="100%" />
              </Box>
              <Typography component="span" className="signup-with">
                Sign in using{"   "}
                <Button variant="text" className="linkedin-btn">
                  <LinkedInIcon className="linkedin-icon" />
                </Button>
                <GoogleButton
                  handleAlreadyExist={()=>{}}
                  redirectUrl={""}
                />
              </Typography>
            </Box>
          </Box>

          <Box className={`${showConfirmation ? "" : "d-none"}`}>
            <Typography
              variant="h5"
              color="inherit"
              component="span"
              className="custom-signupthnks-txt"
              textAlign="center"
              sx={{ letterSpacing: "3px", color: "#4F4F4F" }}
            >
              <div
                className="custom-signin-thnks"
                style={{ textAlign: "center",paddingTop:"44px",letterSpacing:"0px" }}
              >
                Thanks for signing up!
              </div>
            </Typography>
            <br></br>
            <Typography component="span">
              <div
                className="custom-thnxx-txt"
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "rgba(29, 52, 62, 1)",
                  fontWeight: "400",
                  lineHeight: "19.2px",
                }}
              >
                Go to your mailbox and click on the verification link that was
                sent to{" "}
                <span style={{ color: "rgba(69, 64, 219, 1)", fontWeight:"700" }}>{email}</span>{" "}
                within the next 15 minutes.
              </div>
            </Typography>

            <Typography
              component="span"
              sx={{
                marginTop: "15px",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              gutterBottom
            >
              <br></br>
              <Grid style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  className="custom-email-signup-txt"
                  onClick={() => {
                    setShowConfirmation(false);
                    setIsOpen(true);
                  }}
                >
                  Not my email address
                </Button>
              </Grid>
            </Typography>
            <Box sx={{ textAlign: "center", padding: "15px 0", paddingTop:"73px", paddingBottom:"72px" }}>
              <img src={Line} alt="" width= "100%" />
            </Box>
            <Typography
              variant="caption"
              display="block"
              className="custom-signingup-txt"
              style={{
                color: "rgba(29, 52, 62, 1)",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18.75px",
              }}
            >
              By signing up you confirm that you have read and accepted our{" "}
              <Link>
                <a
                  href="/TermsAndConditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Terms of Service
                </a>
              </Link>
              {", "}
              <Link>
                <a
                  href="/PrivacyPolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Privacy Policy{" "}
                </a>
              </Link>
              {", "}
              and default{" "}
              <Link
                onClick={() => {
                  navigate("/notificationSettings", { state: { email: email } });
                }}
                style={{ cursor: "pointer" }}
              >
                Notification Settings.
              </Link>
              {"."}
            </Typography>
          </Box>
        </Box>
      
        <Box
          sx={{
            display: showConfirmation ? "none" : "flex",
            justifyContent: "center",
            backgroundColor: "rgba(240, 243, 255, 1)",
            paddingTop: "15px",
            fontSize: "15px",
          }}
          className="custom-lnk-signup1 signInFormInnerWrapper-bottomSection"
        >
          Don't have an account?{" "}
          <Link
            data-testid="Signup"
            style={{ textDecoration: "none", cursor: "pointer" }}
            className="custom-lnk-signup"
            onClick={(e) => navigate("/signup")}
          >
            Sign up
          </Link>
        </Box>
      </Box>

      {isOpen && (
        <SignupPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handlePopupClose={()=>{
            navigate("/login");
          }}
          setEmail={setEmail}
          selectedOption={selectedOption}
          setShowConfirmation={(confirmValue: boolean, selectedOption: SignUpOptionType) => {
            setShowConfirmation(confirmValue);
            setSelectedOption(selectedOption);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default SignupForm;
