import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCandidateByFilter } from "../../../Services/candidateService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import {
  resetCandidateListState,
  setCandidateListState,
} from "../../../features/Candidate/CandidateListStateSlice";
import { SortType } from "../../../models/app/AppModels";
import { UserAuth } from "../../../models/interfaces";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import { GotoTop } from "../../ui/GotoTop";
import "./CandidatesList.scss";
import CandidateListTableToobar from "./Components/CandidateListTableToobar";
import CandidateTableList from "./Components/CandidateTableList";

const CandidatesList: React.FC = () => {
  // Initialisation
  const dispatch = useDispatch();

  const userRole = getLoggedInUserRole();
  const user = getUserDetails();
  //acces the redux toolkit
  const CandidateListState = useSelector(
    (state: any) => state.CandidateListState
  );

  // Component state
  const [candidatesDataList, setCandidatesDataList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMoreData, setHasMoreFlag] = useState<boolean>(false);
  const [isDispatchState, setIsDispatchedState] = useState<boolean>(false);
  const [totalRecruiterCount, setTotalRecruiterCount] = useState<number>(0);
  

  useEffect(() => {
    if (CandidateListState?.pageIndex > 1 ) {
      _GetCandidatesList();
    }
  }, [CandidateListState?.pageIndex]);

  useEffect(() => {
    if (isDispatchState && CandidateListState?.pageIndex === 1) {
      _GetCandidatesList();
    }
  }, [
    isDispatchState,
  ]);

  // Reset or clear All Global states
  const _handleClearGlobalAll = () => {
    dispatch(resetCandidateListState());
    setIsDispatchedState(true);
  };

  const _handleSearchKeywords = (searchText: string) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: 1,
        searchKeywords: searchText,
      })
    );
    setIsDispatchedState(true);
  };

  // Handle sort change
  const _handleSortChange = (isAcendingSort: boolean, sortColumn: string) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: 1,
        sortOrder: isAcendingSort ? SortType.Ascending : SortType.Descending,
        sortColumn: sortColumn,
      })
    );
    setIsDispatchedState(true);
  };

  // Handle Fitler multi changes
  // Location filter
  const _handleOnLocationChange = (selectedLocations: string[]) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: 1,
        location: selectedLocations,
      })
    );
    setIsDispatchedState(true);
  };

  // Industries Filter
  const _handleOnExperinceChange = (selectedExperinces: string[]) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        experience: selectedExperinces,
        pageIndex: 1,
      })
    );
    setIsDispatchedState(true);
  };

  // Canndidate filter
  const _handleOnCandidateChange = (selectedCandidates: string[]) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: 1,
        candidateGuids: selectedCandidates,
      })
    );
    setIsDispatchedState(true);
  };

  //Recruiter filter
  const _handleOnRecruiterChange = (selectedRecruiters: string[]) => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: 1,
        recruiterGuids: selectedRecruiters,
      })
    );
    setIsDispatchedState(true);
  };
  const _fetchMore = () => {
    dispatch(
      setCandidateListState({
        ...CandidateListState,
        pageIndex: CandidateListState.pageIndex + 1,
      })
    );
  };

  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description)?.city;

    return city;
  };

  const _GetCandidatesList = () => {
    setLoading(true);
    let selectedLocations: string[] =
      CandidateListState?.location?.map((item: any) => {
        return getCity(item.description);
      }) || [];

    let selectedExperinces: string[] =
      CandidateListState?.experience?.map((item: any) =>
        item?.experienceValue?.toString()
      ) || [];

    let selectedCandidates: string[] =
      CandidateListState?.candidateGuids?.map((item: any) => item?.id) || [];

    let selectedRecruiter: string[] =
      CandidateListState?.recruiterGuids?.map((item: any) => item.id) || [];

    if (CandidateListState?.pageIndex === 1) {
      setCandidatesDataList([]);
      setTotalRecruiterCount(0);
    }
    let requestPayload = {
      ...CandidateListState,
      location: selectedLocations,
      experience: selectedExperinces,
      candidateGuids: selectedCandidates,
      recruiterGuids: selectedRecruiter,
    };
    GetCandidateByFilter({
      ...requestPayload,
      recruiterId: userRole === UserAuth.Recruiter ? user?.id : "",
    })
      ?.then((response) => {
        if (response?.data?.entityList?.length < CandidateListState?.pageSize) {
          setHasMoreFlag(false);
        } else {
          setHasMoreFlag(true);
        }
        setTotalRecruiterCount(response?.data?.totalRecords || 0);

        if (CandidateListState.pageIndex === 1) {
          setCandidatesDataList([...response?.data?.entityList]);
        } else {
          setCandidatesDataList([
            ...candidatesDataList,
            ...response?.data?.entityList,
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setIsDispatchedState(false);
      });
  };

  return (
    <Grid container spacing={2}>
      {/* Row 1 */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CandidateListTableToobar
              totalRecruiterCount={totalRecruiterCount}
              handleClearGlobalAll={_handleClearGlobalAll}
              CandidateListState={CandidateListState}
              handleSearchbar={_handleSearchKeywords}
              handleOnLocationChange={_handleOnLocationChange}
              handleOnExperineceChange={_handleOnExperinceChange}
              handleOnCandidateChange={_handleOnCandidateChange}
              handleOnRecruiterChange={_handleOnRecruiterChange}
              selectedCandidateLocation={[]}
              selectedCandidateExperinces={[]}
              selectedCandiadtes={[]}
              selectedRecruiters={[]}
            />
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12}>
          <CandidateTableList
            candidatesData={candidatesDataList}
            fetchMore={_fetchMore}
            hasMore={hasMoreData}
            isLoading={loading}
            handleSortChange={_handleSortChange}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              position: "fixed",

              right: "6%",

              zIndex: "50",
              top: "81%",
            }}
          >
            <GotoTop />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidatesList;
