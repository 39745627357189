import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  useMediaQuery
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { GetAllCurrencyList } from "../../../Services/masterCurrencyService";
import { AddRecuiterJobAlert } from "../../../Services/recruiterJobAlertService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import {
  ClearClientFilter,
  handleAdvanceSearchKeyword,
  handleCheckboxFilter,
  handleDomainChange,
  handleJobSearchKeyword,
  resetAdvanceFilter,
} from "../../../features/Jobs/clientJobSlice";
import { PageName } from "../../../models/app/Page/PageModel";
import { clientJobFilterDefault } from "../../../models/client/ClienJobModels";
import { UserAuth } from "../../../models/interfaces";
import { AppState } from "../../../store/AppState";
import formatAmount from "../../../utils/formatAmount";
import getAdvanceSearchMessage from "../../../utils/getAdvanceSearchMessage";
import { Searchbar } from "../../ui/Searchbar";
import CollapsibleAdvanceSearch from "../CollapsibleAdvanceSearch";
import "./JobPage.scss";
import { valueOrDefault } from "../../../utils/complexityUtils";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import { CustomAlert } from "../../ui/CustomAlert";
import { getIsClientModuleEnabled } from "../../../utils/AppConfig";

interface JobSearchbarProps {
  totalRecords: number | undefined;
  pageNameType?: PageName.ALLJOBPAGE | PageName.SAVEDJOBPAGE
}

interface AlertStatusType {
  isSuccess:boolean;
  isError:boolean;
  message:string
}

const JobSearchbar: React.FC<JobSearchbarProps> = ({
  totalRecords,
  pageNameType
}) => {
  const userRole = getLoggedInUserRole();
  const [masterCurrercyList, setMasterCurrencyList] = React.useState([]);

  const dispatch = useDispatch<any>();

  const [isAdvanceSearchActive, setIsAdvanceSearchActive] =
    React.useState(false);
  const handleAdvanceSearchToggle = () => {
    setIsAdvanceSearchActive(!isAdvanceSearchActive);
  };
  let { page } = useParams();

  const { recruiterData } = useSelector((state: any) => state.recruiter);
  const location = useLocation();

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const searchBarWidth = isSmallScreen ? '310px' : '400px';

  let jobTypeList = ["Full time", "Contract"];
  const isClientModuleEnabled = getIsClientModuleEnabled();
  if(isClientModuleEnabled){
    jobTypeList.push("Part Time","Internship")
  }

  const { filterType } = useSelector(
    (state: AppState) => state.clientJobReducer
  );

  const {
    searchKeywords,
    domains,
    showRemoteJobs,
    showFeaturedJobs,
    showDisabledJobs,
    jobTypes,
    advanceSearch,
  } = useSelector((state: AppState) => state.clientJobReducer[filterType]);

  const [searchMessage, setSearchMessage] = React.useState("");

  const [showMessage,setShowMessage] = React.useState("");
  const [showSuccessMsg, setShowSuccessMsg] = React.useState(false);
  const [alertStatus, setAlertStatus] = React.useState<AlertStatusType>({
    isSuccess:false,
    isError:false,
    message:""
  });
  const [defaultSetJobAlert,setDefaultSetJobAlert]= React.useState(false);
  const [jobAlertPayload, setJobAlertPayload] = React.useState<any>({
    "id": "",
    "recruiterGuid": "",
    "jobTitle": [],
    "location": [],
    "client": [],
    "minimumPayRate": 0,
    "maximumPayRate": 0,
    "currency": "",
    "searchKeywords": "",
    "domains": [],
    "jobAlertType": "Daily",  // by default set to Daily
    "jobAlertShareType": "Email"  // by default set to Email
  })

  const onAlertClose =()=>{
    setShowSuccessMsg(false);
  }

  const onSearchKeywordChange = (e:any) => {
    dispatch(handleJobSearchKeyword(e.target.value));
  };

  const clearAllFilter = () => {
    setDefaultSetJobAlert(false)
    dispatch(ClearClientFilter());
    dispatch(handleJobSearchKeyword(""));
  };
  const haveAnyFilter = () => {
    return (
      (searchKeywords && searchKeywords !== "") ||
      domains.length !== 0 ||
      showRemoteJobs ||
      showFeaturedJobs ||
      showDisabledJobs ||
      jobTypes.length !== 0 ||
      JSON.stringify(advanceSearch) !=
        JSON.stringify(clientJobFilterDefault.advanceSearch)
    );
  };
  const handleAdvancedSearchClick = (advanceFilterData: any) => {
    setDefaultSetJobAlert(false)
    let updatedAdvanceFilterData = {
      jobTitle: advanceFilterData?.jobTitle || [],
      location: advanceFilterData?.location || [],
      client: advanceFilterData?.client || [],
      minimumPayRate:
        advanceFilterData?.minimumPayRate !== ""
          ? advanceFilterData?.minimumPayRate
          : 0,
      maximumPayRate:
        advanceFilterData?.maximumPayRate !== ""
          ? advanceFilterData?.maximumPayRate
          : 0,
      currency: advanceFilterData?.currency,
    };
    dispatch(handleAdvanceSearchKeyword(updatedAdvanceFilterData));
    handleAdvanceSearchToggle();
  };

  const getAllMasterCurrencyList = () => {
    if (getLoggedInUserRole() != UserAuth.NotLoggedIn) {
      GetAllCurrencyList()
        ?.then((response) => {
          let currencyList = response.data.entityList.map(
            (item: any, index: any) => item
          );
          setMasterCurrencyList(currencyList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  
  const handleSetJobAlert = async (event:any) => {
    setShowSuccessMsg(false);
    
    if (!event.currentTarget.checked) {
      setShowSuccessMsg(false);
      setDefaultSetJobAlert(false);
      return;
    }

    if(advanceSearch?.jobTitle?.length === 0){
      setAlertStatus({
        ...alertStatus,
        isError:true,
        message:"Please select the job title for setting the Job alerts."
      });
      return;
    }
    
    let JobAlertRequestPayload = {
      ...jobAlertPayload,
      recruiterGuid: recruiterData?.entity?.id ?? "",
      recruiterEmail: recruiterData?.entity?.email,
      recruiterFirstName: recruiterData?.entity?.firstName,
      recruiterLastName: recruiterData?.entity?.lastName,
      jobTitle: advanceSearch?.jobTitle ?? [],
      location: advanceSearch?.location ?? [],
      client: advanceSearch?.client ?? [],
      minimumPayRate: advanceSearch?.minimumPayRate ?? 0,
      maximumPayRate: advanceSearch?.maximumPayRate ?? 0,
      currency: advanceSearch?.currency  ?? "",
      searchKeywords: searchKeywords ?? "",
      domains: domains ?? [],
      jobAlertType: "Daily",
      jobAlertShareType: "Email",
      viewJobsReturnUrl:window.location.origin + `${location?.pathname}/`
    }
    
    setJobAlertPayload({...jobAlertPayload,...JobAlertRequestPayload });

    callAddJobAlert(JobAlertRequestPayload);
  }

  function callAddJobAlert(jobAlertRequestPayload: any) {
    AddRecuiterJobAlert(jobAlertRequestPayload)
    .then(res => {
      if (res.data.status == 200 && res.data.message.appStatusCode == "RC_JOB_ALERT_ADD") {
        setShowMessage("Job alerts set successfully.");
        setShowSuccessMsg(true);
        setDefaultSetJobAlert(true);
      }else if(res.data.status == 409 && res.data.message.appStatusCode == "RC_JOB_ALERT_EXST"){
        setShowSuccessMsg(true);
        setShowMessage(res?.data?.message?.appStatusDescription);
        setDefaultSetJobAlert(true);
      }else{
        setShowSuccessMsg(true);
        setShowMessage(valueOrDefault(res?.data?.message?.appStatusDescription, "Something went wrong"));
      }
    });
  }

  
  React.useEffect(() => {
    getSearchMessage();
  }, [advanceSearch]);
  
  const getSearchMessage = () => {
    let message = getAdvanceSearchMessage(
      advanceSearch?.jobTitle || [],
      advanceSearch.location,
      advanceSearch.client,
      advanceSearch.minimumPayRate,
      advanceSearch.maximumPayRate,
      advanceSearch.currency,
      userRole
    );
    setSearchMessage(message);
    return message;
  };
  React.useEffect(() => {
    getAllMasterCurrencyList();

    return()=>{
      if(location?.state?.fromPage === "JOBALERT"){
        clearAllFilter()
        
      }
    }
  }, []);

  let status = recruiterData?.entity?.status
  
  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{ marginTop: "15px" }}
        className="custom-header-menus"
        justifyContent={{ sm: "flex-start", md: "space-between" }}
      >
        <Grid item>
          <Typography className="listing-total-heading">Total Jobs ({formatAmount(totalRecords)})</Typography>
        </Grid>
        <Grid item>
        <Grid container justifyContent={{sm:"flex-start", md:"flex-end"}} mb={1}
        >
          <Grid item>
            <Box style={{position:"relative",marginRight:"15px"}}>
            <span className="AdvanceSearchBtnWrapper">
              <Button
                className="adv-search-btn"
                variant="outlined"
                onClick={handleAdvanceSearchToggle}
              >
                Advanced Search<ArrowDropDownIcon></ArrowDropDownIcon>
              </Button> 
            </span>           
            <Searchbar
              key={1}
              classes="joblistingSearchbar custom-searchbar-respnv"
              isNewSeachBar={true}
              width={searchBarWidth}
              height="1.7rem"
              marginBottom="1px"
              placeHolderText={"Search"}
              txtFontSize={"14px"}
              value={searchKeywords}
              onChange={onSearchKeywordChange}
              onEnter={onSearchKeywordChange}
              onIconClick={onSearchKeywordChange}
            />

              {isAdvanceSearchActive && (
                <CollapsibleAdvanceSearch
                  userRole={userRole}
                  recruiterStatus={recruiterData?.entity?.status}
                  AdvanceSearchFilterData={advanceSearch}
                  handleAdvancedSearchClick={handleAdvancedSearchClick}
                  handleClose={() => setIsAdvanceSearchActive(false)}
                  CurrercyList={masterCurrercyList}
                  setShowSearchResultMsg={""}
                  clearAdvancedSearch={()=>{
                    setDefaultSetJobAlert(false)
                    dispatch(resetAdvanceFilter())
                  
                  }}
                  pageType="clientJob"
                  classes="clientCollapsibleSearchBar"
                />
              )}
                <Typography color="primary" className="client-advanceSearchMessage">
                  {searchMessage}
                </Typography>
              </Box>
          </Grid>

          <Grid
            className="custom-btnmargin-rspnsv"
            item
          >
                        <Button
              variant="text"
              size="small"
              onClick={clearAllFilter}
              className={`${
                haveAnyFilter()
                  ? "custom-Btn-fontsizes"
                  : "disable-custom-Btn-fontsizes"
              } cust-btn-bgClr searchbarRow-ht-btn`}
              style={{
                marginLeft: "11px",
              }}
              data-testid="clear-button"
              disabled={!haveAnyFilter()}
            >
              <ClearIcon className="closeIcon" />
              Clear
            </Button>
         
          </Grid>
          {pageNameType !== PageName.SAVEDJOBPAGE && userRole === UserAuth.Recruiter&&
          <Box sx={{borderRadius:"4px", background: '#f4f5f8', padding: '6px 0px 7px 0px', marginLeft: '10px', display: 'flex', alignItems: 'center',height:"24px", justifyContent: 'center', minWidth: '100px' }}>
                 
                 <FormControlLabel 
                    className="alertSwitchFormControl" 
                    label=""
                    control={ 
                      <Switch 
                      disabled={status=="Pending"}
                      data-testid={"handleSetJobAlert"} 
                      inputProps={{ 'aria-label': 'secondary checkbox','name':'jobAlertSwitch',  }}
                      onChange={handleSetJobAlert} 
                      checked={defaultSetJobAlert} 
                      className="custom-switch-small"
                      />} />
                 <Typography sx={{ fontSize: '14px',  fontWeight: '500', color:"#4540DB" }} className="setalert-font">Set Alert</Typography>
               </Box>}
        </Grid>
        </Grid>
        <Grid container>
      <Grid item xs={12} xl={12}>
        {
          alertStatus?.isError && 
          <CustomAlert 
            type={"error"}
            message={"Please select the job title for setting the Job alerts."}
            onClose={()=>{ setAlertStatus({ ...alertStatus, isError:false, message:""})}}
          />
        }
      {showSuccessMsg && 
        <SnackbarPopup
        open={showSuccessMsg}
        message={showMessage} 
        onClose={onAlertClose}
      />
            }  
      </Grid>
    </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={
          "custom-flex-Ad-width-frst1 custom-inline-flex1 custom-fontize-hdr"
        }
      >
        <span className="Domain-txt-fontwt"> Select Domain: </span>
        {["IT", "Non-IT", "Healthcare"].map((x: string) => {
          return (
            <FormControlLabel
              key={x}
              sx={{ paddingLeft: "5px" }}
              control={
                <Checkbox
                  className="custom-checkbox-pading"
                  name={x}
                  value={x}
                  onChange={(e) => dispatch(handleDomainChange(x))}
                  size="small"
                  checked={domains.includes(x)}
                />
              }
              label={x}
            />
          );
        })}

        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={showRemoteJobs}
              checked={showRemoteJobs}
              onChange={() => {
                dispatch(
                  handleCheckboxFilter({
                    filterType: "remote",
                    filterValue: !showRemoteJobs,
                  })
                );
              }}
              size="small"
            />
          }
          label="Remote"
        />

        <>
          {page == "all" && (
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="featuredJobFilter"
                  defaultChecked={showFeaturedJobs}
                  checked={showFeaturedJobs}
                  onChange={() => {
                    dispatch(
                      handleCheckboxFilter({
                        filterType: "featured",
                        filterValue: !showFeaturedJobs,
                      })
                    );
                  }}
                  size="small"
                />
              }
              label="Featured"
            />
          )}
          {jobTypeList.map((x: string) => {
            return (
              <FormControlLabel
                key={x}
                control={
                  <Checkbox
                    defaultChecked={jobTypes.includes(x)}
                    checked={jobTypes.includes(x)}
                    onChange={(event) => {
                      dispatch(
                        handleCheckboxFilter({
                          filterType: "jobTypes",
                          filterValue: x,
                        })
                      );
                    }}
                    size="small"
                  />
                }
                label={x}
              />
            );
          })}
        </>


        { getUserDetails()?.userRoleType === "Client" || getUserDetails()?.userRoleType === "Admin" || getUserDetails()?.userRoleType === "SuperAdmin" ?(
          <FormControlLabel
            control={ 
              <Checkbox
                data-testid="notActiveFilter"
                defaultChecked={showDisabledJobs}
                checked={showDisabledJobs}
                onChange={() => {
                  dispatch(
                    handleCheckboxFilter({
                      filterType: "disabled",
                      filterValue: !showDisabledJobs,
                    })
                  );
                }}
                size="small"
              />
            }
            label="Not Active"
          />
        ):""}
      </Grid>
    </div>
  );
}

export default JobSearchbar;
